import React from 'react'

import { faCarCrash, faChevronDown, faChevronRight, faEngineWarning, faSkullCrossbones, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Accident.scss'

class Accident extends React.Component {
    formatHeaderDate = (dateString) => {
        let locale = window.navigator.language || 'en-us';

        var options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        let date = Date.parse(dateString);
        return new Date(date).toLocaleDateString(locale, options);
    }

    static init = (accident, closehandler) => {
        const popup = document.querySelector(`.accident-info-window[data-accident-id="${accident.id}"]`)
        if (!popup) return;

        popup.querySelectorAll('.vehicles .vehicle-detail .toggle-vehicle').forEach(button => {
            button.addEventListener('click', () => {
                let root = button.closest('.vehicle-detail');
                let isExpanded = root.getAttribute('data-expanded') === 'true';
                root.setAttribute('data-expanded', isExpanded ? 'false' : 'true')

                let expandedIcon = root.querySelector('.vehicle-header .icon.expanded');
                let collapsedIcon = root.querySelector('.vehicle-header .icon.collapsed');

                if (isExpanded) {
                    expandedIcon.style['display'] = 'none';
                    collapsedIcon.style['display'] = 'inline-block';
                }
                else {
                    expandedIcon.style['display'] = 'inline-block';
                    collapsedIcon.style['display'] = 'none';
                }
            });
        });

        popup.querySelector('.header .link-button.close').addEventListener('click', () => {
            if (closehandler) closehandler(accident);
        });

        const dialogParent = popup.closest('.gm-style-iw[role="dialog"]');
        if (dialogParent) {
            dialogParent.classList.add('custom-map-dialog')
        }
        else {
            console.warn('Can\'t find Google Maps modal popup element')
        }
    }

    getIcon = (severity) => {
        switch (severity.toLowerCase()) {
            case 'fatal':
                return faSkullCrossbones;
            case 'serious':
                return faCarCrash;
            case 'slight':
            default:
                return faEngineWarning;
        }
    }

    getSeverityClass = (severity) => {
        return severity ? `severity-${severity.toLowerCase()}` : '';
    }

    render() {
        const accident = this.props.accident;
        if (!accident) return;

        let vehicles = [...accident.vehicles];

        vehicles.forEach(vehicle => {
            vehicle.expanded = false;
            vehicle.casualties = accident.casualties.filter(c => c.vehicleId === vehicle.id);
            
            if (vehicle.casualties.some(c => c.severity === 'Fatal')) {
                vehicle.severity = 'Fatal';
            }
            else if (vehicle.casualties.some(c => c.severity === 'Serious')) {
                vehicle.severity = 'Serious';
            }
            else if (vehicle.casualties.some(c => c.severity === 'Slight')) {
                vehicle.severity = 'Slight';
            }
            else {
                vehicle.severity = null;
            }
        });

        return (
            <aside data-accident-id={accident.id} className={`accident-info-window ${this.getSeverityClass(accident.severity)}`}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" fixedWidth icon={this.getIcon(accident.severity)}/>
                        <span className="text">{accident.severity} Accident - <time>{this.formatHeaderDate(accident.date)}</time></span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close">
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="content">
                    <dl className="data-list">
                        <div className="item">
                            <dt className="key">Weather:</dt>
                            <dd className="value">
                                {accident.weatherConditions || '-'}
                            </dd>
                        </div>

                        <div className="item">
                            <dt className="key">Light:</dt>
                            <dd className="value">
                                {accident.lightConditions || '-'}
                            </dd>
                        </div>

                        <div className="item">
                            <dt className="key">Road:</dt>
                            <dd className="value">
                                {accident.roadConditions || '-'}
                            </dd>
                        </div>

                        <div className="item">
                            <dt className="key">Special:</dt>
                            <dd className="value">
                                {accident.spcialConditions || '-'}
                            </dd>
                        </div>

                        <div className="item">
                            <dt className="key">Hazards:</dt>
                            <dd className="value">
                                {accident.hazards || '-'}
                            </dd>
                        </div>
                    </dl>

                    <div className="vehicles">
                        <h3 className="title">Vehicles:</h3>

                        {vehicles.map(vehicle =>
                            <article key={vehicle.id} className={`vehicle-detail ${this.getSeverityClass(vehicle.severity)}`}
                                data-vehicle-id={vehicle.id} data-expanded="false">

                                <header className="vehicle-header">
                                    <h4 className="title">
                                        <button type="button" className="link-button toggle-vehicle">
                                            <FontAwesomeIcon className="icon collapsed" fixedWidth icon={faChevronRight}/>
                                            <FontAwesomeIcon className="icon expanded" fixedWidth icon={faChevronDown} style={{ display: 'none' }} />
                                            <span className="text">
                                                {vehicle.type || 'Unknown Vehicle'}
                                            </span>
                                        </button>
                                    </h4>
                                </header>

                                <div className="content">
                                    <dl className="data-list">
                                        <div className="item">
                                            <dt className="key">Age:</dt>
                                            <dd className="value">
                                                {vehicle.age ? (vehicle.age + ' year' + (vehicle.age !== 1 ? 's' :'')): '-'}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Engine:</dt>
                                            <dd className="value">
                                                {vehicle.engineCapacity ? (vehicle.engineCapacity + 'cc') : '-'}
                                                {vehicle.propulsionType && <> -  ({vehicle.propulsionType})</>}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Left Hand Drive:</dt>
                                            <dd className="value">
                                                {vehicle.isLeftHandDrive === null ? '-' : (vehicle.isLeftHandDrive ? 'Yes' : 'No')}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Towing:</dt>
                                            <dd className="value">
                                                {vehicle.towingType || ''}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Driver:</dt>
                                            <dd className="value">
                                                {vehicle.isDriverMale === null ? '' : (vehicle.isDriverMale ? 'Male, ' : 'Female, ')}
                                                {vehicle.driverAge ? (vehicle.driverAge + ' year' + (vehicle.driverAge !== 1 ? 's' :'')): ''}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Journey Purpose:</dt>
                                            <dd className="value">
                                                {vehicle.journeyPurpose || '-'}
                                            </dd>
                                        </div>

                                        <div className="item">
                                            <dt className="key">Manoeuvre:</dt>
                                            <dd className="value">
                                                {vehicle.manoeuvre || '-'}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Point Of Impact:</dt>
                                            <dd className="value">
                                                {vehicle.pointOfImpact || '-'}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Skidding / Overturning:</dt>
                                            <dd className="value">
                                                {vehicle.skiddingOverturning || '-'}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Hit Object On Road:</dt>
                                            <dd className="value">
                                                {vehicle.hitObjectOnRoad || '-'}
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Hit Object Off Road:</dt>
                                            <dd className="value">
                                                {vehicle.hitObjectOffRoad || '-'}
                                            </dd>
                                        </div>
                                    </dl>

                                    {vehicle.casualties.length > 0 &&
                                        <div className="vehicle-casualties">
                                            <h4 className="title">Casualties:</h4>

                                            <ul className="casualty-list">
                                                {vehicle.casualties.map(casualty => 
                                                    <li key={casualty.id} data-casualty-id={casualty.id} className={`item ${this.getSeverityClass(casualty.severity)}`}>
                                                        <FontAwesomeIcon className="icon" fixedWidth icon={this.getIcon(casualty.severity)}/>
                                                        <div className="content">
                                                            <strong className="head-details">{casualty.class ? casualty.class + ' - ' : ''}{casualty.severity}</strong>
                                                            <span className="sub-details">
                                                                {casualty.isMale === null ? 'Unknown' : (casualty.isMale ? 'Male' : 'Female')}<>, </>
                                                                {casualty.age ? (casualty.age + ' year' + (casualty.age !== 1 ? 's' :'')): ''}
                                                                {casualty.type ? ' - ' + casualty.type : ''}
                                                            </span>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </article>
                        )}
                    </div>
                </div>
            </aside>
        )
    }
}

export default Accident