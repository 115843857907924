import React from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageQuote, faTimesCircle, faUserCircle } from '@fortawesome/pro-solid-svg-icons'

import format from '../../utils/format';

import Stars from '../Stars';
import ModalPortal from './ModalPortal'

import './ReviewDetail.scss'

class ReviewDetail extends React.Component {
    close = () => {
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        const review = this.props.review;
        const rating = review.rating ? review.rating / 2 : null;

        let distanceInMeters = this.props.drive.distance;

        let distanceInKM = distanceInMeters / 1000;
        let distanceInMiles = distanceInKM * 0.621371;

        distanceInKM = distanceInKM.toFixed(1);
        distanceInMiles = distanceInMiles.toFixed(1);

        let travelTimeInMinutes = this.props.drive.travelTime;

        let hours = Math.floor(travelTimeInMinutes / 60)
        let remainderMinutes = travelTimeInMinutes - (hours * 60);
        let travelTime = (hours > 0 ? (hours + ' hours ') : '') + remainderMinutes + ' minutes';

        return (
            <ModalPortal className="standard-modal review-detail-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faMessageQuote}/>
                        <span className="text">{this.props.drive.name} Review</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="main">
                    <dl className="data-list drive-props">
                        <div className="item">
                            <dt className="key">Distance:</dt>
                            <dd className="value">{distanceInKM}km ({distanceInMiles} miles)</dd>
                        </div>
                        <div className="item">
                            <dt className="key">Travel Time:</dt>
                            <dd className="value">{travelTime}</dd>
                        </div>
                    </dl>

                    <div className="review-text">
                        {format.textBlock(review.text)}
                    </div>

                    <footer className="review-info">
                        {review.author.avatarImages &&
                            <img className="review-avatar" alt="" src={review.author.avatarImages.small}/>
                        }

                        {!review.author.avatarImages &&
                            <FontAwesomeIcon className="review-avatar no-image" icon={faUserCircle}/>
                        }

                        <div className="rating-author">
                            {rating && <Stars className="star-rating" rating={rating} readOnly={true}/> }

                            <p className="author">
                                by <NavLink className="author-link" to={`/profile/` + review.author.username}>{review.author.fullname}</NavLink>
                                <span className="created-date"> on <time>{format.date(review.created)}</time></span>
                            </p>
                        </div>
                    </footer>
                </div>
            </ModalPortal>
        )
    }
}

export default ReviewDetail