const statusCodes = {
    ok: 0,

    noAPIEndpoint: 4,

    requiresAuthentication: 30,
    notAuthorized: 31,
    invalidAuthToken: 32,
    incorrectAuthDetails: 33,

    badRequest: 40,
    modelValidationError: 41,
    bespokeModelError: 42,
    modelInvalid: 43,
    notFound: 44,
    alreadyExists: 49,

    internalServerError: 50,

    subCodes: {
        accounts: {
            usernameExists: 1,
            emailExists: 2,
            usernameAndEmailExists: 3
        },
        drives: {
            startAndEndCantBeSame: 1,
            distanceTooShort: 2,
            similarDriveAlreadyExists: 3,
            noDirectionsFound: 4
        }
    }
};

export default statusCodes;