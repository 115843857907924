import React from 'react'
import { NavLink } from 'react-router-dom'

import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faPlusCircle, faSync } from '@fortawesome/pro-solid-svg-icons'

import validation from '../../utils/validation'
import geo from '../../utils/geo'
import Storage from '../../utils/storage'
import youTube from '../../utils/youTube'
import statusCode from '../../utils/apiStatusCodes';

import Stars from '../../components/Stars'
import DriveInfo from '../../components/DriveInfo';

import './confirm.scss'

class DriveConfirm extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'Confirm Your Drive - Spirited Drive';
        document.querySelector('head > meta[name="description"]').setAttribute('content', '');

        this.props.pageType.setHalf();
    }

    state = {
        authUser: null,
        isSubmitted: false,
        isSaving: false,
        form: {
            name: {
                val: '',
                err: null
            },
            rating: {
                val: 0,
                err: null
            },
            review: {
                val: '',
                err: null
            },
            youTubeUrl: {
                val: '',
                err: null
            }
        },
        startLocation: '',
        endLocation: '',
        generalLocation: '',
        isLoop: false,
        distance: 0,
        travelTime: 0
    }

    mapDrawings = [];
    mapEvents = [];

    componentDidMount() {
        this.props.mapReady((map, google) => {
            map._enableControls();
            map.streetView.setVisible(false);

            const storage = Storage.get(window);
            let routeInfo = null;
        
            try {
                routeInfo = JSON.parse(storage.getItem('AddDriveRouteInfo'));
            }
            catch {
                console.error('No route info found');
                this.props.history.push('/drives/add');
                return;
            }

            if (!routeInfo) {
                console.error('No route info found');
                this.props.history.push('/drives/add');
                return;
            }

            let points = geo.decodePoints(routeInfo.encodedPath);
            if (points.length < 2) {
                console.error('Not enough points (' + points.length + ')');
                this.props.history.push('/drives/add');
                return;
            }

            let polyline = new google.maps.Polyline({
                path: points,
                clickable: false,
                geodesic: true,
                strokeColor: "#0080ff",
                strokeOpacity: 0.7,
                strokeWeight: 6
            });

            let expandedBounds = window.innerWidth <= 700 ? routeInfo.bounds : geo.getBoundsForSplitView(routeInfo.bounds, { side: 'left' });
            let bounds = new google.maps.LatLngBounds(expandedBounds.sw, expandedBounds.ne);
            map.fitBounds(bounds);

            this.mapDrawings.push(new google.maps.Marker({
                map,
                position: {
                    lat: routeInfo.start.lat,
                    lng: routeInfo.start.lng
                },
                animation: 'BOUNCE',
                clickable: false,
                label: {
                    text: routeInfo.isLoop ? 'O' : 'A',
                    color: '#ffffff'
                }
            }));

            if (!routeInfo.isLoop) {
                this.mapDrawings.push(new google.maps.Marker({
                    map,
                    position: {
                        lat: routeInfo.end.lat,
                        lng: routeInfo.end.lng
                    },
                    clickable: false,
                    label: {
                        text: 'B',
                        color: '#ffffff'
                    }
                }));
            }

            let form = { ...this.state.form };
            form.name.val = routeInfo.summary.nameCandidate;

            this.setState({
                form: form,
                startLocation: routeInfo.summary.startLocation,
                endLocation: routeInfo.summary.endLocation,
                generalLocation: routeInfo.summary.generalLocation,
                isLoop: routeInfo.isLoop,
                distance: routeInfo.distanceInMeters,
                travelTime: Math.ceil(routeInfo.durationInSeconds / 60)
            });

            this.mapDrawings.push(polyline);
            polyline.setMap(map);
        });

        this.props.authUserStateReady(authUser => {
            if (!authUser) {
                this.props.history.push('/drives/add');
            }

            this.setState({ authUser: authUser });
        });
    }

    componentWillUnmount() {
        this.props.mapReady((map, google) => {
            this.mapEvents.forEach(e => google.maps.event.removeListener(e));
            this.mapDrawings.forEach(d => d.setMap(null));
        });
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleRating = (rating) => {
        let form = { ...this.state.form }
        form.rating.val = rating;
        this.setState({ form: form })
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.isSaving) return;

        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        // get stored route
        const storage = Storage.get(window);
        let routeInfo = null;
    
        try {
            routeInfo = JSON.parse(storage.getItem('AddDriveRouteInfo'));
        }
        catch {
            console.error('No route info found');
            this.props.history.push('/drives/add');
            return;
        }

        this.setState({ isSaving: true });

        // check youtube video
        let videoId = youTube.extractVideoId(this.state.form.youTubeUrl.val);
        if (videoId) {
            let videoItem = await youTube.getVideoInfo(videoId, this.props.config.googleApiKey);

            if (!videoItem) {
                let errorMessage = 'YouTube video not found'
                this.setState({
                    form: validation.markFieldInvalid(event.target, this.state.form, 'youTubeUrl', errorMessage),
                    isSaving: false
                });
                return;
            }

            if (videoItem.privacyStatus !== 'public') {
                let errorMessage = 'Video must be made Public';
                this.setState({
                    form: validation.markFieldInvalid(event.target, this.state.form, 'youTubeUrl', errorMessage),
                    isSaving: false
                });
                return;
            }

            if (!videoItem.embeddable) {
                let errorMessage = 'Video must be set to be embeddable';
                this.setState({
                    form: validation.markFieldInvalid(event.target, this.state.form, 'youTubeUrl', errorMessage),
                    isSaving: false
                });
                return;
            }
        }

        // save Drive
        let waypoints = [];
        waypoints.push(routeInfo.start);
        routeInfo.midWaypoints.forEach(waypoint => {
            waypoints.push(waypoint);
        });
        waypoints.push(routeInfo.end);

        let createUrl = this.props.config.apiUrl + '/v1/drives/';

        let driveData = {
            name: this.state.form.name.val,
            isPrivate: false,
            waypoints: waypoints,
            avoid: routeInfo.avoid
        };

        this.props.fetcher.post(createUrl, driveData).then(response => {
            if (response.code !== statusCode.ok &&
                response.code !== statusCode.alreadyExists) {
                // TODO: error
                return;
            }

            let driveId = response.id;
            let urlSlug = response.slug;

            let processed = {
                favourite: false,
                review: false,
                youTube: false
            }

            // save Favourite
            let favouritesData = {
                name: this.state.form.name.val,
                driveId: driveId,
                isPrivate: false
            }

            let favouritesUrl = this.props.config.apiUrl + '/v1/users/' + encodeURIComponent(this.state.authUser.id) + '/favourites'

            this.props.fetcher.post(favouritesUrl, favouritesData).then(response => {
                if (response.code !== statusCode.ok &&
                    response.code !== statusCode.alreadyExists) {
                    // TODO: error
                    return;
                }

                processed.favourite = true;
                this.finishAndRedirect(processed, driveData.name, urlSlug);
            });

            // save Review
            if (this.state.form.rating.val > 0) {
                let reviewUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/reviews'

                let reviewData = {
                    text: this.state.form.review.val,
                    rating: this.state.form.rating.val * 2
                };

                this.props.fetcher.post(reviewUrl, reviewData).then(response => {
                    if (response.code !== statusCode.ok &&
                        response.code !== statusCode.alreadyExists) {
                        // TODO: error
                        return;
                    }

                    processed.review = true;
                    this.finishAndRedirect(processed, driveData.name, urlSlug);
                });
            }
            else {
                processed.review = true;
                this.finishAndRedirect(processed, driveData.name, urlSlug);
            }

            // save Video
            if (videoId) {
                let videoUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/videos'

                let videoData = {
                    youTubeVideoId: videoId
                };

                this.props.fetcher.post(videoUrl, videoData).then(response => {
                    if (response.code !== statusCode.ok &&
                        response.code !== statusCode.alreadyExists) {
                        // TODO: error
                        return;
                    }

                    processed.youTube = true;
                    this.finishAndRedirect(processed, driveData.name, urlSlug);
                });
            }
            else {
                processed.youTube = true;
                this.finishAndRedirect(processed, driveData.name, urlSlug);
            }
        });
    }

    finishAndRedirect = (processed, driveName, urlSlug) => {
        let allProcessed = processed.favourite && processed.review && processed.youTube;
        if (!allProcessed) return;

        const storage = Storage.get(window);
        storage.removeItem('AddDriveRouteInfo');

        this.props.trackEvent('FinishedDriveUpload');

        this.props.history.push('/drives/' + encodeURIComponent(urlSlug));
    }

    handleCancel = () => {
        if (this.state.isSaving) return;

        const storage = Storage.get(window);
        storage.removeItem('AddDriveRouteInfo');

        this.props.history.push('/drives/add');
    }

    render() {
        return (
            <div className="main-page-half confirm-drive-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/drives/add" className="link">Add Your Drive</NavLink></li>
                        <li className="item"><NavLink exact to="/drives/confirm" aria-current="location" className="link">Confirm</NavLink></li>
                    </ol>
                </nav>

                <section className="split-page-content">
                    <h1 className="title">Confirm Your Drive</h1>

                    <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                        <fieldset className="form">
                            <legend className="screen-reader-only">Drive details</legend>

                            <div className="form-row stacked">
                                <label htmlFor="driveName" className="label">Drive name *</label>
                                <div className="control">
                                    <input id="driveName" name="name" type="text" className="textbox" required minLength="5" maxLength="80"
                                        value={this.state.form.name.val} onChange={this.handleInputChange}/>
                                </div>
                            </div>

                            <DriveInfo className="drive-meta-data" startAddress={this.state.startLocation} endAddress={this.state.endLocation}
                                generalLocation={this.state.generalLocation} isLoop={this.state.isLoop}
                                distanceInMeters={this.state.distance} travelTimeInMinutes={this.state.travelTime}/>

                            <div className="form-row stacked star-row">
                                <label className="label">Your Rating <span className="optional-label">(optional)</span></label>
                                <div className="control">
                                    <Stars className="star-rating" rating={this.state.form.rating.val} onChange={this.handleRating}/>

                                    {this.state.form.rating.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/> {this.state.form.rating.err}
                                        </p>
                                    }
                                </div>
                            </div>

                            {this.state.form.rating.val > 0 &&
                                <div className="form-row stacked review-row">
                                    <label htmlFor="review" className="label">Your Review <span className="optional-label">(optional)</span></label>
                                    <div className="control">
                                        <textarea id="review" name="review" placeholder="Tell us what you like about this drive (you can fill this in later)"
                                            value={this.state.form.review.val} onChange={this.handleInputChange} maxLength="2000"></textarea>
                                    </div>
                                </div>
                            }

                            <div className="form-row stacked video-row">
                                <label htmlFor="youTubeUrl" className="label">YouTube Video Link <span className="optional-label">(optional)</span></label>
                                <div className="control">
                                    <input id="youTubeUrl" name="youTubeUrl"  type="url" className="textbox url"
                                        maxLength="100" pattern="^https:\/\/(youtu\.be\/|www\.youtube\.com\/watch\?v=)(.+)$"
                                        data-error-pattern="Must be a valid YouTube URL"
                                        placeholder="e.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
                                        value={this.state.form.youTubeUrl.val} onChange={this.handleInputChange}/>

                                    {this.state.form.youTubeUrl.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/> {this.state.form.youTubeUrl.err}
                                        </p>
                                    }
                                </div>
                            </div>
                        </fieldset>

                        <div className="form-row buttons">
                            <button type="submit" className="button cta chunky confirm-button">
                                <FontAwesomeIcon className="icon" icon={this.state.isSaving ? faSync : faPlusCircle}
                                    spin={this.state.isSaving} fixedWidth={true}/>
                                <span className="text">Looks Good</span>
                            </button>
                            <button type="button" className="link-button cancel-button" onClick={this.handleCancel}>Nevermind</button>
                        </div>
                    </form>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(DriveConfirm)