import React from 'react'

const functions = {
    date: function(dateString, locale) {
        locale = locale || window.navigator.language || 'en-us';

        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        let date = Date.parse(dateString);
        return new Date(date).toLocaleDateString(locale, options);
    },
    dateTime: function(dateString, locale) {
        locale = locale || window.navigator.language || 'en-us';

        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        let date = Date.parse(dateString);
        return new Date(date).toLocaleDateString(locale, options);
    },
    textBlock: function(text, className) {
        let lines = text.split('\n');

        return (
            <p className={className}>
                {lines.map((line, i) =>
                    <React.Fragment key={i}>{line}{i < lines.length - 1 && <br/>}</React.Fragment>
                )}
            </p>
        )
    },
    truncate: function(text, maxLength, affix) {
        text = (text || '').trim();
        
        if (text.length <= maxLength) {
            return text;
        }

        return text.substring(0, maxLength).trim() + ' ' + affix;
    },
    sanitiseUsername: function(value, maxLength) {
        return value.trim()
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/([^a-z0-9_-]+)/g, '')
            .substring(0, maxLength);
    },
    makeUrlFriendly: function(text) {
        let urlFriendlyName = '';

        text
            .trim()
            .toLowerCase()
            .split('')
            .forEach(c => {
                if (c.match(/[a-z0-9\-_]/i)) {
                    urlFriendlyName += c;
                    return;
                }

                if (c === ' ') {
                    urlFriendlyName += '-'
                }
            });

        return urlFriendlyName
            .replace(/-{2,}/ig, '-')
            .replace(/^-/, '')
            .replace(/-$/, '');
    },
    querystring: function(search) {
        let qs = {};

        // check if search is actually a location object
        if (search.hasOwnProperty('pathname') && search.hasOwnProperty('search')) {
            search = search.search || '';
        }

        if (search.startsWith('?')) {
            search = search.substr(1);
        }

        if (search.length > 0) {
            search
                .split('&')
                .forEach(x => {
                    if (!x) return;
                    
                    x = x.split('=');
                    qs[x[0]] = decodeURIComponent(x[1] || '');
                });
        }

        qs.toString = function(include) {
            let newQS = { ...this };
            delete newQS.toString;

            if (include) {
                Object.assign(newQS, include);
            }

            let search = '';

            const keys = Object.keys(newQS);

            keys.forEach((key, i) => {
                let keyValue = key + '=' + encodeURIComponent(newQS[key]);

                let isLast = keys.length === i + 1;
                search += keyValue + (!isLast ? '&' : '');
            });

            return search ? ('?' + search) : '';
        };

        return qs;
    },
    uuidv4: function() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> (c / 4)).toString(16)
        );
    }
}

export default functions;