import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'

class PasswordField extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reveal: false,
            password: props.value || ''
        }
    }

    toggleReveal = () => {
        this.setState({ reveal: !this.state.reveal })
    }

    onChange = (e) => {
        this.setState({ password: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <label htmlFor={this.props.id} className="label">
                    {this.props.label || 'Password'} {this.props.optionalLabel &&
                        <span className="optional-label">{this.props.optionalLabel}</span>
                    }
                </label>

                <div className="password-control">
                    <input id={this.props.id} name={this.props.id} type={this.state.reveal ? 'text' : 'password'}
                        className="textbox password" required={this.props.required} minLength="6"
                        value={this.state.password} onChange={this.onChange}/>
                    <button type="button" className="password-reveal" aria-label="Reveal Password" onClick={this.toggleReveal}>
                        {this.state.reveal && <FontAwesomeIcon className="icon" icon={faEyeSlash}/>}
                        {!this.state.reveal && <FontAwesomeIcon className="icon" icon={faEye}/>}
                    </button>
                </div>

                {this.props.error &&
                    <p className="validation-error">
                        <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.props.error}
                    </p>
                }
            </div>
        )
    }
}

export default PasswordField