import React from 'react'
import { withRouter } from "react-router";
import AppContext from '../appContext';
import format from '../utils/format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/pro-solid-svg-icons'

import ModalPortal from './../components/modals/ModalPortal'

const httpRequestDelay = 0;

const basePageWrapper = WrappedComponent => {
    WrappedComponent = withRouter(WrappedComponent)

    const basePageComponent = class BasePageComponent extends React.Component {
        static contextType = AppContext;

        constructor(props) {
            super(props)

            this.qs = format.querystring(window.location.search);
        }

        abortController = new AbortController();

        mapReadyFunc = null
        map = null;
        google = null;

        authUserStateReadyFunc = null;
        authUserStateDetermined = false;
        authUser = null;

        state = {
            hasError: false,
            errorId: null,
            errorDetails: null
        };

        qs = {}

        componentDidMount() {
            this.context.mapReady((map, google) => {
                this.map = map;
                this.google = google;

                if (this.mapReadyFunc) {
                    try {
                        this.mapReadyFunc(this.map, this.google);
                    }
                    catch(e) {
                        this.setState(() => { throw e; });
                    }
                }
            });

            this.context.userReady(authUser => {
                this.authUser = authUser;
                this.authUserStateDetermined = true;

                if (this.authUserStateReadyFunc) {
                    try {
                        this.authUserStateReadyFunc(this.authUser);
                    }
                    catch (e) {
                        this.setState(() => { throw e; });
                    }
                }
            });
        }

        componentWillUnmount() {
            this.abortController.abort();

            // reset any 404 states set by pages
            const robotsMeta = document.querySelector('meta[name="robots"]');
            if (robotsMeta) {
                robotsMeta.setAttribute('content', 'index follow');
            }

            // reset open Graph
            this.populateOpenGraph();
        }

        authUserStateReady = (func) => {
            this.authUserStateReadyFunc = func;

            if (this.authUserStateDetermined) {
                try {
                    this.authUserStateReadyFunc(this.authUser);
                }
                catch (e) {
                    this.setState(() => { throw e; });
                }
            }
        }

        mapReady = (func) => {
            this.mapReadyFunc = func;

            if (this.map) {
                try {
                    this.mapReadyFunc(this.map, this.google);
                }
                catch (e) {
                    this.setState(() => { throw e; });
                }
            }
        }

        fetcher = {
            get: (url) => {
                return new Promise((resolve, reject) => {
                    fetch(url, {
                        method: 'GET',
                        signal: this.abortController.signal,
                        headers: {
                            'Content-Type': 'application/json',
                            'Website-URL':  window.location.toString(),
                            'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 50 ) {
                            this.handleRestError(response)
                            return;
                        }

                        if (response.code === 4) {
                            throw new Error('API Endpoint does not exist (404)');
                        }

                        setTimeout(() => {
                            resolve(response);
                        }, httpRequestDelay);
                    })
                    .catch(error => {
                        if (error.name === 'AbortError') return;

                        this.handleFetchError(url, error);
                    });
                });
            },
            post: (url, data) => {
                return new Promise((resolve, reject) => {
                    fetch(url, {
                        method: 'POST',
                        signal: this.abortController.signal,
                        headers: {
                            'Content-Type': 'application/json',
                            'Website-URL':  window.location.toString(),
                            'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 50) {
                            this.handleRestError(response)
                            return;
                        }

                        if (response.code === 4) {
                            throw new Error('API Endpoint does not exist (404)');
                        }

                        setTimeout(() => {
                            resolve(response);
                        }, httpRequestDelay);
                    })
                    .catch(error => {
                        if (error.name === 'AbortError') return;
            
                        this.handleFetchError(url, error);
                    }); 
                });
            },
            put: (url, data) => {
                return new Promise((resolve, reject) => {
                    fetch(url, {
                        method: 'PUT',
                        signal: this.abortController.signal,
                        headers: {
                            'Content-Type': 'application/json',
                            'Website-URL':  window.location.toString(),
                            'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 50) {
                            this.handleRestError(response)
                            return;
                        }

                        if (response.code === 4) {
                            throw new Error('API Endpoint does not exist (404)');
                        }

                        setTimeout(() => {
                            resolve(response);
                        }, httpRequestDelay);
                    })
                    .catch(error => {
                        if (error.name === 'AbortError') return;
            
                        this.handleFetchError(url, error);
                    });
                });
            },
            delete: (url) => {
                return new Promise((resolve, reject) => {
                    fetch(url, {
                        method: 'DELETE',
                        signal: this.abortController.signal,
                        headers: {
                            'Content-Type': 'application/json',
                            'Website-URL':  window.location.toString(),
                            'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 50) {
                            this.handleRestError(response)
                            return;
                        }

                        if (response.code === 4) {
                            throw new Error('API Endpoint does not exist (404)');
                        }

                        setTimeout(() => {
                            resolve(response);
                        }, httpRequestDelay);
                    })
                    .catch(error => {
                        if (error.name === 'AbortError') return;
            
                        this.handleFetchError(url, error);
                    });
                });
            }
        }

        handleRestError = (response) => {
            let errorDetails = null;

            if (response.exception) {
                errorDetails = {
                    message: response.exception,
                    stack: response.stack || []
                };
            }

            this.setState({
                hasError: true,
                errorId: response.errorId,
                errorDetails: errorDetails
            });
        }

        handleFetchError = (url, error) => {
            this.setState({ hasError: true });

            let name = error.name;
            let message = error.message;

            console.error('Fetching (GET: ' + url + ') threw an error:', name + ': ' + message);

            const apiRootUrl = process.env.REACT_APP_API_URL;
            const logUrl = apiRootUrl + '/v1/logs';

            let data = {
                source: 'web',
                message: 'Error trying to Fetch (GET: ' + url + '). Error was: ' + name + ': ' + message,
                stack: null,
                url: window.location.toString(),
                version: process.env.REACT_APP_VERSION
            };

            fetch(logUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(response => {
                if (response.code === 0) {
                    this.setState({ errorId: response.id });
                }
                else {
                    this.setState({ errorReportingFailed: true });
                }
                
            })
            .catch(() => {
                this.setState({ errorReportingFailed: true });
            });
        }

        pageType = {
            setHalf: () => {
                this.context.pageType.setHalf();
            },
            setFull: () => {
                this.context.pageType.setFull();
            },
            setSearch: () => {
                this.context.pageType.setSearch();
            },
            set404: () => {
                const robotsMeta = document.querySelector('meta[name="robots"]');
                if (robotsMeta) {
                    robotsMeta.setAttribute('content', 'noindex follow');
                }
            }
        }

        trackEvent = (eventName, value) => {
            if (!window.fathom) return;

            let code = null;

            switch (eventName) {
                case 'AccountCreated':
                    code = '2OSHTTHB';
                    break;
                case 'AccountActivated':
                    code = '8MM085UQ';
                    break;
                case 'PhotoUploaded':
                    code = 'AANTHGIC';
                    break;
                case 'StartedDriveUpload':
                    code = 'MZRAZ370';
                    break;
                case 'FinishedDriveUpload':
                    code = 'GPZMEXHV';
                    break;
                default:
                    code = null;
                    break;
            }

            if (!code) {
                console.error('Event name "' + eventName + '" not recognised for tracking.');
                return;
            }

            value = value || 0;
            window.fathom.trackGoal(code, value);
        }

        populateOpenGraph = (data) => {
            data = data || {};

            // twitter
            let twitterCardNode = document.querySelector('head > meta[name="twitter:card"]')
            if (twitterCardNode && data.imageUrl) {
                twitterCardNode.setAttribute('content', 'summary_large_image');
            }
            else {
                twitterCardNode.setAttribute('content', 'summary');
            }

            let twitterCreatorNode = document.querySelector('head > meta[name="twitter:creator"]')
            if (twitterCreatorNode) {
                let username = (data.twitterUsername || '').trim();
                if (username) {
                    username = username.startsWith('@') ? username : ('@' + username);
                }
                twitterCreatorNode.setAttribute('content', username);
            }

            let twitterTitleNode = document.querySelector('head > meta[name="twitter:title"]');
            if (twitterTitleNode) twitterTitleNode.setAttribute("content", data.title || 'Spirited Drive');

            let twitterUrlNode = document.querySelector('head > meta[property="twitter:url"]');
            if (twitterUrlNode) twitterUrlNode.setAttribute("content", data.url || window.location.toString());

            let twitterDescriptionNode = document.querySelector('head > meta[name="twitter:description"]');
            if (twitterDescriptionNode) twitterDescriptionNode.setAttribute("content", data.description || 'A crowdsourced online database of the world\'s best driving roads.');

            let twitterImageNode = document.querySelector('head > meta[name="twitter:image"]');
            if (twitterImageNode) twitterImageNode.setAttribute("content", data.imageUrl || 'https://cdn.spiriteddrive.club/images/banner.jpeg');

            let twitterImageAltNode = document.querySelector('head > meta[name="twitter:image:alt"]');
            if (twitterImageAltNode) twitterImageAltNode.setAttribute("content", data.imageAlt || '');

            // FB open graph
            let titleNode = document.querySelector('head > meta[property="og:title"]');
            if (titleNode) titleNode.setAttribute("content", data.title || 'Spirited Drive');

            let descriptionNode = document.querySelector('head > meta[property="og:description"]');
            if (descriptionNode) descriptionNode.setAttribute("content", data.description || 'A crowdsourced online database of the world\'s best driving roads.');

            let urlNode = document.querySelector('head > meta[property="og:url"]');
            if (urlNode) urlNode.setAttribute("content", data.url || window.location.toString());

            let imageNode = document.querySelector('head > meta[property="og:image"]');
            if (imageNode) imageNode.setAttribute("content", data.imageUrl || 'https://cdn.spiriteddrive.club/images/banner.jpeg');

            let imageAltNode = document.querySelector('head > meta[property="og:image:alt"]');
            if (imageAltNode) imageAltNode.setAttribute("content", data.imageAlt || '');

            let typeNode = document.querySelector('head > meta[property="og:type"]');
            if (typeNode) typeNode.setAttribute("content", data.type || 'website');

            let publishedTimeNode = document.querySelector('head > meta[property="article:published_time"]');
            if (publishedTimeNode) publishedTimeNode.setAttribute("content", data.publishedTime || '');

            let modifiedTimeNode = document.querySelector('head > meta[property="article:modified_time"]');
            if (modifiedTimeNode) modifiedTimeNode.setAttribute("content", data.modifiedTime || '');

            let authorNode = document.querySelector('head > meta[property="article:author"]');
            if (authorNode) authorNode.setAttribute("content", data.author || '');

            let usernameNode = document.querySelector('head > meta[property="profile:username"]');
            if (usernameNode) usernameNode.setAttribute("content", data.username || '');
        }

        render() {
            return (<>
                {this.state.hasError &&
                    <ModalPortal className="error-modal">
                        <div className="error-info">
                            <img className="logo" src="/logo.png" alt="Spirited Drive logo"/>

                            <h2 className="title"><FontAwesomeIcon icon={faFrown}/> Looks like the website needs an oil change.</h2>

                            <p className="message">
                                We've been notified of the issue and will fix the problem ASAP. In the meantime 
                                try <a href={window.location.toString()}>reloading</a> this page,
                                or return to the <a href="/">home page</a>.
                            </p>

                            <p className="message">Sorry for any inconvience. Thank you!</p>

                            {this.state.errorId && <p className="error-id">Error ID: {this.state.errorId}</p>}

                            <nav className="footer-links">
                                <ul className="row-list">
                                    <li className="item">
                                        <a className="contact-link" href={`mailto:${this.context.config.email}`}>Contact Us</a>
                                    </li>
                                    <li className="item">
                                        <a className="contact-link" target="_blank" rel="noreferrer"
                                            href={`https://twitter.com/` + this.context.config.social.twitterUsername}>Twitter</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </ModalPortal>
                }
                
                <WrappedComponent
                    fetcher={this.fetcher}
                    config={this.context.config}
                    authUserStateReady={this.authUserStateReady}
                    mapReady={this.mapReady}
                    pageType={this.pageType} 
                    qs={this.qs}
                    trackEvent={this.trackEvent}
                    populateOpenGraph={this.populateOpenGraph}
                    {...this.props} />
            </>)
        }
    };

    return basePageComponent;
};

export default basePageWrapper;