import React from 'react';
import memoize from "memoize-one";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faTable } from '@fortawesome/pro-solid-svg-icons';

import pinSelected from '../images/pin-count-point-selected.png';
import pinUnselected from '../images/pin-count-point-unselected.png';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class TrafficInfo extends React.Component {
    state = {
        vehicleTypes: [
            { key: 'bicycles', name: 'Bicycles', color: '#2dab30' },
            { key: 'motorcycles', name: 'Motorcycles', color: '#3699e1' },
            { key: 'cars', name: 'Cars', color: '#ff3f3f' },
            { key: 'buses', name: 'Buses', color: '#e5ab52' },
            { key: 'lgvs', name: 'Vans', color: '#67686e' },
            { key: 'hgvs', name: 'HGVs', color: '#000000' }
        ],
        selectedTab: 'summary',
        showChart: false,
        selectedHourlyTab: 'weekday',
        monthNames: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
        dayNames: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
        countPoints: {
            editMode: false,
            saving: false,
            records: []
        }
    }

    processChartData = memoize(traffic => {
        if (!traffic) return;

        const dayLabels = [ '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18' ];

        const options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        footer: (tooltipItems) => {
                            let sum = 0;

                            tooltipItems.forEach(tooltipItem => {
                                sum += tooltipItem.parsed.y;
                            });

                            return 'Total: ' + sum;
                        }
                    }
                }
            }
        };

        const hourlyOptions = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: (tooltipItems) => {
                            let item = tooltipItems[0];
                
                            let hour = parseInt(item.label);
                            let period = hour < 12 ? 'am' : 'pm';
                            hour = hour > 12 ? hour - 12 : hour;
                
                            let nextHour = hour + 1;
                            let nextPeriod = nextHour < 12 ? 'am' : 'pm';
                            nextHour = nextHour > 12 ? nextHour - 12 : nextHour;
                
                            return hour + period + ' - ' + nextHour + nextPeriod;
                        },
                        footer: (tooltipItems) => {
                            let sum = 0;

                            tooltipItems.forEach(tooltipItem => {
                                sum += tooltipItem.parsed.y;
                            });

                            return 'Total: ' + sum;
                        }
                    }
                }
            }
        };

        const weekdayData = {
            labels: dayLabels,
            datasets: this.state.vehicleTypes.map(type => {
                const weekday = traffic.hourly.weekday;

                const dataset = Object.keys(weekday)
                    .map(hour => {
                        return weekday[hour][type.key];
                    });
    
                return {
                    label: type.name,
                    data: dataset,
                    borderColor: type.color,
                    backgroundColor: type.color + '80',
                    borderWidth: 1
                };
            })
        };

        const weekendData = {
            labels: dayLabels,
            datasets: this.state.vehicleTypes.map(type => {
                const weekend = traffic.hourly.weekend;

                const dataset = Object.keys(weekend)
                    .map(hour => {
                        return weekend[hour][type.key];
                    });
    
                return {
                    label: type.name,
                    data: dataset,
                    borderColor: type.color,
                    backgroundColor: type.color + '80',
                    borderWidth: 1
                };
            })
        };
        
        const dailyData = {
            labels: this.state.dayNames,
            datasets: this.state.vehicleTypes.map(type => {
                let dataset = [];

                this.state.dayNames.forEach(dayName => {
                    const dayStat = traffic.daily[dayName.toLowerCase()];
                    if (dayStat) {
                        const count = Math.round(dayStat[type.key]);
                        dataset.push(count);
                    }
                    else {
                        dataset.push(null);
                    }
                });

                return {
                    label: type.name,
                    data: dataset,
                    borderColor: type.color,
                    backgroundColor: type.color + '80',
                    borderWidth: 1
                }
            })
        };

        let monthlyData = null;
        
        if (traffic.monthly) {
            monthlyData = {
                labels: this.state.monthNames,
                datasets: this.state.vehicleTypes.map(type => {
                    let dataset = [];

                    this.state.monthNames.forEach(monthName => {
                        const monthStat = traffic.monthly[monthName.toLowerCase()];
                        if (monthStat) {
                            const count = Math.round(monthStat[type.key]);
                            dataset.push(count);
                        }
                        else {
                            dataset.push(null);
                        }
                    });

                    return {
                        label: type.name,
                        data: dataset,
                        borderColor: type.color,
                        backgroundColor: type.color + '80',
                        borderWidth: 1
                    }
                })
            };
        }

        return {
            weekday: {
                data: weekdayData,
                options: hourlyOptions
            },
            weekend: {
                data: weekendData,
                options: hourlyOptions
            },
            daily: {
                data: dailyData,
                options: options
            },
            monthly: {
                data: monthlyData,
                options: options
            }
        }
    });

    handleHourlyTypeChange = (key) => {
        this.setState({ selectedHourlyTab: key });
    }

    handleTabChange = (tab) => { 
        this.setState({ selectedTab: tab });
    }

    handleToggleChart = () => {
        this.setState({ showChart: !this.state.showChart });
    }

    handleEditCountPoints = () => {
        let state = {...this.state.countPoints};
        state.editMode = true;

        const countPoints = this.props.countPoints.map(cp => {
            return {
                id: cp.id,
                lat: cp.location.lat,
                lng: cp.location.lng,
                roadName: cp.roadName,
                pin: cp.isActive ? pinSelected : pinUnselected,
                isActive: cp.isActive
            };
        });

        state.records = countPoints;
    
        if (this.props.renderCountLocations) {
            this.props.renderCountLocations(countPoints, this.handleCountPointToggle);
        }

        this.setState({ countPoints: state });
    }

    handleCountPointToggle = (countPoint) => {
        let state = {...this.state.countPoints};
        let foundCountPoint = state.records.find(cp => cp.id === countPoint.id);
        if (!foundCountPoint) return;

        foundCountPoint.isActive = !foundCountPoint.isActive;
        foundCountPoint.pin = foundCountPoint.isActive ? pinSelected : pinUnselected;
        this.setState({ countPoints: state });

        if (this.props.renderCountLocations) {
            this.props.renderCountLocations(state.records, this.handleCountPointToggle);
        }
    }

    handleSaveCountPoints = () => {
        if (!this.props.saveDriveCountLocations) return;

        let state = {...this.state.countPoints};
        state.saving = true;
        this.setState({ countPoints: state });

        const activeCountPointIds = this.state.countPoints.records
            .filter(cp => cp.isActive)
            .map(cp => cp.id);

        this.props.saveDriveCountLocations(activeCountPointIds)
            .then(() => {
                let state = {...this.state.countPoints};
                state.records = [];
                state.editMode = false;
                state.saving = false;
                this.setState({ countPoints: state });
        
                if (this.props.renderCountLocations) {
                    this.props.renderCountLocations([]);
                }
            });
    }

    handleCancelCountPoints = () => {
        let countPoints = {...this.state.countPoints};
        countPoints.records = [];
        countPoints.editMode = false;
        this.setState({ countPoints: countPoints });

        if (this.props.renderCountLocations) {
            this.props.renderCountLocations([]);
        }
    }

    ordinal = (n) => {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return (s[(v - 20) % 10] || s[v] || s[0]);
    }

    getSummary = (dailyByType, dailyAverageByType) => {
        const total = dailyByType.total;
        const rank = dailyByType.rank + this.ordinal(dailyByType.rank);
        const maxRank = dailyByType.maxRankings;

        const average = dailyAverageByType.total;

        return <>With an average of {total} vehicles per day, this drive is the {rank} busiest in the UK (out of {maxRank}). The UK average is {average}.</>
    }

    render() {
        const traffic = this.props.trafficData;
        const dailyByType = traffic ? traffic.dailyByType : null;

        const adminControls = (
            <aside className="admin-traffic-controls">
                {!this.state.countPoints.editMode &&
                    <button className="button" onClick={this.handleEditCountPoints}>Edit Count Points</button>
                }

                {this.state.countPoints.editMode && <>
                    <button className="button" onClick={this.handleSaveCountPoints}>Save</button>
                    <button className="link-button" onClick={this.handleCancelCountPoints}>Cancel</button>
                </>}
            </aside>
        );
        
        if (!traffic || !dailyByType) {
            return (
                <div className="traffic-info no-traffic-info">
                    <p className="message">Sorry, no traffic data is available for this drive.</p>
                    {this.props.adminMode && adminControls}
                </div>
            );
        }

        const chartData = this.processChartData(traffic);

        const weekday = traffic.hourly.weekday;
        const weekend = traffic.hourly.weekend;
        
        const dailyAverageByType = traffic.dailyAverageByType;

        return (
            <div className="traffic-info">
                <header className="header">
                    <nav className="navigation tab-list" role="tablist" aria-label="Traffic information">
                        <button id="traffic_info_summary_tab" className={`link-button tab-item` + (this.state.selectedTab === `summary` ? ` selected` : ``)}
                            role="tab" tabIndex={this.state.selectedTab !== 'summary' ? -1 : null}
                            aria-controls="traffic_info_summary_content" aria-selected={this.state.selectedTab === 'summary'}
                            type="button" onClick={() => this.handleTabChange('summary')}>Summary</button>

                        <button id="traffic_info_hourly_tab" className={`link-button tab-item` + (this.state.selectedTab === `hourly` ? ` selected` : ``)}
                            role="tab" tabIndex={this.state.selectedTab !== 'hourly' ? -1 : null}
                            aria-controls="traffic_info_hourly_content" aria-selected={this.state.selectedTab === 'hourly'}
                            type="button" onClick={() => this.handleTabChange('hourly')}>Hourly</button>

                        <button id="traffic_info_daily_tab" className={`link-button tab-item` + (this.state.selectedTab === `daily` ? ` selected` : ``)}
                            role="tab" tabIndex={this.state.selectedTab !== 'daily' ? -1 : null}
                            aria-controls="traffic_info_daily_content" aria-selected={this.state.selectedTab === 'daily'}
                            type="button" onClick={() => this.handleTabChange('daily')}>Daily</button>

                        {traffic.monthly &&
                            <button id="traffic_info_monthly_tab" className={`link-button tab-item` + (this.state.selectedTab === `monthly` ? ` selected` : ``)}
                                role="tab" tabIndex={this.state.selectedTab !== 'monthly' ? -1 : null}
                                aria-controls="traffic_info_monthly_content" aria-selected={this.state.selectedTab === 'monthly'}
                                type="button" onClick={() => this.handleTabChange('monthly')}>Monthly</button>
                        }
                    </nav>

                    {this.state.selectedTab !== `summary` &&
                        <nav className="table-chart-tabs tab-list" aria-label="Toggle between table and chart">
                            <button className={`link-button tab-item` + (this.state.showChart ? `` : ` selected`)}
                                type="button" onClick={this.handleToggleChart}>
                                <FontAwesomeIcon className="icon" icon={faTable}/>
                                <span className="text">Table</span>
                            </button>

                            <button className={`link-button tab-item` + (this.state.showChart ? ` selected` : ``)}
                                type="button" onClick={this.handleToggleChart}>
                                <FontAwesomeIcon className="icon" icon={faChartLine}/>
                                <span className="text">Chart</span>
                            </button>
                        </nav>
                    }
                </header>

                
                <section className={`traffic-info-section ` + (this.state.selectedTab === 'summary' ? `tab-visible` : `tab-hidden`)}
                    id="traffic_info_summary_content" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_summary_tab">
                    <div className="scrollable-table">
                        <table className="traffic-data-table traffic-summary-table">
                            <thead>
                                <tr>
                                    <th className="vehicle-col"></th>
                                    <th className="hour-col">Daily Average</th>
                                    <th className="hour-col">UK Average</th>
                                    <th className="hour-col">Rank</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.vehicleTypes.map(type => 
                                    <tr key={type.key}>
                                        <th className="vehicle-col">{type.name}</th>
                                        <td className="hour-col">{dailyByType[type.key]}</td>
                                        <td className="hour-col">{dailyAverageByType[type.key]}</td>
                                        <td className="hour-col">{dailyByType[type.key + 'Rank']} / {dailyByType.maxRankings}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="vehicle-col">Total</th>
                                    <td className="hour-col">{dailyByType.total}</td>
                                    <td className="hour-col">{dailyAverageByType.total}</td>
                                    <td className="hour-col">{dailyByType.rank} / {dailyByType.maxRankings}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <p className="traffic-summary">{this.getSummary(dailyByType, dailyAverageByType)}</p>
                </section>

                <section className={`traffic-info-section ` + (this.state.selectedTab === 'hourly' ? `tab-visible` : `tab-hidden`)}
                    id="traffic_info_hourly_content" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_hourly_tab">

                    <div id="traffic_info_hourly_weekday_content" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_hourly_weekday_tab"
                        className={`traffic-info-sub-section ` + (this.state.selectedHourlyTab === 'weekday' ? `tab-visible` : `tab-hidden`)}>

                        <div className="scrollable-table" style={{ 'display': this.state.showChart ? 'none': 'block' }}>
                            <table className="traffic-data-table daily-traffic-table">
                                <thead>
                                    <tr>
                                        <th className="vehicle-col"></th>
                                        {Object.keys(weekday).map(hour =>
                                            <th key={hour} className="hour-col">{hour}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.vehicleTypes.map(type => 
                                        <tr key={type.key}>
                                            <th className="vehicle-col">{type.name}</th>
                                            {Object.keys(weekday).map(hour =>
                                                <td key={hour} className="hour-col">{weekday[hour][type.key].toLocaleString()}</td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className="vehicle-col">Total</th>
                                        {Object.keys(weekday).map(hour =>
                                            <td key={hour} className="hour-col">{weekday[hour].total.toLocaleString()}</td>
                                        )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <Line data={chartData.weekday.data} options={chartData.weekday.options}
                            style={{ 'display': this.state.showChart ? 'block': 'none' }} />
                    </div>

                    <div id="traffic_info_hourly_weekend_tab" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_hourly_weekend_content"
                        className={`traffic-info-sub-section ` + (this.state.selectedHourlyTab === 'weekend' ? `tab-visible` : `tab-hidden`)}>

                        <div className="scrollable-table" style={{ 'display': this.state.showChart ? 'none': 'block' }}>
                            <table className="traffic-data-table daily-traffic-table">
                                <thead>
                                    <tr>
                                        <th className="vehicle-col"></th>
                                        {Object.keys(weekend).map(hour =>
                                            <th key={hour} className="hour-col">{hour}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.vehicleTypes.map(type => 
                                        <tr key={type.key}>
                                            <th className="vehicle-col">{type.name}</th>
                                            {Object.keys(weekend).map(hour =>
                                                <td key={hour} className="hour-col">{weekend[hour][type.key].toLocaleString()}</td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className="vehicle-col">Total</th>
                                        {Object.keys(weekend).map(hour =>
                                            <td key={hour} className="hour-col">{weekend[hour].total.toLocaleString()}</td>
                                        )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <Line data={chartData.weekend.data} options={chartData.weekend.options}
                            style={{ 'display': this.state.showChart ? 'block': 'none' }} />
                    </div>

                    <footer className="footer">
                        <nav className="tab-list" role="tablist" aria-label="Toggle between weekday and weekend stats">
                            <button id="traffic_info_hourly_weekday_tab" className={`link-button tab-item` + (this.state.selectedHourlyTab === 'weekday' ? ` selected` : ``)}
                                tabIndex={this.state.selectedHourlyTab === 'weekday' ? -1 : null} role="tab"
                                aria-controls="traffic_info_hourly_weekday_content" aria-selected={this.state.selectedHourlyTab === 'weekday'}
                                type="button" onClick={() => this.handleHourlyTypeChange('weekday')}>Weekday</button>

                            <button id="traffic_info_hourly_weekend_tab" className={`link-button tab-item` + (this.state.selectedHourlyTab === 'weekend' ? ` selected` : ``)}
                                tabIndex={this.state.selectedHourlyTab === 'weekend' ? -1 : null} role="tab"
                                aria-controls="traffic_info_hourly_weekend_content" aria-selected={this.state.selectedHourlyTab === 'weekend'}
                                type="button" onClick={() => this.handleHourlyTypeChange('weekend')}>Weekend</button>
                        </nav>

                        {this.props.adminMode && adminControls}
                    </footer>
                </section>

                <section className={`traffic-info-section ` + (this.state.selectedTab === 'daily' ? `tab-visible` : `tab-hidden`)}
                    id="traffic_info_daily_content" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_daily_tab">

                    <div className="scrollable-table" style={{ 'display': this.state.showChart ? 'none': 'block' }}>
                        <table className="traffic-data-table weekly-traffic-table">
                            <thead>
                                <tr>
                                    <th className="vehicle-col"></th>
                                    {this.state.dayNames.map((day, i) =>
                                        <th key={i} className="day-col">{day}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.vehicleTypes.map(type => 
                                    <tr key={type.key}>
                                        <th className="vehicle-col">{type.name}</th>
                                        {this.state.dayNames.map((day, i) =>
                                            <td key={i} className="day-col">
                                                {traffic.daily[day.toLowerCase()] ? traffic.daily[day.toLowerCase()][type.key].toLocaleString() : '-'}
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="vehicle-col">Total</th>
                                    {this.state.dayNames.map((day, i) =>
                                        <td key={i} className="day-col">
                                            {traffic.daily[day.toLowerCase()] ? traffic.daily[day.toLowerCase()].total.toLocaleString() : '-'}
                                        </td>
                                    )}
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <Bar data={chartData.daily.data} options={chartData.daily.options}
                        style={{ 'display': this.state.showChart ? 'block': 'none' }} />

                    <footer className="footer">
                        {this.props.adminMode && adminControls}
                    </footer>
                </section>

                {traffic.monthly &&
                    <section className={`traffic-info-section ` + (this.state.selectedTab === 'monthly' ? `tab-visible` : `tab-hidden`)}
                        id="traffic_info_monthly_content" tabIndex="0" role="tabpanel" aria-labelledby="traffic_info_monthly_tab">

                        <div className="scrollable-table" style={{ 'display': this.state.showChart ? 'none': 'block' }}>
                            <table className="traffic-data-table monthly-traffic-table">
                                <thead>
                                    <tr>
                                        <th className="vehicle-col"></th>
                                        {this.state.monthNames.map((month, i) =>
                                            <th key={i} className={`day-col` + (traffic.monthly[month] ? `` : ` empty-col`)}>{month}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.vehicleTypes.map(type => 
                                        <tr key={type.key}>
                                            <th className="vehicle-col">{type.name}</th>
                                            {this.state.monthNames.map((month, i) =>
                                                <td key={i} className={`day-col` + (traffic.monthly[month] ? `` : ` empty-col`)}>
                                                    {traffic.monthly[month.toLowerCase()] ? traffic.monthly[month.toLowerCase()][type.key].toLocaleString() : '-'}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className="vehicle-col">Total</th>
                                        {this.state.monthNames.map((month, i) =>
                                            <td key={i} className={`day-col` + (traffic.monthly[month] ? `` : ` empty-col`)}>
                                                {traffic.monthly[month.toLowerCase()] ? traffic.monthly[month.toLowerCase()].total.toLocaleString() : '-'}
                                            </td>
                                        )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <Bar data={chartData.monthly.data} options={chartData.monthly.options}
                            style={{ 'display': this.state.showChart ? 'block': 'none' }} />

                        <footer className="footer">
                            {this.props.adminMode && adminControls}
                        </footer>
                    </section>
                }
            </div>
        );
    }
}

export default TrafficInfo;