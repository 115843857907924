// https://github.com/david-r-edgar/google-maps-data-parameter-parser

/**
 * Generic tree implementation
 *
 * This class represents any node in the tree.
 */
var Node = function(val) {

    this.val = val;
    this.children = [];
    this.parent = null;

    /**
     * Sets the parent node of this node.
     */
    this.setParentNode = function(node) {
        this.parent = node;
        node.children[node.children.length] = this;
    }

    /**
     * Gets the parent node of this node.
     */
    this.getParentNode = function() {
        return this.parent;
    }

    /**
     * Adds a child node of this node.
     */
    this.addChild = function(node) {
        node.parent = this;
        this.children[this.children.length] = node;
    }

    /**
     * Gets the array of child nodes of this node.
     */
    this.getChildren = function() {
        return this.children;
    }

    /**
     * Removes all the children of this node.
     */
    this.removeChildren = function() {
        for (var child of this.children) {
            child.parent = null;
        }
        this.children = [];
    }

    /**
     * Recursively counts the number of all descendants, from children down, and
     * returns the total number.
     */
    this.getTotalDescendantCount = function() {
        var count = 0;
        for (var child of this.children) {
            count += child.getTotalDescendantCount();
        }
        return count + this.children.length;
    }
}

/**
 * Protocol Buffer implementation, which extends the functionality of Node
 * while specifically typing the stored value
 */
var PrBufNode = function(id, type, value) {
    this.val = {id, type, value}
    this.children = [];
    this.parent = null;
}

PrBufNode.prototype = new Node();
PrBufNode.prototype.constructor = PrBufNode;

PrBufNode.prototype.id = function() { return this.val.id; }
PrBufNode.prototype.type = function() { return this.val.type; }
PrBufNode.prototype.value = function() { return this.val.value; }

/**
 * Compares the number of descendants with the value specified in the map element.
 * If all the children have not yet been added, we continue adding to this element.
 */
PrBufNode.prototype.findLatestIncompleteNode = function() {

    //if it's a branch (map) node ('m') and has room,
    //or if it's the root (identified by having a null parent), which has no element limit,
    //then return this node
    if (((this.val.type === 'm') && (this.val.value > this.getTotalDescendantCount()))
        || (!this.parent)) {
        return this;
    }
    else {
        return this.parent.findLatestIncompleteNode();
    }
}

/**
 * Parses the input URL 'data' protocol buffer parameter into a tree
 */
PrBufNode.create = function(urlToParse) {
    var rootNode = null;
    var re = /data=!([^?&]+)/;
    var dataArray = urlToParse.match(re);
    if (!dataArray || dataArray.length < 1) {
        re = /mv:!([^?&]+)/;
        dataArray = urlToParse.match(re);
    }
    if (dataArray && dataArray.length >= 1) {
        rootNode = new PrBufNode();
        var workingNode = rootNode;
        //we iterate through each of the elements, creating a node for it, and
        //deciding where to place it in the tree
        var elemArray = dataArray[1].split("!");
        for (var i=0; i < elemArray.length; i++) {
            var elemRe = /^([0-9]+)([a-z])(.+)$/;
            var elemValsArray = elemArray[i].match(elemRe);
            if (elemValsArray && elemValsArray.length > 3) {
                var elemNode = new PrBufNode(elemValsArray[1], elemValsArray[2], elemValsArray[3]);
                workingNode.addChild(elemNode);
                workingNode = elemNode.findLatestIncompleteNode();
            }
        }
    }
    return rootNode;
}




var GmdpPoint = function(lat, lng) {
    this.lat = lat;
    this.lng = lng;
}



/**
 * Represents a basic waypoint, with latitude and longitude.
 *
 * If both are not specified, the waypoint is considered to be valid
 * but empty waypoint (these can exist in the data parameter, where
 * the coordinates have been specified in the URL path.
 */
var GmdpWaypoint = function(lat, lng, primary) {
    this.lat = lat;
    this.lng = lng;
    this.primary = primary ? true : false;
}

/**
 * Represents a basic route, comprised of an ordered list of
 * GmdpWaypoint objects.
 */
var GmdpRoute = function() {
    this.route = [];
}

/**
 * Pushes a GmdpWaypoint on to the end of this GmdpRoute.
 */
GmdpRoute.prototype.pushWaypoint = function(wpt) {
    if (wpt instanceof GmdpWaypoint) {
        this.route.push(wpt);
    }
}

/**
 * Sets the mode of transportation.
 * If the passed parameter represents one of the integers normally used by Google Maps,
 * it will be interpreted as the relevant transport mode, and set as a string:
 * "car", "bike", "foot", "transit", "flight"
 */
GmdpRoute.prototype.setTransportation = function(transportation) {
    switch (transportation) {
        case '0':
            this.transportation = "car";
            break;
        case '1':
            this.transportation = "bike";
            break;
        case '2':
            this.transportation = "foot";
            break;
        case '3':
            this.transportation = "transit";
            break;
        case '4':
            this.transportation = "flight";
            break;
        default:
            this.transportation = transportation;
            break;
    }
}

/**
 * Returns the mode of transportation (if any) for the route.
 */
GmdpRoute.prototype.getTransportation = function() {
    return this.transportation;
}

GmdpRoute.prototype.setUnit = function(unit) {
    switch (unit) {
        case '0':
            this.unit = "km";
            break;
        case '1':
            this.unit = "miles";
            break;
        default:
            break;
    }
}

GmdpRoute.prototype.getUnit = function() {
    return this.unit;
}

GmdpRoute.prototype.setRoutePref = function(routePref) {
    switch (routePref) {
        case '0':
        case '1':
            this.routePref = "best route";
            break;
        case '2':
            this.routePref = "fewer transfers";
            break;
        case '3':
            this.routePref = "less walking";
            break;
        default:
            break;
    }
}

GmdpRoute.prototype.getRoutePref = function() {
    return this.routePref;
}

GmdpRoute.prototype.setArrDepTimeType = function(arrDepTimeType) {
    switch (arrDepTimeType) {
        case '0':
            this.arrDepTimeType = "depart at";
            break;
        case '1':
            this.arrDepTimeType = "arrive by";
            break;
        case '2':
            this.arrDepTimeType = "last available";
            break;
        default:
            break;
    }
}

GmdpRoute.prototype.getArrDepTimeType = function() {
    return this.arrDepTimeType;
}

GmdpRoute.prototype.addTransitModePref = function(transitModePref) {
    //there can be multiple preferred transit modes, so we store them in an array
    //we assume there will be no duplicate values, but it probably doesn't matter
    //even if there are
    switch (transitModePref) {
        case '0':
            this.transitModePref.push("bus");
            break;
        case '1':
            this.transitModePref.push("subway");
            break;
        case '2':
            this.transitModePref.push("train");
            break;
        case '3':
            this.transitModePref.push("tram / light rail");
            break;
        default:
            break;
    }
}

GmdpRoute.prototype.getTransitModePref = function() {
    return this.transitModePref;
}



/**
 * Returns the list of all waypoints belonging to this route.
 */
GmdpRoute.prototype.getAllWaypoints = function() {
    return this.route;
}


function GmdpException(message) {
    this.message = message;
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
        Error.captureStackTrace(this, GmdpException);
    else
        this.stack = (new Error()).stack;
}

GmdpException.prototype = Object.create(Error.prototype);
GmdpException.prototype.name = "GmdpException";

/**
 * Represents a google maps data parameter, constructed from the passed URL.
 *
 * Utility methods defined below allow the user to easily extract interesting
 * information from the data parameter.
 */
var Gmdp = function(url) {
    this.prBufRoot = PrBufNode.create(url);
    this.mapType = "map";
    this.pins = [];

    if (!this.prBufRoot) {
        throw new GmdpException("no parsable data parameter found");
    }

    //the main top node for routes is 4m; other urls (eg. streetview) feature 3m etc.
    var routeTop = null;
    var streetviewTop = null;

    for (let child of this.prBufRoot.getChildren()) {
        if (child.id() === '1' && child.type() === 'm') {
            var localSearchMapChildren = child.getChildren();
        }
        else if (child.id() === '3' && child.type() === 'm') {
            var mapTypeChildren = child.getChildren();
            if (mapTypeChildren && mapTypeChildren.length >= 1) {
                if (mapTypeChildren[0].id() === '1' && mapTypeChildren[0].type() === 'e') {
                    switch (mapTypeChildren[0].value()) {
                        case '1':
                            this.mapType = "streetview";
                            streetviewTop = child;
                            break;
                        case '3':
                            this.mapType = "earth";
                            break;
                        default:
                            break;
                    }
                }
            }
        } else if (child.id() === '4' && child.type() === 'm') {
            routeTop = child;
        }
    }
    if (routeTop) {
        var pinData = null;
        var directions = null;
        var oldDirections = null;
        for (let child of routeTop.getChildren()) {
            if (child.id() === '3' && child.type() === 'm') {
                pinData = child;
            } else if (child.id() === '4' && child.type() === 'm') {
                directions = child;
            } else if (child.id() === '1' && child.type() === 'm') {
                //1m_ indicates the old route, pin, or search location
                //it seems that there can't be both a current route and an old route, for example
                //so we can treat its children as regular pins or directions
                for (var grandchild of child.getChildren()) {
                    if (grandchild.id() === '3' && grandchild.type() === 'm') {
                        if (!pinData) {
                            pinData = grandchild;
                        }
                    } else if (grandchild.id() === '4' && grandchild.type() === 'm') {
                        if (!directions) {
                            oldDirections = grandchild;
                        }
                    }
                }
            }
        }
    }
    if (pinData) {
        var pinPoint = this.parsePin(pinData);
        if (pinPoint) {
            this.pushPin(pinPoint);
        }
    }
    if (directions) {
        this.route = this.parseRoute(directions);
    } else if (oldDirections) {
        this.oldRoute = this.parseRoute(oldDirections);
    }
    if (streetviewTop) {
        var streetviewChildren = streetviewTop.getChildren();
        for (var streetviewChild of streetviewChildren) {
            if (streetviewChild.id() === '3' && streetviewChild.type() === 'm') {
                var svInfos = streetviewChild.getChildren();
                for (var svInfo of svInfos) {
                    if (svInfo.id() === '2' && svInfo.type() === 'e') {
                        if (svInfo.value() === '4') {
                            //!2e4!3e11 indicates a photosphere, rather than standard streetview
                            //but the 3e11 doesn't seem to matter too much (?)
                            this.mapType = "photosphere";
                        }
                    }
                    if (svInfo.id() === '6' && svInfo.type() === 's') {
                        this.svURL = decodeURIComponent(svInfo.value());
                    }
                }
            }
        }
    }
    if (localSearchMapChildren && localSearchMapChildren.length >= 1) {
        var lsmLat = undefined;
        var lsmLng = undefined;
        var lsmResolution = undefined;
        for (var field of localSearchMapChildren) {
            if (field.type() === 'd') {
                switch (field.id()) {
                    case '1':
                        lsmResolution = field.value();
                        break;
                    case '2':
                        lsmLng = field.value();
                        break;
                    case '3':
                        lsmLat = field.value();
                        break;
                    default:
                        break;
                }
            }
        }
        if (lsmLat !== undefined && lsmLng !== undefined && lsmResolution !== undefined) {
            this.localSearchMap = {
                centre: new GmdpPoint(lsmLat, lsmLng),
                resolution: lsmResolution
            }
        }
    }
}


Gmdp.prototype.parsePin = function(pinNode) {
    var pinPoint = null;
    for (var primaryChild of pinNode.getChildren()) {
        if (primaryChild.id() === '8' && primaryChild.type() === 'm') {
            let coordNodes = primaryChild.getChildren();
            if (coordNodes &&
                coordNodes.length >= 2 &&
                coordNodes[0].id() === '3' &&
                coordNodes[0].type() === 'd' &&
                coordNodes[1].id() === '4' &&
                coordNodes[1].type() === 'd') {
                pinPoint = new GmdpPoint(coordNodes[0].value(), coordNodes[1].value());
            }
        }
    }
    return pinPoint;
}

Gmdp.prototype.parseRoute = function(directionsNode) {

    var route = new GmdpRoute();
    route.arrDepTimeType = "leave now"; //default if no value is specified
    route.avoidHighways = false;
    route.avoidTolls = false;
    route.avoidFerries = false;
    route.transitModePref = [];

    for (var primaryChild of directionsNode.getChildren()) {
        if (primaryChild.id() === '1' && primaryChild.type() === 'm') {
            if (primaryChild.value() === '0') {
                route.pushWaypoint(new GmdpWaypoint(undefined, undefined, true));
            }
            else {
                var addedPrimaryWpt = false;
                var wptNodes = primaryChild.getChildren();
                for (var wptNode of wptNodes) {
                    if (wptNode.id() === '2') {
                        //this is the primary wpt, add coords
                        let coordNodes = wptNode.getChildren();
                        if (coordNodes &&
                            coordNodes.length >= 2 &&
                            coordNodes[0].id() === '1' &&
                            coordNodes[0].type() === 'd' &&
                            coordNodes[1].id() === '2' &&
                            coordNodes[1].type() === 'd') {
                                route.pushWaypoint(
                                    new GmdpWaypoint(coordNodes[1].value(),
                                                    coordNodes[0].value(),
                                                    true));
                        }
                        addedPrimaryWpt = true;
                    } else if (wptNode.id() === '3') {
                        //this is a secondary (unnamed) wpt
                        //
                        //but first, if we haven't yet added the primary wpt,
                        //then the coordinates are apparently not specified,
                        //so we should add an empty wpt
                        if (!addedPrimaryWpt) {
                            route.pushWaypoint(new GmdpWaypoint(undefined, undefined, true));
                            addedPrimaryWpt = true;
                        }

                        //now proceed with the secondary wpt itself
                        var secondaryWpts = wptNode.getChildren();
                        if (secondaryWpts && secondaryWpts.length > 1) {
                            let coordNodes = secondaryWpts[0].getChildren();
                            if (coordNodes &&
                                coordNodes.length >= 2 &&
                                coordNodes[0].id() === '1' &&
                                coordNodes[0].type() === 'd' &&
                                coordNodes[1].id() === '2' &&
                                coordNodes[1].type() === 'd') {
                                    route.pushWaypoint(
                                        new GmdpWaypoint(coordNodes[1].value(),
                                                        coordNodes[0].value(),
                                                        false));
                            }
                        }
                    } else if (wptNode.id() === '4' && wptNode.type() === 'e') {
                        route.pushWaypoint(new GmdpWaypoint(undefined, undefined, true));
                        addedPrimaryWpt = true;
                    }
                }
            }
        } else if (primaryChild.id() === '2' && primaryChild.type() === 'm') {
            var routeOptions = primaryChild.getChildren();
            for (var routeOption of routeOptions) {
                if (routeOption.id() === '1' && routeOption.type() === 'b') {
                    route.avoidHighways = true;
                }
                else if (routeOption.id() === '2' && routeOption.type() === 'b') {
                    route.avoidTolls = true;
                }
                else if (routeOption.id() === '3' && routeOption.type() === 'b') {
                    route.avoidFerries = true;
                }
                else if (routeOption.id() === '4' && routeOption.type() === 'e') {
                    route.setRoutePref(routeOption.value());
                }
                else if (routeOption.id() === '5' && routeOption.type() === 'e') {
                    route.addTransitModePref(routeOption.value());
                }
                else if (routeOption.id() === '6' && routeOption.type() === 'e') {
                    route.setArrDepTimeType(routeOption.value());
                }
                if (routeOption.id() === '8' && routeOption.type() === 'j') {
                    route.arrDepTime = routeOption.value(); //as a unix timestamp
                }
            }
        } else if (primaryChild.id() === '3' && primaryChild.type() === 'e') {
            route.setTransportation(primaryChild.value());
        } else if (primaryChild.id() === '4' && primaryChild.type() === 'e') {
            route.setUnit(primaryChild.value());
        }
    }

    return route;
}


Gmdp.prototype.pushPin = function(wpt) {
    if (wpt instanceof GmdpPoint) {
        this.pins.push(wpt);
    }
}

/**
 * Returns the route defined by this data parameter.
 */
Gmdp.prototype.getRoute = function() {
    return this.route;
}

/**
 * Returns the route defined by this data parameter.
 */
Gmdp.prototype.getOldRoute = function() {
    return this.oldRoute;
}

/**
 * Returns the main map type ("map", "earth").
 */
Gmdp.prototype.getMapType = function() {
    return this.mapType;
}

/**
 * Returns the main map type ("map", "earth").
 */
Gmdp.prototype.getStreetviewURL = function() {
    return this.svURL;
}

/**
 * Returns the pinned location.
 */
Gmdp.prototype.getPins = function() {
    return this.pins;
}

/**
 * Returns local search map data.
 */
Gmdp.prototype.getLocalSearchMap = function() {
    return this.localSearchMap;
}

const TILE_SIZE = 256;

// see https://stackoverflow.com/questions/12291459/google-maps-heatmap-layer-point-radius
function MercatorProjection() {
    this.pixelOrigin_ = {
        x: TILE_SIZE / 2,
        y: TILE_SIZE / 2
    };
    
    this.pixelsPerLonDegree_ = TILE_SIZE / 360;
    this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
}

MercatorProjection.prototype.fromLatLngToPoint = function (latLng, opt_point) {
    let point = opt_point || { x: 0, y: 0 };
    let origin = this.pixelOrigin_;

    point.x = origin.x + latLng.lng * this.pixelsPerLonDegree_;

    // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
    // 89.189.  This is about a third of a tile past the edge of the world
    // tile.
    let siny = bound(Math.sin(deg2rad(latLng.lat)), - 0.9999, 0.9999);
    point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian_;
    return point;
};

MercatorProjection.prototype.fromPointToLatLng = function (point) {
    let origin = this.pixelOrigin_;
    let lng = (point.x - origin.x) / this.pixelsPerLonDegree_;
    let latRadians = (point.y - origin.y) / -this.pixelsPerLonRadian_;
    let lat = rad2deg(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);

    return  {
        lat: lat,
        lng: lng
    };
};

const SAFE_CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
const MULTIPLIER = 100000;
const DIVIDER = 32;

function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

function rad2deg(rad) {
    return rad / (Math.PI / 180);
}

function bound(value, opt_min, opt_max) {
    if (opt_min !== null) value = Math.max(value, opt_min);
    if (opt_max !== null) value = Math.min(value, opt_max);
    return value;
}

const functions = {
    parseGoogleMapsUrl(mapsUrl) {
        let inputWaypointsMatch = mapsUrl.match(/\/dir\/(.+)\/+@/i);
        let dataMatch = mapsUrl.match(/\/data=(.+)/i);
        if (!inputWaypointsMatch || !dataMatch) {
            return null;
        }

        // extract waypoints from what the user enters via the
        // directions input boxes on the top left of Google Maps
        let inputWaypoints = [];

        inputWaypointsMatch[1].split('/').forEach(waypoint => {
            waypoint = decodeURIComponent(waypoint).trim();
            let coordsMatch = waypoint.match(/^(-?[0-9]{1,3}\.[0-9]+) *, *(-?[0-9]{1,3}\.[0-9]+)$/);
            if (coordsMatch) {
                inputWaypoints.push({
                    lat: parseFloat(coordsMatch[1]),
                    lng: parseFloat(coordsMatch[2])
                });
            }
            else {
                inputWaypoints.push(null);
            }
        });

        // parse the protocol buffer "data" querystring param
        let gmdp = new Gmdp(mapsUrl);
        let route = gmdp.getRoute();

        // extract waypoints contained inside the protocol buffer data,
        // these normally come from the user dragging the route around via the mouse
        let protocolWaypoints = [];

        route.route.forEach((routeItem, i) => {
            if (routeItem.lat && routeItem.lng) {
                protocolWaypoints.push({
                    lat: parseFloat(routeItem.lat),
                    lng: parseFloat(routeItem.lng)
                });
            }
            else {
                protocolWaypoints.push(null);
            }
        });

        let waypoints = [];

        protocolWaypoints.forEach(protoWaypoint => {
            if (protoWaypoint) {
                waypoints.push(protoWaypoint);
            }
            else {
                waypoints.push(inputWaypoints.shift());
            }
        });

        inputWaypoints.forEach(inputWaypoint => {
            waypoints.push(inputWaypoint);
        });

        waypoints = waypoints.filter(w => w !== null);

        let directionsData = {
            avoidFerries: route.avoidFerries,
            avoidHighways: route.avoidHighways,
            avoidTolls: route.avoidTolls,
            transportation: route.transportation,
            waypoints: waypoints
        };

        return directionsData;
    },

    encodePoints(points) {
        // https://github.com/ljanecek/point-compression/blob/master/index.js

        let result = "";
        let latitude = 0;
        let longitude = 0;
    
        for (let i in points) {
            let newLat = Math.round(points[i].lat * MULTIPLIER);
            let newLon = Math.round(points[i].lng * MULTIPLIER);
    
            let dy = newLat - latitude;
            let dx = newLon - longitude;
    
            latitude = newLat;
            longitude = newLon;
            dy = (dy << 1) ^ (dy >> 31);
            dx = (dx << 1) ^ (dx >> 31);
    
            let index = ((dy + dx) * (dy + dx + 1)) / 2 + dy;
    
            while (index > 0) {
                let rem = index & 31;
    
                index = (index - rem) / DIVIDER;
    
                if (index > 0) {
                    rem += DIVIDER;
                }
    
                result += SAFE_CHARACTERS[rem];
            }
        }

        return result;
    },
    
    decodePoints(compressedValue) {
        if (!compressedValue) return [];

        let points = [];
        let pointsArray = [];
        let point = [];
        let lastLat = 0;
        let lastLon = 0;
    
        for (let i = 0; i < compressedValue.length; i++) {
            let num = SAFE_CHARACTERS.indexOf(compressedValue[i]);
    
            if (num < DIVIDER) {
                point.push(num);
                pointsArray.push(point);
                point = [];
            } else {
                num -= DIVIDER;
                point.push(num);
            }
        }
    
        for (let y in pointsArray) {
            let result = 0;
            let list = pointsArray[y].reverse();
    
            for (let x in list) {
                if (result === 0) {
                    result = list[x];
                }
                else {
                    result = result * DIVIDER + list[x];
                }
            }
    
            let dIag = parseInt((Math.sqrt(8 * result + 5) - 1) / 2);
    
            let latY = result - (dIag * (dIag + 1)) / 2;
            let lonX = dIag - latY;
    
            if (latY % 2 === 1) {
                latY = (latY + 1) * -1;
            }
            if (lonX % 2 === 1) {
                lonX = (lonX + 1) * -1;
            }
    
            latY /= 2;
            lonX /= 2;
            let lat = latY + lastLat;
            let lng = lonX + lastLon;
    
            lastLat = lat;
            lastLon = lng;
            lat /= MULTIPLIER;
            lng /= MULTIPLIER;
    
            points.push({
                lat: lat, 
                lng: lng
            });
        }
    
        return points;
    },
    
    getDistanceFromLatLngInKm: function(start, end) {
        // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula

        if (!start || !end) return 0;

        let lat1 = start.lat;
        let lon1 = start.lng;
        let lat2 = end.lat;
        let lon2 = end.lng;

        let R = 6371; // Radius of the earth in km
        let dLat = deg2rad(lat2 - lat1);  // deg2rad below
        let dLon = deg2rad(lon2 - lon1); 
        let a = 
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ; 
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
        let d = R * c; // Distance in km
        return d;
    },

    getBoundsForSplitView: (bounds, options) => {
        options = options || {};
        let isRight = options.side === 'right';

        let latDifference = bounds.ne.lat - bounds.sw.lat;
        let lngDifference = bounds.ne.lng - bounds.sw.lng;
        
        let squareBounds = {
            ne: {
                lat: bounds.ne.lat,
                lng: bounds.ne.lng
            },
            sw: {
                lat: bounds.sw.lat,
                lng: bounds.sw.lng
            }
        };

        let portraitBounds = latDifference > lngDifference;
        if (portraitBounds) {
            squareBounds.ne.lng = bounds.ne.lng + (latDifference - (lngDifference / 2));
            squareBounds.sw.lng = bounds.sw.lng - (latDifference - (lngDifference / 2));
        }

        lngDifference = squareBounds.ne.lng - squareBounds.sw.lng;

        if (isRight) {
            return {
                sw: {
                    lat: bounds.sw.lat,
                    lng: bounds.sw.lng - (lngDifference * 2)
                },
                ne: bounds.ne
            };
        }
        else {
            return {
                sw: bounds.sw,
                ne: {
                    lat: bounds.ne.lat,
                    lng: bounds.ne.lng + (lngDifference * 2)
                }
            };
        }
    },

    simplifyLine: (points) => {
        let newPoints = [];

        let minTorerance = 3;

        newPoints.push(points[0]);

        for (let i = 1; i < points.length - 1; i = i + 1) {
            let lastPoint = newPoints[newPoints.length - 1];
            let thisPoint = points[i];
            let nextPoint = points[i + 1];

            let angle = functions.findAngle(lastPoint, thisPoint, nextPoint, i < 5);
            let angleDiff = Math.abs(180 - angle);
            angleDiff = angleDiff > 10 ? 10 : angleDiff;

            let asPercentage = (100 / 10) * angleDiff;

            let tolerance = 100 - asPercentage;
            tolerance = tolerance < minTorerance ? minTorerance : tolerance;

            let distanceToLast = functions.getDistanceFromLatLngInKm(thisPoint, lastPoint) * 1000;
            if (distanceToLast > tolerance) {
                newPoints.push(points[i]);
            }
        }

        newPoints.push(points[points.length - 1]);

        return newPoints;
    },

    findAngle: (point1, point2, point3) => {
        let AB = Math.sqrt(Math.pow(point2.lat - point1.lat, 2) + Math.pow(point2.lng - point1.lng, 2));    
        let BC = Math.sqrt(Math.pow(point2.lat - point3.lat, 2) + Math.pow(point2.lng - point3.lng, 2));
        let AC = Math.sqrt(Math.pow(point3.lat - point1.lat, 2) + Math.pow(point3.lng - point1.lng, 2));

        let acos = (BC * BC + AB * AB - AC * AC) / (2 * BC * AB);
        acos = acos > 1 ? 1 : (acos < -1 ? -1 : acos);

        return Math.acos(acos) * (180 / Math.PI);
    },

    extractRoadNameFromRoute: function(route) {
        let roads = [];

        route.legs.forEach(leg => {
            leg.steps.forEach(step => {
                let matches = step.instructions.match(/<b>(.*?)<\/b>/ig)
                if (!matches) return;

                matches.forEach(htmlSegment => {
                    let text = htmlSegment.match(/<b>(.+)<\/b>/i)[1];
                    if (!text) return;

                    text = text.trim();

                    // e.g. A723, M4, B123 etc
                    let roadNameMatch = text.match(/^[M|A|B][0-9]+$/i);
                    if (roadNameMatch) {
                        roads.push(roadNameMatch[0]);
                        return;
                    }

                    let streetOrRoadMatch = text.match(/^.+ (rd|st)$/i);
                    if (streetOrRoadMatch) {
                        roads.push(streetOrRoadMatch[0]);
                        return;
                    }
                });
            });
        });

        return roads;
    },

    getHeatmapRadius: function(map, google) {
        let radiusInMeters = 1000;
        let zoomLevel = map.getZoom();

        const zoomToRadius = {
            '0': 17710,
            '1': 13286,
            '2': 9967,
            '3': 7477,
            '4': 5609,
            '5': 4208,
            '6': 3157,
            '7': 2368,
            '8': 1780,
            '9': 1333,
            '10': 1000,
            '11': 750,
            '12': 562,
            '13': 421,
            '14': 316,
            '15': 237,
            '16': 178,
            '17': 134,
            '18': 102,
            '19': 77,
            '20': 58,
            '21': 44,
            '22': 33,
        }

        radiusInMeters = zoomToRadius[zoomLevel.toString()]

        let numTiles = 1 << zoomLevel;

        let center = map.getCenter();
        let moved = google.maps.geometry.spherical.computeOffset(center, 10000, 90);

        let projection = new MercatorProjection();
        let initCoord = projection.fromLatLngToPoint({
            lat: center.lat(),
            lng: center.lng()
        });
        let endCoord = projection.fromLatLngToPoint({
            lat: moved.lat(),
            lng: moved.lng()
        });

        let initPoint = new google.maps.Point(initCoord.x * numTiles, initCoord.y * numTiles);
        let endPoint = new google.maps.Point(endCoord.x * numTiles, endCoord.y * numTiles);
        let pixelsPerMeter = (Math.abs(initPoint.x - endPoint.x)) / 10000.0;
        let totalPixelSize = radiusInMeters * pixelsPerMeter;

        return totalPixelSize;
    }
}

export default functions;