import './App.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignInAlt, faHome, faPlus , faUser, faQuestionCircle , faLevelUpAlt, faSignOutAlt, faPlusCircle, faUserCircle, faSitemap } from '@fortawesome/pro-solid-svg-icons'
import { faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import React from 'react'
import { Switch, Route, NavLink } from 'react-router-dom'
import { Loader } from "@googlemaps/js-api-loader"
import { withRouter } from "react-router";

import Home from './pages'

import AccountSignin from './pages/account/sign-in'
import AccountRegister from './pages/account/register'
import AccountForgotPassword from './pages/account/forgot-password'
import AccountResetPassword from './pages/account/reset-password'
import AccountActivate from './pages/account/activate'

import Profile from './pages/profile'
import ProfileDrives from './pages/profile/drives'

import Drives from './pages/drives'
import Drive from './pages/drives/get'
import DrivesAdd from './pages/drives/add'
import DrivesConfirm from './pages/drives/confirm'

import About from './pages/about'
import AboutContact from './pages/about/contact'
import AboutPrivacy from './pages/about/privacy'
import AboutTerms from './pages/about/terms'

import NotFound from './pages/404';

import Storage from './utils/storage'
import AppContext from './appContext';

class App extends React.Component {
    static AuthUserTokenStorageKey = 'authUserToken';

    state = {
        showMenu: false,
        isAuthStateDetermined: false,
        authUser: null,
        map: null,
        config: null,
        streetViewActive: false,
        rootClassName: '',
        pageType: null,
        language: {
            code: 'en-gb',
            name: 'English (US)',
            isRightToLeft: false
        }
    };

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    hideMenu = (event) => {
        if (!this.state.showMenu) return;

        if (!event.target.closest('#main-menu-button')) {
            this.setState({
                showMenu: false
            });
        }
    }

    signin = (authToken, callback) => {
        if (!authToken) return;

        const storage = Storage.get(window);
        storage.setItem(App.AuthUserTokenStorageKey, authToken)

        this.getAuthUserFromToken(authToken)
            .then(authUser => {
                this.setState({
                    authUser: authUser,
                    isAuthStateDetermined: true
                }, callback);
            });
    }

    logout = () => {
        if (!window.confirm('Logout? Are you sure?')) return;

        const storage = Storage.get(window);
        storage.removeItem(App.AuthUserTokenStorageKey);

        window.location.reload();
    }

    componentDidMount() {
        console.log('Applecross Web: v' + process.env.REACT_APP_VERSION);

        const apiRootUrl = process.env.REACT_APP_API_URL;

        fetch(apiRootUrl)
            .then(response => response.json())
            .then(response => {
                if (response.code !== 0) {
                    this.setState(() => { throw new Error('Could not GET API root. Status code: ' + response.code); });
                    return;
                }

                const serverConfig = response.config;

                const fullConfig = {
                    googleApiKey: serverConfig.googleApiKey,
                    apiUrl: process.env.REACT_APP_API_URL,
                    cdnUrl: serverConfig.cdnUrl,
                    authUserTokenStorageKey: App.AuthUserTokenStorageKey,
                    email: serverConfig.email,
                    maxPhotosPerDrive: serverConfig.maxPhotosPerDrive,
                    maxRidesPerUser: serverConfig.maxRidesPerUser,
                    social: {
                        twitterUsername: serverConfig.twitterUsername,
                        youTubeChannelId: serverConfig.youTubeChannelId,
                        instagramId: serverConfig.instagramId
                    }
                };
                this.setState({ config: fullConfig });

                console.log('Applecross API: v' + response.version);

                this.setLanguage(this.state.language);

                const storage = Storage.get(window);
                const authToken = storage.getItem(App.AuthUserTokenStorageKey)
                
                if (authToken) {
                    this.getAuthUserFromToken(authToken)
                        .then(authUser => {
                            this.setState({
                                authUser: authUser,
                                isAuthStateDetermined: true
                            });

                            this.userReadyFuncs.forEach(f => f(authUser));
                        });
                }
                else {
                    this.setState({
                        authUser: null,
                        isAuthStateDetermined: true
                    });

                    this.userReadyFuncs.forEach(f => f(null));
                }

                const loader = new Loader({
                    apiKey: fullConfig.googleApiKey,
                    libraries: ['geometry', 'visualization'],
                    version: 'weekly'
                });

                loader.load().then(() => {
                    fetch(fullConfig.apiUrl + '/v1/ips/whereami')
                        .then(response => response.json())
                        .then(response => {
                            // default to US
                            let center = {
                                lat: 37.09024,
                                lng: -95.712891
                            };

                            if (response.record) {
                                center = response.record.point;
                            }

                            storage.setItem('defaultMapCenter', JSON.stringify(center));

                            let mapElement = document.getElementById('main-map');
                            if (!mapElement) return;

                            let map = new window.google.maps.Map(mapElement, {
                                center: center,
                                minZoom: 3,
                                zoom: 5
                            });

                            map._enableControls = function() {
                                this.setOptions({
                                    zoomControl: true,
                                    zoomControlOptions: {
                                        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                                    },
                                    scaleControl: true,
                                    streetViewControl: true,
                                    streetViewControlOptions: {
                                        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                                    },
                                    fullscreenControl: true,
                                    fullscreenControlOptions: {
                                        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                                    },
                                    mapTypeControl: false
                                })
                            }

                            map._disableControls = function() {
                                this.setOptions({
                                    zoomControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    fullscreenControl: false,
                                    mapTypeControl: false
                                })
                            }

                            map._enableControls();

                            map.streetView.addListener('visible_changed', () => {
                                if (map.streetView.getVisible()) {
                                    this.setState({ streetViewActive: true });
                                }
                                else {
                                    this.setState({ streetViewActive: false });
                                }
                            });

                            this.googleMap.map = map;

                            if (this.googleMap.mapReadyFunc) {
                                this.googleMap.mapReadyFunc(this.googleMap.map, window.google);
                            }
                        })
                        .catch(ex => {
                            this.setState(() => { throw new Error('Could not look up user location: ' + ex.toString()); });
                        });
                });
            })
            .catch(ex => {
                this.setState(() => { throw new Error('Could not GET API root: ' + ex.toString()); });
            });
    }

    googleMap = {
        map: null,
        mapReadyFunc: null
    };

    mapReady = (readyFunc) => {
        if (!readyFunc) return;

        if (this.googleMap.map) {
            readyFunc(this.googleMap.map, window.google);
        }
        else {
            this.googleMap.mapReadyFunc = readyFunc
        }
    }

    userReadyFuncs = [];

    userReady = (readyFunc) => {
        if (!readyFunc) return;

        if (this.state.isAuthStateDetermined) {
            readyFunc(this.state.authUser)
        }
        else {
            this.userReadyFuncs.push(readyFunc);
        }
    }

    pageType = {
        setHalf: () => {
            this.setState({
                rootClassName: 'half-page',
                pageType: 'half'
            });
        },
        setFull: () => {
            this.setState({
                rootClassName: 'full-page',
                pageType: 'full'
            });
        },
        setSearch: () => {
            this.setState({
                rootClassName: 'search-page',
                pageType: 'search'
            });
        }
    }

    getAuthUserFromToken = (authToken) => {
        return new Promise((resolve, reject) => {
            fetch(this.state.config.apiUrl + '/v1/accounts/whoami', {
                headers: { 'Authorization': 'Bearer ' + authToken }
            })
            .then(response => response.json())
            .then(response => {
                if (response.code !== 0) {
                    this.setState(() => { throw new Error('Problem retrieving logged in user. Response code: ' + response.code); });
                    return;
                }

                const authUser = response.record;
                if (authUser) {
                    authUser.token = authToken;
                }

                resolve(authUser);
            })
            .catch(ex => {
                this.setState(() => { throw new Error('Problem retrieving logged in user. Error: ' + ex.toString()); });
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.language.code !== prevState.language.code) {
            console.log('update language');
        }
    }

    setLanguage = (language) => {
        document.documentElement.setAttribute('lang', language.code);

        if (language.isRightToLeft) {
            document.body.classList.add('rtl');
        }
        else {
            document.body.classList.remove('rtl');
        }
    }

    render() {
        if (!this.state.config) return <></>

        let isHome = window.location.pathname === '/';

        return (
            <div className={`app` + (this.state.streetViewActive ? ` street-view-active `: ` `) + this.state.rootClassName} onClick={this.hideMenu}>
                <header className="main-header">
                    <div className="titles">
                        {isHome &&
                            <h1 className="title" aria-label="Spirited Drive">
                                <NavLink exact className="logo-link" to="/">Spirited Drive</NavLink>
                            </h1>
                        }
                        {!isHome &&
                            <p className="title" aria-label="Spirited Drive">
                                <NavLink exact className="logo-link" to="/">Spirited Drive</NavLink>
                            </p>
                        }
                        <p className="sub-title">Find Great Driving Roads</p>
                    </div>

                    <div className="burger-menu">
                        <button type="button" className="menu-toggle" id="main-menu-button" aria-label="Main site navigation"
                            onClick={this.toggleMenu} aria-expanded={this.state.showMenu}>
                            <FontAwesomeIcon icon={faBars}/>
                            <span className="text" aria-hidden="true">Menu</span>
                        </button>

                        <nav className={`menu-list ${this.state.showMenu ? "visible" : "hidden"}`} aria-labelledby="main-menu-button">
                            <ul className="list">
                                <li className="menu-item">
                                    <NavLink exact className="link" to="/"><FontAwesomeIcon className="icon" icon={faHome} fixedWidth/> Home</NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink exact className="link" to="/drives/add"><FontAwesomeIcon className="icon" icon={faPlus} fixedWidth/> Add Your Own Drive</NavLink>
                                </li>

                                {this.state.authUser &&
                                    <li className="menu-item">
                                        <NavLink exact className="link" to={`/profile/${this.state.authUser.username}`}><FontAwesomeIcon className="icon" icon={faUser} fixedWidth/> Profile</NavLink>

                                        <ul className="sub-list">
                                            <li className="menu-item">
                                                <NavLink exact className="link" to={`/profile/${this.state.authUser.username}/drives`}><FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> My Drives</NavLink>
                                            </li>
                                            <li className="menu-item">
                                                <button type="button" className="link link-button logout-button" onClick={this.logout}>
                                                    <FontAwesomeIcon className="icon" icon={faSignOutAlt} fixedWidth/> Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </li>
                                }

                                {!this.state.authUser &&
                                    <li className="menu-item">
                                        <NavLink exact className="link" to="/account/sign-in"><FontAwesomeIcon className="icon" icon={faUser} fixedWidth/> Sign-in</NavLink>

                                        <ul className="sub-list">
                                            <li className="menu-item">
                                                <NavLink exact className="link" to="/account/register"><FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> Register</NavLink>
                                            </li>
                                            <li className="menu-item">
                                                <NavLink exact className="link" to="/account/forgot-password"><FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> Forgot Password</NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                }

                                <li className="menu-item">
                                    <NavLink exact className="link" to="/about"><FontAwesomeIcon className="icon" icon={faQuestionCircle} fixedWidth/> About</NavLink>

                                    <ul className="sub-list">
                                        <li className="menu-item">
                                            <NavLink exact className="link" to="/about/contact">
                                                <FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> Contact
                                            </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink exact className="link" to="/about/privacy">
                                                <FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> Privacy Policy
                                            </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink exact className="link" to="/about/terms">
                                                <FontAwesomeIcon className="icon rtl-flip" icon={faLevelUpAlt} fixedWidth rotation={90}/> Terms &amp; Conditions
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className="menu-item">
                                    <NavLink exact className="link" to="/drives">
                                        <FontAwesomeIcon className="icon" icon={faSitemap} fixedWidth/> Site Map
                                    </NavLink>
                                </li>
                                <li className="menu-item">
                                    <a className="link" target="_blank" rel="noreferrer" aria-label="Visit our YouTube channel"
                                        href={`https://www.youtube.com/channel/`+ this.state.config.social.youTubeChannelId}>
                                        <FontAwesomeIcon className="icon" icon={faYoutube} fixedWidth/> YouTube
                                    </a>
                                </li>
                                <li className="menu-item">
                                    <a className="link" target="_blank" rel="noreferrer" aria-label="Follow us on Twitter"
                                        href={`https://twitter.com/` + this.state.config.social.twitterUsername}>
                                        <FontAwesomeIcon className="icon" icon={faTwitter} fixedWidth/> Twitter
                                    </a>
                                </li>
                                <li className="menu-item">
                                    <a className="link" target="_blank" rel="noreferrer" aria-label="Follow us on Instagram"
                                        href={`https://www.instagram.com/` + this.state.config.social.instagramId}>
                                        <FontAwesomeIcon className="icon" icon={faInstagram} fixedWidth/> Instagram
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="welcome-login">
                        {this.state.authUser &&
                            <div className="logged-in">
                                <NavLink exact className="hello-link" to={`/profile/${this.state.authUser.username}`}
                                    aria-label={`Visit the profile page for ${this.state.authUser.fullname}`}>
                                    <FontAwesomeIcon className="icon" icon={faUserCircle} fixedWidth/>
                                    <span className="text">{this.state.authUser.username}</span>
                                </NavLink>

                                <button type="button" className="link link-button logout-button" onClick={this.logout}
                                    aria-label="Logout of your account">
                                    <FontAwesomeIcon className="icon" icon={faSignOutAlt} fixedWidth/>
                                    <span className="text">Logout</span>
                                </button>
                            </div>
                        }

                        {!this.state.authUser &&
                            <NavLink exact to="/account/sign-in" className="sign-in">
                                <FontAwesomeIcon className="icon" icon={faSignInAlt}/>
                                <span className="text">Sign in</span>
                            </NavLink>
                        }
                    </div>

                    <NavLink exact className="add-drive button cta" to="/drives/add">
                        <FontAwesomeIcon className="icon" icon={faPlusCircle} fixedWidth/>
                        <span className="text">Add<span className="superfluous"> Your Own</span> Drive</span>
                    </NavLink>
                </header>

                <main className="main-center">
                    <div className="inner">
                        <AppContext.Provider value={{
                            config: this.state.config,
                            mapReady: this.mapReady,
                            userReady: this.userReady,
                            pageType: this.pageType,
                            language: this.state.language
                        }}>
                            <Switch>
                                {/* Home */}
                                <Route path="/" exact>
                                    <Home/>
                                </Route>

                                {/* Accounts */}
                                <Route path="/account/sign-in">
                                    <AccountSignin handleSignin={this.signin}/>
                                </Route>
                                <Route path="/account/register">
                                    <AccountRegister/>
                                </Route>
                                <Route path="/account/forgot-password">
                                    <AccountForgotPassword/>
                                </Route>
                                <Route path="/account/reset-password">
                                    <AccountResetPassword/>
                                </Route>
                                <Route path="/account/activate">
                                    <AccountActivate/>
                                </Route>

                                {/* Profile */}
                                <Route path="/profile/:username/drives">
                                    <ProfileDrives/>
                                </Route>
                                <Route path="/profile/:username?" exact>
                                    <Profile/>
                                </Route>

                                {/* Drives */}
                                <Route path="/drives" exact>
                                    <Drives/>
                                </Route>
                                <Route path="/drives/add">
                                    <DrivesAdd/>
                                </Route>
                                <Route path="/drives/confirm">
                                    <DrivesConfirm/>
                                </Route>
                                <Route path="/drives/:id?" exact>
                                    <Drive/>
                                </Route>

                                {/* About */}
                                <Route path="/about" exact>
                                    <About/>
                                </Route>
                                <Route path="/about/contact">
                                    <AboutContact/>
                                </Route>
                                <Route path="/about/privacy">
                                    <AboutPrivacy/>
                                </Route>
                                <Route path="/about/terms">
                                    <AboutTerms/>
                                </Route>

                                {/* 404 */}
                                <Route>
                                    <NotFound/>
                                </Route>
                            </Switch>
                        </AppContext.Provider>

                        <div className="map-container">
                            <div id="main-map" aria-hidden={this.state.pageType === 'full'}></div>
                        </div>
                    </div>
                </main>

                <footer className="main-footer">
                    <nav className="social-links" aria-label="Social media links">
                        <ul className="row-list">
                            <li className="item">
                                <a className="link youtube" target="_blank" rel="noreferrer" aria-label="Visit our YouTube channel"
                                    href={`https://www.youtube.com/channel/`+ this.state.config.social.youTubeChannelId}>
                                    <FontAwesomeIcon className="icon" icon={faYoutube}/>
                                    <span className="text">YouTube</span>
                                </a>
                            </li>
                            <li className="item">
                                <a className="link twitter" target="_blank" rel="noreferrer" aria-label="Follow us on Twitter"
                                    href={`https://twitter.com/` + this.state.config.social.twitterUsername}>
                                    <FontAwesomeIcon className="icon" icon={faTwitter}/>
                                    <span className="text">Twitter</span>
                                </a>
                            </li>
                            <li className="item">
                                <a className="link instagram" target="_blank" rel="noreferrer" aria-label="Follow us on Instagram"
                                    href={`https://www.instagram.com/` + this.state.config.social.instagramId}>
                                    <FontAwesomeIcon className="icon" icon={faInstagram}/>
                                    <span className="text">Instagram</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <nav className="about-links" aria-label="Legal/About/Contact Us links">
                        <ul className="row-list">
                            <li className="item">
                                <NavLink exact className="link" to="/about">About</NavLink>
                            </li>
                            <li className="item">
                                <NavLink exact className="link" to="/about/contact">Contact</NavLink>
                            </li>
                            <li className="item">
                                <NavLink exact className="link" to="/about/privacy">Privacy</NavLink>
                            </li>
                            <li className="item">
                                <NavLink exact className="link" to="/about/terms">Terms</NavLink>
                            </li>
                            <li className="item">
                                <NavLink exact className="link" to="/drives" aria-label="Site Map" title="Site Map">
                                    <FontAwesomeIcon className="icon" icon={faSitemap} fixedWidth/>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </footer>

                <div id="root-modal"></div>
            </div>
        );
    }
}

export default withRouter(App);