import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCameraRetro, faEdit, faPlus, faTrashAlt, faSync } from '@fortawesome/pro-solid-svg-icons'

import validation from '../../utils/validation'
import images from '../../utils/images'

import ModalPortal from './ModalPortal'

import './DriveImage.scss'

class DriveImage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            imageId: props.image ? props.image.id : null,
            imageUrl: props.image ? props.image.sizes.small : null,
            maxImageAllowance: props.maxImageAllowance || -1,
            totalUserImages: props.totalUserImages || 0,
            form: {
                caption: {
                    val: props.image ? (props.image.caption || '') : '',
                    err: null
                },
                source: {
                    val: props.image ? (props.image.source || '') : '',
                    err: null
                },
                sourceUrl: {
                    val: props.image ? (props.image.sourceUrl || '') : '',
                    err: null
                }
            },
            loading: false,
            saving: false,
            deleting: false,
            isSubmitted: false,
        };
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    uploadImage = (event) => {
        let file = event.target.files[0];
        if (!file) return;

        if (this.state.loading) return;
        this.setState({ loading: true });

        let imageUrl = null;

        images.resize(file)
            .then(dataUrl => imageUrl = dataUrl)
            .catch(() => imageUrl = null)
            .finally(() => {
                this.setState({
                    imageUrl: imageUrl,
                    loading: false
                });
        
                event.target.value = '';
            });
    }

    addImage = () => {
        document.getElementById('new-image-upload-label').click();
    }

    handleSave = (event) => {
        if (!this.props.handleSave) return;
        if (!this.state.imageUrl) return;
        if (this.state.saving || this.state.deleting) return;

        event.preventDefault();
        this.setState({ isSubmitted: true  });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        this.setState({ saving: true });

        this.props.handleSave({
            id: this.state.imageId,
            url: this.state.imageUrl,
            caption: this.state.form.caption.val,
            source: this.state.form.source.val,
            sourceUrl: this.state.form.sourceUrl.val,
        }, () => {
            this.setState({ saving: false });
        });
    }

    handleDelete = () => {
        if (!this.props.handleDelete) return;

        if (this.state.deleting || this.state.saving) return;
        if (!window.confirm('Are you sure you want to delete this photo?')) return;
        
        this.setState({ deleting: true });

        this.props.handleDelete(this.state.imageId, () => {
            this.setState({ deleting: false });
        });
    }

    close = () => {
        if (this.state.deleting || this.state.saving) return;

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        let exceededMaxAllowedImages = !this.state.imageId &&
            this.state.maxImageAllowance !== -1 &&
            this.state.totalUserImages >= this.state.maxImageAllowance; 

        return (
            <ModalPortal className="standard-modal drive-images-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faCameraRetro}/>
                        <span className="text">{this.state.imageId ? 'Update' : 'Add'} Your Photo</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="main">
                    <div className="media-form-items">
                        <div className="fading-scroller">
                            {exceededMaxAllowedImages &&
                                <p>A maximum of <strong>{this.state.maxImageAllowance}</strong> images are allowed per Drive per user.</p>
                            }

                            {!exceededMaxAllowedImages &&
                                <form id="image-upload-form" onSubmit={this.handleSave} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                                    <article className="media-item">
                                        <figure className="media">
                                            {!this.state.imageUrl &&
                                                <div className="file-upload">
                                                    <input id="new-image-upload" type="file" className="file" onChange={(e) => this.uploadImage(e)}
                                                        accept="image/png, image/jpeg, image/heic"/>
                                                    <label htmlFor="new-image-upload" id="new-image-upload-label" className="button upload-image" aria-label="Upload a new image">
                                                        <FontAwesomeIcon className="icon" icon={this.state.loading ? faSync : faPlus} spin={this.state.loading}/>
                                                    </label>
                                                </div>
                                            }

                                            {this.state.imageUrl &&
                                                <img className="image simple-image" src={this.state.imageUrl} alt="" />
                                            }
                                        </figure>
                                        <div className="details">
                                            <div className="form-row">
                                                <label htmlFor={`caption_new`} className="label">
                                                    Caption <span className="optional-label">(optional)</span>
                                                </label>
                                                <div className="control">
                                                    <textarea id={`caption_new`} name={`caption`} maxLength="140"
                                                        value={this.state.form.caption.val} onChange={this.handleInputChange}></textarea>
                                                </div>
                                            </div>

                                            <fieldset className="image-source-form">
                                                <legend>Author/Attribution/Source <span className="optional-label">(optional)</span></legend>
                                            
                                                <div className="form-row">
                                                    <div className="control">
                                                        <input type="text" placeholder="Name" className="textbox" name={`source`} maxLength="50"
                                                            value={this.state.form.source.val} onChange={this.handleInputChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="control">
                                                        <input type="url" placeholder="URL" className="textbox" name={`sourceUrl`} maxLength="500"
                                                            value={this.state.form.sourceUrl.val} onChange={this.handleInputChange}/>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </article>
                                </form>
                            }

                        </div>
                    </div>

                    {exceededMaxAllowedImages &&
                        <div className="form-row buttons footer-controls">
                            <button type="button" className="button primary-button" onClick={this.close}>
                                <FontAwesomeIcon className="icon" icon={faTimesCircle}/> Close
                            </button>
                        </div>
                    }

                    {!exceededMaxAllowedImages &&
                        <div className="form-row buttons footer-controls">
                            <button type="submit" form="image-upload-form" className="button primary-button">
                                <FontAwesomeIcon className="icon" icon={this.state.saving ? faSync : faEdit} spin={this.state.saving} fixedWidth={true}/>
                                <span className="text">Save &amp; Close</span>
                            </button>
                            <button type="button" className="link-button cancel-button" onClick={this.close}>Nevermind</button>

                            {this.state.imageId &&
                                <>
                                    <span className="flex-gap"></span>
                                    <button type="button" className="button warn delete-button" onClick={this.handleDelete}>
                                        <FontAwesomeIcon className="icon" icon={this.state.deleting ? faSync : faTrashAlt} spin={this.state.deleting}/>
                                        <span className="text">Remove<span className="superfluous"> My Photo</span></span>
                                    </button>
                                </>
                            }
                        </div>
                    }
                </div>
            </ModalPortal>
        );
    }
}

export default DriveImage