import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheckCircle, faSyncAlt, faUserCircle } from '@fortawesome/pro-solid-svg-icons'

import PasswordField from '../../components/PasswordField'

import validation from '../../utils/validation'
import format from '../../utils/format';

import './styles.scss'

class AccountRegister extends React.Component {
    constructor(props) {
        super(props);

        let qs = {}; this.props.location.search.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });
        let redirectUrl = (qs.redirectUrl || '').trim();
        redirectUrl = redirectUrl.startsWith('/') ? redirectUrl : '';

        this.state = {
            isSubmitted: false,
            redirectUrl: redirectUrl,
            showActivationMessage: false,
            autoUsername: true,
            registering: false,
            form: {
                username: {
                    val: '',
                    err: null
                },
                fullname: {
                    val: '',
                    err: null
                },
                email: {
                    val: '',
                    err: null
                },
                password: {
                    val: '',
                    err: null
                },
                confirmPassword: {
                    val: '',
                    err: null
                }
            }
        };

        document.title = 'Register - Spirited Drive';
        let metaDescription = 'Register with Spirited Drive, a crowdsourced online database of the worlds best driving roads. ' +
            'As a registered user you can upload your own drives, leave reviews, upload photos, and "like" your favourite roads.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    handleInputChange = (event) => {
        let form = { ...this.state.form };

        if (event.target.name === 'username') {
            event.target.value = format.sanitiseUsername(event.target.value, event.target.maxLength);

            this.setState({
                autoUsername: form.username.val.length === 0,
                form: validation.handleInputChange(event.target, form)
            });

            return;
        }

        form = validation.handleInputChange(event.target, form);

        if (event.target.name === 'fullname' && this.state.autoUsername) {
            let usernameTarget = event.target.form.elements.username;
            usernameTarget.value = format.sanitiseUsername(event.target.value, usernameTarget.maxLength);

            form = validation.handleInputChange(usernameTarget, form)
        }

        this.setState({ form: form });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.registering) return;

        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        this.setState({ registering: true });

        let url = this.props.config.apiUrl + '/v1/accounts/';

        let data = {
            username: this.state.form.username.val,
            fullname: this.state.form.fullname.val,
            email: this.state.form.email.val,
            password: this.state.form.password.val,
            redirectUrl: this.state.redirectUrl
        };

        this.props.fetcher.post(url, data).then(response => {
            if (response.code === 0) {
                this.props.trackEvent('AccountCreated');
                this.setState({ showActivationMessage: true });
                return;
            }

            let formNode = document.querySelector('.form');
            let form = { ...this.state.form }

            if (response.subCode === 3) {
                form.username.err = 'Username already exists.';
                formNode.elements.username.setCustomValidity(form.username.err);

                form.email.err = 'Email already exists.';
                formNode.elements.email.setCustomValidity(form.email.err);
            }
            else {
                let message = '';
                let formName = null;

                switch (response.subCode) {
                    case 1:
                        message = 'Username already exists.';
                        formName = 'username'
                        break;
                    case 2:
                        message = 'Email already exists.';
                        formName = 'email';
                        break;
                    default:
                        message = 'There was an unknown error, sorry.';
                        formName = 'username';
                        break;
                }

                form[formName].err = message;
                formNode.elements[formName].setCustomValidity(message);
            }
            
            this.setState({
                form: form,
                registering: false
            })
        });
    }

    getSigninUrl = () => {
        return '/account/sign-in' + (this.state.redirectUrl ? '?redirectUrl=' + encodeURIComponent(this.state.redirectUrl) : '');
    }

    render() {
        return (
            <div className="main-page-full register-page narrow-form-page">
                <header className="header">
                    <h1 className="title">Become A Spirited Driver</h1>
                    <p className="intro">
                        Register to upload your own spirited drives,<br/>
                        or favourite existing drives on this site.
                    </p>
                </header>

                <section className="inner register">
                    <fieldset className="form">

                        {!this.state.showActivationMessage &&
                            <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                                <div className="form-row">
                                    <label htmlFor="fullname" className="label">Your Name</label>
                                    <div className="control">
                                        <input id="fullname" name="fullname" type="text" className="textbox" required minLength="2" maxLength="50"
                                            value={this.state.form.fullname.val} onChange={this.handleInputChange}/>

                                        {this.state.form.fullname.err && this.state.isSubmitted &&
                                            <p className="validation-error">
                                                <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.fullname.err}
                                            </p>
                                        }
                                    </div>
                                </div>

                                <div className="form-row inline username-row">
                                    <div className="label-control">
                                        <label htmlFor="username" className="label">spiriteddrive.club/profile/</label>
                                        <div className="control">
                                            <input id="username" name="username" type="text" className="textbox"
                                                required pattern="[a-z0-9_-]+" minLength="2" maxLength="20" placeholder="username"
                                                value={this.state.form.username.val} onChange={this.handleInputChange}/>

                                            {this.state.form.username.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.username.err}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <label htmlFor="email" className="label">Email</label>
                                    <div className="control">
                                        <input id="email" name="email" type="email" className="textbox" required
                                            value={this.state.form.email.val} onChange={this.handleInputChange}/>

                                        {this.state.form.email.err && this.state.isSubmitted &&
                                            <p className="validation-error">
                                                <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.email.err}
                                            </p>
                                        }
                                    </div>
                                </div>

                                <PasswordField className="form-row" id="password" value={this.state.form.password.val} required={true}
                                    onChange={this.handleInputChange} error={this.state.isSubmitted && this.state.form.password.err} />

                                <div className="form-row buttons centered">
                                    <button type="submit" className="button cta chunky register-button">
                                        <FontAwesomeIcon className="icon" fixedWidth spin={this.state.registering}
                                            icon={this.state.registering ? faSyncAlt : faUserCircle}/>
                                        <span className="text">Register</span>
                                    </button>
                                </div>
                            </form>
                        }

                        {this.state.showActivationMessage &&
                            <div className="register-success">
                                <FontAwesomeIcon className="icon" icon={faCheckCircle}/>

                                <p className="thank-you">Thank you!</p>
                                <p>We have sent you an email to activate your account.</p>
                                <p>Just click on the link in the e-mail and you'll be on the road in no time.</p>
                            </div>
                        }
                    </fieldset>

                    <aside className="form-aside">
                        <p>
                            Already have an account? <NavLink to={this.getSigninUrl()}>Sign-in</NavLink>
                        </p>
                    </aside>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AccountRegister);