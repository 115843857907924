const functions = {
    extractVideoId: function(url) {
        let match = (url || '').trim().match(/^https:\/\/(youtu\.be\/|www\.youtube\.com\/watch\?v=)(.+)$/i);
        if (!match) return null;

        return match[2];
    },
    getVideoInfo: function(videoId, googleApiKey) {
        if (!videoId) return null;

        if (!googleApiKey) {
            throw new Error('Google API Key is missing.');
        }

        let youTubeUrl = 'https://youtube.googleapis.com/youtube/v3/videos?' +
            'part=' + encodeURIComponent('statistics,status,snippet') + '&' +
            'id=' + encodeURIComponent(videoId) + '&' +
            'key=' + encodeURIComponent(googleApiKey);

        return fetch(youTubeUrl)
            .then(response => response.json())
            .then(response => {
                let videoItem = response.items[0];

                if (!videoItem) {
                    return null;
                }

                return {
                    youTubeId: videoItem.id,
                    title: videoItem.snippet.title,
                    channelId: videoItem.snippet.channelId,
                    channelTitle: videoItem.snippet.channelTitle,
                    views: videoItem.statistics.viewCount,
                    privacyStatus: videoItem.status.privacyStatus,
                    embeddable: videoItem.status.embeddable
                };
            });
    }
}

export default functions;