import React from 'react'
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faSync } from '@fortawesome/pro-solid-svg-icons'

import basePageWrapper from './../BasePage'
import format from '../../utils/format';

import './index.scss'

class Sitemap extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'Full Drives List (Site Map) - Spirited Drive';

        let metaDescription = 'See a full list of all driving roads on Spirited Drive, organised by country.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    state = {
        countries: [],
        paging: {
            page: 1,
            recordsPerPage: 100,
            totalRecords: 0,
            pages: 1,
            startRecord: 0,
            endRecord: 0
        },
        isInitialLoading: true,
        isLoading: false
    }

    componentDidMount() {
        this.loadDrives();
    }

    componentDidUpdate() {
        let newPage = parseInt(format.querystring(this.props.location.search).page || 1);
        newPage = isNaN(newPage) ? 1 : newPage;

        if (newPage !== this.state.paging.page) {
            this.setState({
                paging: {
                    ...this.state.paging,
                    page: newPage
                }
            }, () => {
                this.loadDrives();
            })
        }
    }

    loadDrives() {
        this.setState({ isLoading: true });

        let url = this.props.config.apiUrl + `/v1/drives/?page=${this.state.paging.page}&recordsPerPage=${this.state.paging.recordsPerPage}&orderBy=country`;
        this.props.fetcher.get(url)
            .then(response => {
                let countries = [];

                response.records.forEach(drive => {
                    let country = countries.find(c => c.id === drive.country.id);
                    if (!country) {
                        country = {
                            id: drive.country.id,
                            code: drive.country.code,
                            name: drive.country.name,
                            drives: []
                        }

                        countries.push(country);
                    }

                    country.drives.push({
                        id: drive.id,
                        name: drive.name,
                        slug: drive.slug
                    });
                });

                let totalRecords = response.paging.totalRecords;
                let recordsPerPage = this.state.paging.recordsPerPage;
                let numPages = Math.ceil(totalRecords / recordsPerPage);

                let page = this.state.paging.page;
                let startRecord = ((page - 1) * recordsPerPage) + 1;
                let endRecord = (startRecord + recordsPerPage) - 1;
                endRecord = endRecord > totalRecords ? totalRecords : endRecord;

                this.setState({
                    countries: countries,
                    paging: {
                        ...this.state.paging,
                        totalRecords: response.paging.totalRecords,
                        pages: numPages,
                        startRecord: startRecord,
                        endRecord: endRecord
                    },
                    isInitialLoading: false,
                    isLoading: false
                });
            });
    }

    render() {
        return (
            <div className="main-page-full drives-list-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/drives" aria-current="location" className="link">Drives</NavLink></li>
                    </ol>
                </nav>

                <section className="sitemap-drives">
                    <h1 className="title">
                        <FontAwesomeIcon className="icon" icon={faSitemap}/>
                        <span className="text">Full Drives List (Site Map)</span>
                    </h1>

                    {!this.state.isInitialLoading &&
                        <nav className="paging-controls">
                            <p className="record-count">{this.state.paging.startRecord} - {this.state.paging.endRecord} of {this.state.paging.totalRecords}</p>

                            <ol className="paging">
                                {[...Array(this.state.paging.pages)].map((_, i) => 
                                    <li className={`page-item` + ((i + 1) === this.state.paging.page ? ` current` : ``)} key={i}>
                                        <NavLink className="page-link" to={`?page=${i + 1}`}>{i + 1}</NavLink>
                                    </li>
                                )}
                            </ol>
                        </nav>
                    }

                    {this.state.isLoading &&
                        <div className="loading-detail-page">
                            <p className="loading-message" aria-label="Loading drive, please wait...">
                                <FontAwesomeIcon className="icon" icon={faSync} spin={true}/>
                            </p>
                        </div>
                    }

                    {!this.state.isLoading && this.state.countries.map(country => 
                        <article className="country-item" key={country.id}>
                            <h2 className="title">{country.name}</h2>

                            <ul className="drives-list">
                                {country.drives.map(drive => 
                                    <li className="drive-item" key={drive.id}>
                                        <NavLink className="drive-link" to={`/drives/${drive.slug}`}>{drive.name}</NavLink>
                                    </li>    
                                )}
                            </ul>
                        </article>
                    )}

                    {!this.state.isInitialLoading &&
                        <nav className="paging-controls">
                            <p className="record-count">{this.state.paging.startRecord} - {this.state.paging.endRecord} of {this.state.paging.totalRecords}</p>

                            <ol className="paging">
                                {[...Array(this.state.paging.pages)].map((_, i) => 
                                    <li className={`page-item` + ((i + 1) === this.state.paging.page ? ` current` : ``)} key={i}>
                                        <NavLink className="page-link" to={`?page=${i + 1}`}>{i + 1}</NavLink>
                                    </li>
                                )}
                            </ol>
                        </nav>
                    }
                </section>
                
            </div>
        )
    }
}

export default basePageWrapper(Sitemap)