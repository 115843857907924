import React from 'react'
import basePageWrapper from './BasePage'

import { NavLink } from 'react-router-dom'

class NotFound extends React.Component {
    constructor(props) {
        super(props)

        document.title = '404: Page Not Found - Spirited Drive';
        document.querySelector('head > meta[name="description"]').setAttribute('content', '404: Page Not Found ');

        this.props.pageType.setFull();
        this.props.pageType.set404();
    }

    render() {
        return (
            <div className="main-page-full">
                <div className="not-found-content">
                    <h2 className="title">404: Page Not Found</h2>
            
                    <p>
                        Sorry, but we couldn't locate this page. If you came from another
                        site, they might need to update their links.
                    </p>

                    <p>
                        In the meantime try <NavLink to="/">navigating to the home page</NavLink>,
                        and searching for exciting driving roads near where you live.
                    </p>

                    <p>Thank you!</p>
                </div>
            </div>
        )
    }
}

export default basePageWrapper(NotFound);