import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faExclamationCircle, faSync, faMessageQuote } from '@fortawesome/pro-solid-svg-icons'
import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'

import validation from '../../utils/validation'

import Stars from '../Stars';
import ModalPortal from './ModalPortal'

class Review extends React.Component {
    constructor(props) {
        super(props)

        let review = props.review;

        this.state = {
            review: review,
            form: {
                rating: {
                    val: review ? (review.rating || 0) / 2 : 0,
                    err: null
                },
                text: {
                    val: review ? review.text || '' : '',
                    err: null
                }
            },
            isSubmitted: false,
            isSaving: false,
            isDeleting: false
        }
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleRating = (rating) => {
        let form = { ...this.state.form };
        form.rating.val = rating;

        this.setState({ form: form });
    }

    handleSubmit = (event) => {
        if (!this.props.handleSave) return;

        event.preventDefault();

        if (this.state.isSaving || this.state.isDeleting) return;
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.form.rating.val === 0) {
            this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'rating', 'A rating is required') });
            return;
        }

        this.setState({ isSaving: true });

        this.props.handleSave({
            id: this.state.review ? this.state.review.id : null,
            text: this.state.form.text.val,
            rating: this.state.form.rating.val * 2
        });
    }

    removeReview = () => {
        if (this.state.isDeleting || this.state.isSaving) return;
        if (this.state.form.text.val && !window.confirm('Are you sure?')) return;

        this.setState({ isDeleting: true });

        if (this.props.handleDelete) {
            this.props.handleDelete(this.state.review);
        }
    }

    close = () => {
        if (this.state.isDeleting || this.state.isSaving) return;

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        const rating = this.state.form.rating.val;

        return (
            <ModalPortal className="standard-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faMessageQuote}/>
                        <span className="text">{this.state.review ? 'Update' : 'Add'} Your Review</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="main">
                    <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                        <div className="form-row">
                            <label htmlFor="reviewText" className="label">Review <span className="optional-label">(optional)</span></label>
                            <div className="control">
                                <textarea name="text" id="reviewText" rows="7" value={this.state.form.text.val} onChange={this.handleInputChange}></textarea>
                            </div>
                        </div>

                        <div className="form-row star-row">
                            <label className="label">Your Rating:</label>
                            <div className="control">
                                <Stars className="star-rating" rating={rating} onChange={this.handleRating}/>

                                {this.state.form.rating.err && this.state.isSubmitted &&
                                    <p className="validation-error">
                                        <FontAwesomeIcon className="icon" icon={faExclamationCircle}/> {this.state.form.rating.err}
                                    </p>
                                }
                            </div>
                        </div>

                        <div className="form-row buttons">
                            <button type="submit" className="button primary-button">
                                <FontAwesomeIcon className="icon" icon={this.state.isSaving ? faSync : faEdit} spin={this.state.isSaving} fixedWidth={true}/>
                                <span className="text">Save &amp; Close</span>
                            </button>
                            <button type="button" className="link-button cancel-button" onClick={this.close}>Nevermind</button>

                            {this.state.review &&
                                <>
                                    <span className="flex-gap"></span>
                                    <button type="button" className="button warn delete-button" onClick={this.removeReview}>
                                        <FontAwesomeIcon className="icon" icon={this.state.isDeleting ? faSync : faTrashAlt} spin={this.state.isDeleting} fixedWidth={true}/>
                                        <span className="text">Remove<span className="superfluous"> My Review</span></span>
                                    </button>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </ModalPortal>
        )
    }
}

export default Review