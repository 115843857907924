import React from 'react'

const AppContext = React.createContext({
    config: null,
    mapReady: null,
    userReady: null,
    pageType: null,
    language: {
        code: 'en-gb',
        name: 'English (US)',
        isRightToLeft: false
    }
});

export default AppContext;