import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faQuoteLeft, faUserCircle, faHeart as fullHeart, faSync } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as emptyHeart } from '@fortawesome/pro-regular-svg-icons'

import geo from '../../utils/geo';
import format from '../../utils/format';

import Stars from '../../components/Stars';
import ReviewModal from '../../components/modals/Review'
import DriveInfo from '../../components/DriveInfo';

import './drives.scss'

class ProfileDrives extends React.Component {
    static recordsPerPage = 100;

    constructor(props) {
        super(props)

        document.title = 'User Info - Spirited Drive';
        document.querySelector('head > meta[name="description"]').setAttribute('content', 'User profile page');
        
        this.props.pageType.setHalf();
    }

    authUserDetermined = false;
    google = null;
    map = null;

    drivePolyline = null;
    startMarker = null;
    endMarker = null;

    state = {
        isLoading: true,
        authUser: null,
        user: null,
        drives: {
            page: 1,
            totalRecords: 0,
            records: []
        },
        editReview: {
            visible: false,
            review: null
        }
    }

    componentDidMount() {
        this.props.mapReady((map, google) => {
            this.map = map;
            this.google = google;

            this.map._enableControls();
            this.map.streetView.setVisible(false);

            this.loadPage();
        });

        this.props.authUserStateReady(authUser => {
            this.authUserDetermined = true;
            this.setState({ authUser: authUser });

            this.loadPage();
        });
    }

    loadPage = () => {
        if (!this.authUserDetermined || !this.map) return;

        this.fetchUser()
            .then(user => {
                if (!user) {
                    // 404 not found
                    let username = this.props.match.params.username;
                    this.props.history.replace('/profile/' + encodeURIComponent(username));
                    return null;
                }

                this.setState({
                    user: user
                });

                if (user) {
                    document.title = user.fullname + ': Drives - Spirited Drive';

                    let metaDescription = 'List of drives favourited, uploaded, and/or reviewed by ' + user.fullname;
                    document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);
                }

                const page = this.state.drives.page;
                return this.fetchDrives(user.id, page);
            })
            .then(drives => {
                if (!drives) return;
                
                this.setState({
                    isLoading: false,
                    drives: {
                        page: 1,
                        totalRecords: drives.totalRecords,
                        records: drives.records
                    }
                });
            });
            
    }

    fetchUser = () => {
        let username = this.props.match.params.username;
        let url = this.props.config.apiUrl + '/v1/users/' + username;

        return this.props.fetcher.get(url).then(response => {
            if (response.code !== 0 && response.code !== 44) {
                // TODO: handle error
                console.error(response.code + ': ' + response.message);
                return;
            }

            return response.record;
        });
    }

    fetchDrives = (userID, page) => {
        let url = this.props.config.apiUrl + '/v1/users/' + userID + '/drives?page=' + page + '&recordsPerPage=' + ProfileDrives.recordsPerPage;

        return this.props.fetcher.get(url).then(response => {
            if (response.code !== 0) {
                // TODO: handle error
                console.error(response.code + ': ' + response.message);
                return;
            }

            response.records.forEach(drive => {
                // figure out titles
                drive.mainTitle = drive.name;
                drive.subTitle = null;

                if (drive.favourite && drive.favourite.name && drive.favourite.name !== drive.name) {
                    drive.mainTitle = drive.favourite.name;
                    drive.subTitle = drive.name;
                }
            });

            return {
                records: response.records,
                totalRecords: response.paging.totalRecords
            };
        });
    }

    showDriveOnMap = (drive) => {
        if (this.drivePolyline) this.drivePolyline.setMap(null);
        if (this.startMarker) this.startMarker.setMap(null);
        if (this.endMarker) this.endMarker.setMap(null);

        let currentSelected = this.state.drives.records.find(d => d.selected);
        if (currentSelected) currentSelected.selected = false;
        drive.selected = true;

        this.setState({
            drives: {
                ...this.state.drives,
                records: this.state.drives.records
            }
        });

        this.drivePolyline = new this.google.maps.Polyline({
            path: this.google.maps.geometry.encoding.decodePath(drive.encodedPolyline),
            clickable: false,
            geodesic: true,
            strokeColor: "#0080ff",
            strokeOpacity: 0.7,
            strokeWeight: 6
        });
        this.drivePolyline.setMap(this.map);

        let expandedBounds = window.innerWidth <= 700 ? drive.bounds : geo.getBoundsForSplitView(drive.bounds, { side: 'left' });
        let bounds = new this.google.maps.LatLngBounds(expandedBounds.sw, expandedBounds.ne);
        this.map.fitBounds(bounds);

        let start = drive.start;
        let end = drive.end;

        this.startMarker = new this.google.maps.Marker({
            map: this.map,
            position: {
                lat: start.lat,
                lng: start.lng
            },
            label: {
                text: drive.isLoop ? 'O' : 'A',
                color: '#ffffff'
            }
        });

        if (!drive.isLoop) {
            this.endMarker = new this.google.maps.Marker({
                map: this.map,
                position: {
                    lat: end.lat,
                    lng: end.lng
                },
                label: {
                    text: 'B',
                    color: '#ffffff'
                }
            });
        }
    }

    toggleReviewModal = (drive) => {
        // hide modal
        if (this.state.editReview.visible) {
            this.setState({ editReview: { visible: false } });
            return;
        }

        if (!drive.review) return;

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(drive.id) + '/reviews/' + encodeURIComponent(drive.review.id);

        this.props.fetcher.get(url).then(response => {
            this.setState({
                editReview: {
                    visible: true,
                    review: response.record,
                    drive: drive
                }
            });
        });
    }

    saveReview = (review) => {
        let drive = this.state.editReview.drive;
        let reviewId = this.state.editReview.review.id;

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(drive.id) + '/reviews/' + encodeURIComponent(reviewId);
        let data = {
            text: review.text,
            rating: review.rating
        };

        this.props.fetcher.put(url, data).then(response => {
            drive.review.rating = review.rating;
            drive.review.text = review.text;

            this.setState({ 
                drives: { ...this.state.drives },
                editReview: {
                    visible: false,
                    review: null,
                    drive: null
                }
            });
        });
    }

    deleteReview = (review) => {
        let drive = this.state.editReview.drive;
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(drive.id) + '/reviews/' + encodeURIComponent(review.id);

        this.props.fetcher.delete(url).then(response => {
            drive.review = null;

            this.setState({ 
                drives: {
                    ...this.state.drives
                },
                editReview: {
                    visible: false,
                    review: null,
                    drive: null
                }
            });
        });
    }

    toggleFavourite = (drive) => {
        if (drive._updatingFavourite) return;
        if (drive.favourite && !window.confirm('Remove this favourite. Are you sure?')) return;

        drive._updatingFavourite = true;
        this.setState({ drives: { ...this.state.drives } });

        const favUrl = this.props.config.apiUrl + '/v1/users/' + this.state.user.id + '/favourites/';

        if (drive.favourite) {
            this.props.fetcher.delete(favUrl + drive.favourite.id).then(response => {
                if (response.code !== 0) {
                    // TODO: handle error
                    console.error(response.code + ': ' + response.message);
                    return;
                }

                drive.favourite = null;
                drive.subTitle = null;
                drive.mainTitle = drive.name;

                drive._updatingFavourite = false;
                this.setState({ drives: { ...this.state.drives } });
            });
        }
        else {
            let data = {
                name: null,
                driveId: drive.id,
                isPrivate: false 
            };

            this.props.fetcher.post(favUrl, data).then(response => {
                if (response.code !== 0) {
                    // TODO: handle error
                    console.error(response.code + ': ' + response.message);
                    return;
                }

                drive.favourite = {
                    id: response.id,
                    name: null,
                    created: new Date().toString(),
                    isPrivate: false 
                };
                drive._updatingFavourite = false;
                this.setState({ drives: { ...this.state.drives } });
            })
        }
    }

    componentWillUnmount() {
        if (this.drivePolyline) this.drivePolyline.setMap(null);
        if (this.startMarker) this.startMarker.setMap(null);
        if (this.endMarker) this.endMarker.setMap(null);
    }

    render() {
        return (<>
            <div className="main-page-half my-drives-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to={`/profile/${this.props.match.params.username}`} className="link">Profile</NavLink></li>
                        <li className="item"><NavLink exact to={`/profile/${this.props.match.params.username}/drives`} aria-current="location" className="link">Drives</NavLink></li>
                    </ol>
                </nav>

                {this.state.isLoading &&
                    <div className="loading-detail-page">
                        <p className="loading-message" aria-label="Loading user drives, please wait...">
                            <FontAwesomeIcon className="icon" icon={faSync} spin={true}/>
                        </p>
                    </div>
                }

                {this.state.user && !this.state.isLoading &&
                    <section className="my-drives-section">
                        <header className="my-drives-header">
                            <div className="info">
                                <h1 className="title">{this.state.user.fullname}</h1>
                                <p className="username">@{this.state.user.username}</p>
                                <p className="joined">
                                    <time className="datetime" dateTime={this.state.user.created}>
                                        Joined: {format.date(this.state.user.created)}
                                    </time>
                                </p>
                            </div>
                            <figure className="avatar">
                                {this.state.user.avatarImages && 
                                    <img className="image framed-image" alt={this.state.user.username + ` avatar`} src={this.state.user.avatarImages.small}
                                        onError={(e) => this.handleImageError(e, 'e3aeb538-85e4-11eb-b30e-87b621d3fe33-medium.jpeg')}/>
                                }
                                {!this.state.user.avatarImages && 
                                    <FontAwesomeIcon className="framed-image no-image-icon" icon={faUserCircle}/>
                                }
                            </figure>
                        </header>

                        <div className="users-drives-list">
                            {this.state.drives.records.map(drive =>
                                <article className={`users-drive` + (drive.selected ? ` selected` : ``)} key={drive.id}>
                                    <h2 className="main-title"><NavLink to={`/drives/${drive.slug}`} className="link">{drive.name}</NavLink></h2>

                                    <p className="drive-author">
                                        <>Submitted </>
                                        {drive.createdBy && 
                                            <>by <NavLink to={`/profile/` + drive.createdBy.username}>{drive.createdBy.username}</NavLink> </>
                                        }
                                        on {format.date(drive.created)}
                                    </p>

                                    <DriveInfo className="drive-meta-data" startAddress={drive.startAddress} endAddress={drive.endAddress}
                                        generalLocation={drive.generalLocation} isLoop={drive.isLoop}
                                        distanceInMeters={drive.distance} travelTimeInMinutes={drive.travelTime}/>

                                    {drive.review &&
                                        <section className="review">
                                            <header className="header">
                                                <h3 className="title">
                                                    <span className="icon-container">
                                                        <FontAwesomeIcon className="icon" icon={faQuoteLeft}/>
                                                    </span>
                                                    Review from {this.state.user.fullname}:
                                                </h3>

                                                <div className="controls">
                                                    {this.state.authUser && this.state.authUser.id === this.state.user.id &&
                                                        <button type="button" className="link-button update-review-button" onClick={() => this.toggleReviewModal(drive)}>
                                                            Update Your Review
                                                        </button>
                                                    }
                                                </div>
                                            </header>
                                            <div className="review-info">
                                                <div className="review-text">
                                                    {format.textBlock(format.truncate(drive.review.text, 400, '...'))}
                                                </div>

                                                <Stars className="star-rating-bw" readOnly={true} rating={drive.review.rating / 2}
                                                    aria-label={`Rated ` + drive.review.rating + ` out of 5 stars`}/>
                                            </div>
                                        </section>
                                    }

                                    <footer className="footer-controls">
                                        <button type="button" className="button" onClick={() => this.showDriveOnMap(drive)}>
                                            <FontAwesomeIcon className="icon" icon={faArrowCircleLeft}/>
                                            <span className="text">Show On Map</span>
                                        </button>

                                        <div className="sub-actions">
                                            <button type="button" className="link-button like-button" onClick={() => this.toggleFavourite(drive)}
                                                disabled={!this.state.authUser || this.state.authUser.id !== this.state.user.id}>
                                                <FontAwesomeIcon className="icon" icon={drive._updatingFavourite ? faSync : (drive.favourite ? fullHeart : emptyHeart)}
                                                    fixedWidth={true} spin={drive._updatingFavourite}/>
                                            </button>
                                        </div>
                                    </footer>
                                </article>
                            )}
                        </div>
                    </section>
                }
            </div>

            {this.state.editReview.visible &&
                <ReviewModal review={this.state.editReview.review} handleClose={this.toggleReviewModal}
                    handleSave={this.saveReview} handleDelete={this.deleteReview}/>
            }
        </>)
    }
}

export default basePageWrapper(ProfileDrives)