import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faSignInAlt, faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

import PasswordField from '../../components/PasswordField'

import validation from '../../utils/validation'

import './styles.scss'

class AccountSignin extends React.Component {
    constructor(props) {
        super(props)

        let qs = {}; this.props.location.search.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });
        let redirectUrl = (qs.redirectUrl || '').trim();
        redirectUrl = redirectUrl.startsWith('/') ? redirectUrl : '';

        this.state = {
            isSubmitted: false,
            incorrectEmailPassword: false,
            signingIn: false,
            redirectUrl: redirectUrl,
            form: {
                email: {
                    val: '',
                    err: null
                },
                password: {
                    val: '',
                    er: null
                }
            }
        };

        document.title = 'Sign-in - Spirited Drive';
        let metaDescription = 'Sign-in to your Spirited Drive account.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);
        
        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            isSubmitted: true
        });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.signingIn) return;
        this.setState({ signingIn: true });

        let loginUrl = this.props.config.apiUrl + '/v1/accounts/login';

        let body = {
            email: this.state.form.email.val,
            password: this.state.form.password.val
        };

        this.props.fetcher.put(loginUrl, body).then(response => {
            if (response.code === 33) {
                this.setState({ incorrectEmailPassword: true });
                return;
            }

            if (response.code !== 0) {
                // TODO: handle errors
                console.error(response.code + ': ' + response.message);
                return;
            }

            let formNode = document.querySelector('.form');
            let form = { ...this.state.form }

            form.email.err = null;
            formNode.elements.email.setCustomValidity('');
            
            this.setState({ form: form });

            if (this.props.handleSignin) {
                this.props.handleSignin(response.token, () => {
                    if (this.state.redirectUrl) {
                        this.props.history.push(this.state.redirectUrl);
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
            }
        });
    }

    handleInputChange = (event) => {
        this.setState({
            incorrectEmailPassword: false,
            form: validation.handleInputChange(event.target, this.state.form)
        });
    }

    getRegisterUrl = () => {
        return '/account/register' + (this.state.redirectUrl ? '?redirectUrl=' + encodeURIComponent(this.state.redirectUrl) : '');
    }

    render() {
        return (
            <div className="main-page-full signin-page narrow-form-page">
                <header className="header">
                    <h1 className="title">Sign-in</h1>
                </header>

                <section className="inner sign-in">
                    <fieldset className="form">
                        <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                            <div className="form-row">
                                <label htmlFor="email" className="label">Email</label>
                                <input id="email" name="email" type="email" className="textbox" required pattern=".*\S+.*"
                                    value={this.state.form.email.val} onChange={this.handleInputChange}/>

                                {this.state.form.email.err && this.state.isSubmitted &&
                                    <p className="validation-error">
                                        <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.email.err}
                                    </p>
                                }
                            </div>

                            <PasswordField className="form-row" id="password" value={this.state.form.password.val}
                                label="Password" required={true} onChange={this.handleInputChange} />

                            {this.state.incorrectEmailPassword &&
                                <div className="bespoke-form-error">
                                    <p className="validation-error">
                                        <FontAwesomeIcon className="icon" icon={faExclamationCircle}/> Incorrect email and/or password.
                                    </p>
                                </div>
                            }

                            <div className="form-row buttons centered">
                                <button type="submit" className="button">
                                    <FontAwesomeIcon className="icon" fixedWidth spin={this.state.signingIn} icon={this.state.signingIn ? faSyncAlt : faSignInAlt}/>
                                    <span className="text">Sign-in</span>
                                </button>
                            </div>
                        </form>
                    </fieldset>

                    <aside className="form-aside">
                        <p>
                            Forgotten your password? <NavLink to="/account/forgot-password">Reset your password now</NavLink>, 
                            we’ll have you back on the road in no time.
                            <span className="or">or</span>
                            Don’t have an account? <NavLink to={this.getRegisterUrl()}>
                                Become a Spirited Driver
                            </NavLink>
                        </p>
                    </aside>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AccountSignin);