import React from 'react'
import { NavLink } from 'react-router-dom'

import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheckCircle, faArrowLeft, faSyncAlt, faKey } from '@fortawesome/pro-solid-svg-icons'

import validation from '../../utils/validation'
import statusCode from '../../utils/apiStatusCodes'

import './styles.scss'

class AccountForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitted: false,
            showSentMessage: false,
            sending: false,
            form: {
                email: {
                    val: '',
                    err: null
                }
            }
        }

        document.title = 'Forgot My Password - Spirited Drive';
        let metaDescription = 'Forgotten your Spirited Drive password? Use this form and we\'ll ' +
            'email you instructions on how to reset your password';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.sending) return;
        this.setState({ sending: true });

        let url = this.props.config.apiUrl + '/v1/accounts/forgotPassword';
        let data = {
            email: this.state.form.email.val
        };

        this.props.fetcher.put(url, data).then(response => {
            // email not recognised
            if (response.code === statusCode.incorrectAuthDetails) {
                this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'email', 'Email address not found.') });
                return;
            }

            if (response.code !== 0) {
                // TODO: handle error
                console.error(response.code + ': ' + response.message);
                return;
            }

            this.setState({ showSentMessage: true, sending: false })
        });
    }

    render() {
        return (
            <div className="main-page-full forgot-password-page narrow-form-page">
                <header className="header">
                    <h1 className="title">Forgot My Password</h1>
                    <p className="intro">
                        We'll email you instructions to reset your password,<br/>
                        you'll be back on the road in no time.
                    </p>
                </header>

                <section className="inner">
                    <fieldset className="form">
                        {!this.state.showSentMessage &&
                            <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                                <div className="form-row">
                                    <label htmlFor="email" className="label">Email</label>
                                    <div className="control">
                                        <input id="email" name="email" type="email" className="textbox" required
                                            value={this.state.form.email.val} onChange={this.handleInputChange}/>

                                        {this.state.form.email.err && this.state.isSubmitted &&
                                            <p className="validation-error">
                                                <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.email.err}
                                            </p>
                                        }
                                    </div>
                                </div>

                                <div className="form-row buttons centered">
                                    <button type="submit" className="button">
                                        <FontAwesomeIcon className="icon" fixedWidth spin={this.state.sending} icon={this.state.sending ? faSyncAlt : faKey}/>
                                        <span className="text">Reset My Password</span>
                                    </button>
                                </div>
                            </form>
                        }

                        {this.state.showSentMessage &&
                            <div className="sent-success">
                                <FontAwesomeIcon className="icon" icon={faCheckCircle}/>
                                <p>Password reset sent. Please check your email.</p>
                                <p>Thank you.</p>
                            </div>
                        }
                    </fieldset>

                    <aside className="form-aside">
                        <p>
                            <NavLink to="/account/sign-in"><FontAwesomeIcon className="icon" icon={faArrowLeft}/> Back</NavLink>
                        </p>
                    </aside>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AccountForgotPassword);