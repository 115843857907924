function extractField(form, name) {
    let field = null;

    // if field name is something like prop.subProp.subSubProp or items[0].prop
    if (name.indexOf('.') !== -1) {
        let parts = name.split('.');
        parts.forEach(part => {
            if (!field) field = form;

            // e.g. items[0].prop
            let indexedPart = part.match(/(.+)\[([0-9]+)\]/i);
            if (indexedPart) {
                part = indexedPart[1];
                let indexPos = parseInt(indexedPart[2]);
                field = field[part][indexPos];
            }
            // e.g. prop.subProp
            else {
                field = field[part];
            }
        })
    }
    else {
        field = form[name];
    }

    return field;
}

const functions = {
    handleInputChange: function(target, formData) {
        if (!formData) {
            console.error('"formData" for validation is null')
            return null;
        }

        target.setCustomValidity('');

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (!name) {
            console.error('"name" attribute is missing from form input');
            return formData;
        }

        let form = { ...formData }
        let field = extractField(form, name);

        if (!field) {
            console.error('Could not locate property "' + name + '" on form data model');
            return formData;
        }

        field.val = value;
        field.err = null;

        let modelState = target.validity;

        if (modelState.typeMismatch) {
            switch(target.type) {
                case 'email':
                    target.setCustomValidity('Not a valid email address');
                    break;
                case 'url':
                    target.setCustomValidity('Not a valid web address');
                    break;
                default:
                    target.setCustomValidity('Not a valid value');
                    break;
            }
        }

        if (target.minLength !== -1) {
            if (value.length !== 0 && value.length < target.minLength) {
                target.setCustomValidity('Must be at least ' + target.minLength + ' characters.');
            }
        }

        if (modelState.patternMismatch) {
            let errorMessage = target.getAttribute('data-error-pattern');
            if (errorMessage) {
                target.setCustomValidity(errorMessage);
            }
        }

        if (modelState.customError) {
            field.err = target.validationMessage;
        }

        return form;
    },

    markFieldInvalid: function(formNode, formData, fieldName, errorMessage) {
        let form = { ...formData }
        let field = extractField(form, fieldName);

        field.err = errorMessage;

        let fieldElement = formNode.elements[fieldName];
        if (fieldElement) fieldElement.setCustomValidity(errorMessage);
        
        return form;
    }
}

export default functions;