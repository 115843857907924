import React from 'react'
import basePageWrapper from './BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faSearchLocation, faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

import validation from '../utils/validation';
import format from '../utils/format';
import Storage from '../utils/storage'

import drivePin from '../images/pin-drive.png';
import drivePinStart from '../images/pin-drive-start.png';
import drivePinEnd from '../images/pin-drive-end.png';
import drivePinLoop from '../images/pin-drive-loop.png';

class Home extends React.Component {
    static PopupDelay = 500;
    static LineColour = '#0080ff';
    static LineHighlightColour = '#003D9E';

    constructor(props) {
        super(props)

        document.title = 'Spirited Drive - Find Great Driving Roads';

        let metaDescription = 'Spirited Drive is a crowdsourced searchable online database of the world\'s best driving roads.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setSearch();
    }

    searchResults = [];
    countryResults = [];
    loadingResults = false;

    authUser = null;
    authStateDetermined = false;

    map = null;
    google = null;

    mapMarkers = [];
    mapEvents = [];

    state = {
        location: {
            searching: false,
            supported: true
        },

        addressSearching: false,
        addressForm: {
            input: {
                val: '',
                err: null
            }
        },
        addressSubmitted: false
    }

    markerPopup = null;
    linePopup = null;
    markerPopupOpen = false;
    popupTimeout = null;

    componentDidMount() {
        let mapCenterCoords = null;

        if (this.props.qs.resetMap) {
            const storage = Storage.get(window);
            let center = storage.getItem('defaultMapCenter');

            if (center) {
                try {
                    mapCenterCoords = JSON.parse(center);
                }
                catch (e) {
                    storage.removeItem('defaultMapCenter');
                }
            }

            let qs = format.querystring(this.props.location);
            delete qs.resetMap;
            this.props.history.replace('/' + qs.toString());
        }

        this.props.authUserStateReady(authUser => {
            this.authUser = authUser;
            this.authStateDetermined = true;

            this.initMap();
        });

        this.props.mapReady((map, google) => {
            this.map = map;
            this.google = google;

            this.map._enableControls();
            this.map.streetView.setVisible(false);

            if (mapCenterCoords) {
                this.map.setCenter(mapCenterCoords);
                this.map.setZoom(5);
            }

            this.markerPopup = new this.google.maps.InfoWindow({
                disableAutoPan: true,
                pixelOffset: {
                    height: -45,
                    width: 0
                }
            });

            this.markerPopup.addListener('domready', () => {
                const dialogParent = this.markerPopup.content.closest('.gm-style-iw[role="dialog"]');
                if (dialogParent) dialogParent.classList.add('custom-map-dialog');
            });

            this.linePopup = new this.google.maps.InfoWindow({
                disableAutoPan: true,
                pixelOffset: {
                    height: -10,
                    width: 0
                }
            });

            this.linePopup.addListener('domready', () => {
                const dialogParent = this.linePopup.content.closest('.gm-style-iw[role="dialog"]');
                if (dialogParent) dialogParent.classList.add('custom-map-dialog');
            });

            this.initMap();
        });
    }

    dragging = false;

    initMap = () => {
        let ready = this.authStateDetermined && this.map;
        if (!ready) return;

        this.updateMap(this.map);

        const tilesloadedEvent = this.map.addListener('tilesloaded', () => {
            this.updateMap(this.map);
            this.google.maps.event.removeListener(tilesloadedEvent);
        });

        const dragStartEvent = this.map.addListener('dragstart', () => {
            this.dragging = true;
        });
        this.mapEvents.push(dragStartEvent);

        const draggedEvent = this.map.addListener('idle', () => {
            if (this.dragging) {
                this.dragging = false;
                this.updateMap(this.map);
            }
        });
        this.mapEvents.push(draggedEvent);

        const zoomedEvent = this.map.addListener('zoom_changed', () => {
            this.updateMap(this.map);
        });
        this.mapEvents.push(zoomedEvent);
    }

    resultsLoadingCheck = null;

    updateMap = (map) => {
        let bounds = map.getBounds();
        if (!bounds) return;

        // queue up the last call to "updateMap" so that it still runs
        // in case end user has a slow internet
        if (this.loadingResults) {
            if (this.resultsLoadingCheck) {
                clearInterval(this.resultsLoadingCheck);
            }

            this.resultsLoadingCheck = setInterval(() => {
                if (!this.loadingResults) {
                    this.updateMap(map);
                    clearInterval(this.resultsLoadingCheck);
                }
            }, 100);

            return;
        };

        this.loadingResults = true;

        let zoomLevel = map.getZoom();

        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();

        let neLat = ne.lat();
        let neLng = ne.lng();
        let swLat = sw.lat();
        let swLng = sw.lng();

        const useCountryDisplay = zoomLevel <= 4;
        const useCenterPoints = zoomLevel >= 5 && zoomLevel <= 7;
        const useFullPaths = zoomLevel > 7;

        // means we're zoomed out at a high level and need to stop bounds box overlapping itself
        if (swLng > neLng) {
            neLng = 180;
            swLng = -180;
        }

        let boundsQuery = neLat + ',' + neLng + '|' + swLat + ',' + swLng;

        let driveUrl = this.props.config.apiUrl + '/v1/drives?' +
            'withinBounds=' + encodeURIComponent(boundsQuery) + '&' +
            'recordsPerPage=1000';

        if (useFullPaths) {
            driveUrl += '&includePolyline=true';
        }

        if (useCountryDisplay) {
            driveUrl += '&groupCountries=true';
        }

        this.props.fetcher.get(driveUrl)
            .then(response => {
                let drivesToRemove = [];
                let countriesToRemove = [];

                const hintNode = document.createElement('a');
                hintNode.classList.add('drive-name-hint-popup');
                hintNode.addEventListener('click', (e) => {
                    e.preventDefault();

                    let href = e.target.getAttribute('href');
                    this.props.history.push(href);
                });

                if (useCountryDisplay) {
                    response.records.forEach(country => {
                        let existingCountry = this.countryResults.find(c => c.code === country.code)
                        if (existingCountry) return;

                        this.countryResults.push(country);

                        let labelText = country.numDrives.toString()
                        if (country.numDrives > 99) {
                            labelText = '99+';
                        }

                        country.marker = new this.google.maps.Marker({
                            map,
                            title: country.name + ' (' + country.numDrives + ' drives)',
                            position: {
                                lat: country.point.lat,
                                lng: country.point.lng
                            },
                            label: {
                                text: labelText,
                                fontSize: '11px',
                                fontWeight: 'bold',
                                color: '#ffffff'
                            }
                        });

                        country.marker.addListener('click', () => {
                            map.panTo({
                                lat: country.point.lat,
                                lng: country.point.lng
                            });
                            map.setZoom(5);
                        });
                    });

                    drivesToRemove = [...this.searchResults];

                    countriesToRemove = [...this.countryResults.filter(country => {
                        return !response.records.some(c => c.code === country.code);
                    })];
                }
                else {
                    const configurePolyline = (drive) => {
                        const path = this.google.maps.geometry.encoding.decodePath(drive.encodedPolyline);

                        drive.polyline = new this.google.maps.Polyline({
                            path: path,
                            clickable: true,
                            geodesic: true,
                            strokeColor: Home.LineColour,
                            strokeOpacity: 0.8,
                            strokeWeight: 6
                        });

                        drive.polyline.addListener('mouseover', e => {
                            if (this.markerPopupOpen) return;

                            this.popupTimeout = setTimeout(() => {
                                this.linePopup.setPosition(e.latLng);

                                hintNode.setAttribute('href', '/drives/' + encodeURIComponent(drive.slug))
                                hintNode.textContent = drive.name;
                                this.linePopup.setContent(hintNode);

                                this.linePopup.open({
                                    map: this.map,
                                    shouldFocus: false
                                });
                            }, Home.PopupDelay);

                            clearTimeout(drive.polylineTimeout);

                            this.searchResults.forEach(drive => {
                                if (drive.polyline && drive.polyline.strokeColor === Home.LineHighlightColour) {
                                    drive.polyline.setOptions({
                                        strokeColor: Home.LineColour,
                                    });
                                }
                            });

                            drive.polyline.setOptions({
                                strokeColor: Home.LineHighlightColour,
                            });
                        });
                            
                        drive.polyline.addListener('mouseout', () => {
                            if (this.markerPopupOpen) return;

                            clearTimeout(this.popupTimeout);
                            this.linePopup.close();

                            drive.polylineTimeout = setTimeout(() => {
                                if (drive.polyline) {
                                    drive.polyline.setOptions({
                                        strokeColor: Home.LineColour,
                                    });
                                }
                            }, 200);
                        });

                        drive.polyline.addListener('click', () => {
                            this.props.history.push('/drives/' + encodeURIComponent(drive.slug));
                        });
                    }

                    response.records.forEach(drive => {
                        let existingDrive = this.searchResults.find(d => d.id === drive.id)
                        if (existingDrive) {
                            existingDrive.encodedPolyline = drive.encodedPolyline;

                            if (existingDrive.encodedPolyline && !existingDrive.polyline) {
                                configurePolyline(existingDrive);
                            }

                            return;
                        }

                        this.searchResults.push(drive);

                        drive.centerMarker = new this.google.maps.Marker({
                            position: {
                                lat: drive.centerPoint.lat,
                                lng: drive.centerPoint.lng
                            },
                            icon: {
                                url: drivePin,
                                size: new this.google.maps.Size(26, 34),
                                scaledSize: new this.google.maps.Size(26, 34)
                            }
                        });

                        drive.centerMarker.addListener('click', () => {
                            this.props.history.push('/drives/' + encodeURIComponent(drive.slug));
                        });

                        drive.centerMarker.addListener('mouseover', e => {
                            this.popupTimeout = setTimeout(() => {
                                this.markerPopup.setPosition(e.latLng);

                                hintNode.setAttribute('href', '/drives/' + encodeURIComponent(drive.slug))
                                hintNode.textContent = drive.name;
                                this.markerPopup.setContent(hintNode);

                                this.markerPopup.open({
                                    map: this.map,
                                    shouldFocus: false
                                });
                            }, Home.PopupDelay);

                            this.linePopup.close();
                            this.markerPopupOpen = true;

                            clearTimeout(drive.polylineTimeout);
                        });

                        drive.centerMarker.addListener('mouseout', () => {
                            this.markerPopup.close();
                            this.markerPopupOpen = false;

                            if (drive.polyline) {
                                drive.polyline.setOptions({
                                    strokeColor: Home.LineColour,
                                });
                            }

                            clearTimeout(this.popupTimeout);
                        });

                        drive.startMarker = new this.google.maps.Marker({
                            position: {
                                lat: drive.start.lat,
                                lng: drive.start.lng
                            },
                            icon: {
                                url: drive.isLoop ? drivePinLoop : drivePinStart,
                                size: new this.google.maps.Size(26, 34),
                                scaledSize: new this.google.maps.Size(26, 34)
                            }
                        });

                        drive.startMarker.addListener('click', () => {
                            this.props.history.push('/drives/' + encodeURIComponent(drive.slug));
                        });

                        drive.startMarker.addListener('mouseover', e => {
                            this.popupTimeout = setTimeout(() => {
                                this.markerPopup.setPosition(e.latLng);

                                hintNode.setAttribute('href', '/drives/' + encodeURIComponent(drive.slug))
                                hintNode.textContent = drive.name;
                                this.markerPopup.setContent(hintNode);

                                this.markerPopup.open({
                                    map: this.map,
                                    shouldFocus: false
                                });
                            }, Home.PopupDelay);

                            this.linePopup.close();
                            this.markerPopupOpen = true;

                            clearTimeout(drive.polylineTimeout);

                            if (drive.polyline) {
                                this.searchResults.forEach(drive => {
                                    if (drive.polyline && drive.polyline.strokeColor === Home.LineHighlightColour) {
                                        drive.polyline.setOptions({
                                            strokeColor: Home.LineColour,
                                        });
                                    }
                                });

                                drive.polyline.setOptions({
                                    strokeColor: Home.LineHighlightColour,
                                });
                            }
                        });

                        drive.startMarker.addListener('mouseout', () => {
                            this.markerPopup.close();
                            this.markerPopupOpen = false;

                            if (drive.polyline) {
                                drive.polyline.setOptions({
                                    strokeColor: Home.LineColour,
                                });
                            }

                            clearTimeout(this.popupTimeout);
                        });

                        if (!drive.isLoop) {
                            drive.endMarker = new this.google.maps.Marker({
                                position: {
                                    lat: drive.end.lat,
                                    lng: drive.end.lng
                                },
                                icon: {
                                    url: drivePinEnd,
                                    size: new this.google.maps.Size(26, 34),
                                    scaledSize: new this.google.maps.Size(26, 34)
                                }
                            });

                            drive.endMarker.addListener('click', () => {
                                this.props.history.push('/drives/' + encodeURIComponent(drive.slug));
                            });

                            drive.endMarker.addListener('mouseover', e => {
                                this.popupTimeout = setTimeout(() => {
                                    this.markerPopup.setPosition(e.latLng);
        
                                    hintNode.setAttribute('href', '/drives/' + encodeURIComponent(drive.slug))
                                    hintNode.textContent = drive.name;
                                    this.markerPopup.setContent(hintNode);
        
                                    this.markerPopup.open({
                                        map: this.map,
                                        shouldFocus: false
                                    });
                                }, Home.PopupDelay);
    
                                this.linePopup.close();
                                this.markerPopupOpen = true;

                                clearTimeout(drive.polylineTimeout);

                                if (drive.polyline) {
                                    this.searchResults.forEach(drive => {
                                        if (drive.polyline && drive.polyline.strokeColor === Home.LineHighlightColour) {
                                            drive.polyline.setOptions({
                                                strokeColor: Home.LineColour,
                                            });
                                        }
                                    });

                                    drive.polyline.setOptions({
                                        strokeColor: Home.LineHighlightColour,
                                    });
                                }
                            });
    
                            drive.endMarker.addListener('mouseout', () => {
                                this.markerPopup.close();
                                this.markerPopupOpen = false;

                                if (drive.polyline) {
                                    drive.polyline.setOptions({
                                        strokeColor: Home.LineColour,
                                    });
                                }

                                clearTimeout(this.popupTimeout);
                            });
                        }

                        if (drive.encodedPolyline) {
                            configurePolyline(drive);
                        }
                    });

                    drivesToRemove = [...this.searchResults.filter(drive => {
                        return !response.records.some(d => d.id === drive.id);
                    })];

                    countriesToRemove = [...this.countryResults];
                }

                drivesToRemove.forEach(drive => {
                    let index = this.searchResults.indexOf(drive);
                    this.searchResults.splice(index, 1);

                    if (drive.startMarker) {
                        drive.startMarker.setMap(null);
                    }

                    if (drive.endMarker) {
                        drive.endMarker.setMap(null);
                    }

                    if (drive.centerMarker) {
                        drive.centerMarker.setMap(null);
                    }

                    if (drive.polyline) {
                        drive.polyline.setMap(null);
                    }
                });

                countriesToRemove.forEach(country => {
                    let index = this.countryResults.indexOf(country);
                    this.countryResults.splice(index, 1);

                    if (country.marker) {
                        country.marker.setMap(null);
                    }
                });

                // ensure smaller drive have a higher z-index to fix selecting issues
                let xIndex = 1;

                this.searchResults
                    .sort((d1, d2) => d2.distance - d1.distance)
                    .forEach(drive => {
                        if (!drive.polyline) return;

                        drive.polyline.setOptions({
                            zIndex: xIndex
                        });

                        xIndex++;
                    });

                this.searchResults.forEach(drive => {
                    if (useCenterPoints) {
                        if (drive.centerMarker) drive.centerMarker.setMap(this.map);
                        if (drive.endMarker) drive.endMarker.setMap(null);
                        if (drive.startMarker) drive.startMarker.setMap(null);
                        if (drive.polyline) drive.polyline.setMap(null);
                    }

                    if (useFullPaths) {
                        if (drive.centerMarker) drive.centerMarker.setMap(null);
                        if (drive.endMarker) drive.endMarker.setMap(this.map);
                        if (drive.startMarker) drive.startMarker.setMap(this.map);
                        if (drive.polyline) drive.polyline.setMap(this.map);
                    }
                });

                this.loadingResults = false;
            });
    }

    lookupLocation = () => {
        if (this.state.location.searching || !this.state.location.supported) return;

        this.setState({ location: {
            ...this.state.location,
            searching: true
        }});

        window.navigator.geolocation.getCurrentPosition(
            position => {
                this.map.panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });

                this.map.setZoom(12);

                this.setState({ location: {
                    ...this.state.location,
                    searching: false,
                    supported: true
                }});
            },
            error => {
                let message = null;

                if (error.code === 1) {
                    message =
                        'Spirited Drive does not have permission to use your location.\n' +
                        '\n' +
                        'Either change permission in your browser\'s settings, or use the search box to the right, or just pan/zoom on the map.';
                }
                else {
                    message = 'Location not available. Please use the search box to the right, or just pan/zoom on the map.';
                }

                this.setState({ location: {
                    ...this.state.location,
                    searching: false,
                    notAvailable: true
                }});

                window.alert(message);
            }, {
                enableHighAccuracy: false,
                timeout: 10000
            });
    }

    handleInputChange = (event) => {
        this.setState({ addressForm: validation.handleInputChange(event.target, this.state.addressForm) });
    }

    searchAddress = (event) => {
        event.preventDefault();

        this.setState({
            addressSubmitted: true
        });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.addressSearching) return;
        this.setState({ addressSearching: true });

        const geocoder = new this.google.maps.Geocoder();

        geocoder.geocode({ address: this.state.addressForm.input.val }, (results, status) => {
            if (status !== 'OK' && status !== 'ZERO_RESULTS') {
                console.error(status);

                let message = 'Sorry, there was a problem search this address. Please try again later.'
                window.alert(message);
                return;
            }

            this.setState({ addressSearching: false });
            
            if (status === 'ZERO_RESULTS') {
                window.alert('No results');
                return;
            }

            let result = results[0];
            this.map.panTo(result.geometry.location);
            this.map.setZoom(12);
        });
    }

    componentWillUnmount() {
        this.mapEvents.forEach(e => this.google.maps.event.removeListener(e));

        this.searchResults.forEach(drive => {
            if (drive.startMarker) drive.startMarker.setMap(null);
            if (drive.endMarker) drive.endMarker.setMap(null);
            if (drive.centerMarker) drive.centerMarker.setMap(null);
            if (drive.polyline) drive.polyline.setMap(null);
        });

        this.countryResults.forEach(country => {
            if (country.marker) country.marker.setMap(null);
        });

        if (this.resultsLoadingCheck) {
            clearInterval(this.resultsLoadingCheck);
        }

        clearTimeout(this.popupTimeout);
        if (this.markerPopup) this.markerPopup.close();
        if (this.linePopup) this.linePopup.close();
    }

    render() {
        return (
            <div className="home-page">
                <fieldset className="search-map">
                    <legend>Search for the best driving roads</legend>

                    <div className="use-location">
                        <button type="button" className="button cta" onClick={this.lookupLocation}>
                            <FontAwesomeIcon className="icon" fixedWidth spin={this.state.location.searching} icon={this.state.location.searching ? faSyncAlt : faCompass}/>
                            <span className="text">Use Current Location</span>
                        </button>
                    </div>

                    <p className="or">or</p>

                    <form className={`use-address` + (this.state.addressSubmitted ? ` submitted` : ``)} onSubmit={this.searchAddress} noValidate>
                        <div className="form-row inline">
                            <input type="text" name="input" className="textbox" placeholder="Search by address/postcode" required
                                aria-label="Search by address/postcode" value={this.state.addressForm.input.val} onChange={this.handleInputChange}/>
                        </div>

                        <button type="submit" className="button search-location-buton">
                            <FontAwesomeIcon className="icon" fixedWidth spin={this.state.addressSearching} icon={this.state.addressSearching ? faSyncAlt : faSearchLocation}/>
                            <span className="text">Search</span>
                        </button>
                    </form>
                </fieldset>
            </div>
        )
    }
}

export default basePageWrapper(Home);