import React from 'react'
import { NavLink } from 'react-router-dom'

import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faRoad, faSyncAlt } from '@fortawesome/pro-solid-svg-icons'

import './add.scss'

import validation from '../../utils/validation'
import geo from '../../utils/geo'
import Storage from '../../utils/storage'

class DrivesAdd extends React.Component {
    static InvalidMapsUrlMessage = {
        Subject: 'Can\'t Upload My Google Maps URL',
        Body: 'Hi,\n' +
              '\n' +
              'I tried to upload the following Google Maps URL:\n' +
              '\n' +
              '##URL##\n' +
              '\n' +
              '...and it says the URL is NOT valid, but I believe it IS valid.\n' +
              '\n' +
              'Thank you!'
    }

    constructor(props) {
        super(props)

        let qs = {}; this.props.location.search.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });
        let autoSubmitUrl = (qs.autoSubmitUrl || '').trim();

        this.state = {
            isSubmitted: false,
            authUser: null,
            loading: false,
            autoSubmit: autoSubmitUrl.length > 0,
            reportInvalidMapsUrl: false,
            form: {
                mapsUrl: {
                    val: autoSubmitUrl,
                    err: null
                }
            }
        }

        document.title = 'Add Your Own Drive - Spirited Drive';
        let metaDescription = 'Add your own drive to Spirited Drive, a crowdsourced searchable online database of the world\'s best driving roads.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);

            this.loadedMap = true;
            this.checkAutoSubmit();
        });

        this.props.authUserStateReady(authUser => {
            this.setState({ authUser: authUser }, () => {
                this.loadedUser = true;
                this.checkAutoSubmit();
            });
        });
    }

    loadedMap = false;
    loadedUser = false;

    checkAutoSubmit = () => {
        let ready = this.loadedMap && this.loadedUser;
        if (!ready) return;

        if (this.state.autoSubmit) {
            document.querySelector('.add-drive-button').click();
        }
    }

    handleInputChange = (event) => {
        this.setState({
            form: validation.handleInputChange(event.target, this.state.form),
            reportInvalidMapsUrl: false
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            isSubmitted: true,
            reportInvalidMapsUrl: false
        });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        this.props.trackEvent('StartedDriveUpload');

        let mapsUrl = this.state.form.mapsUrl.val;
        let directionsData = geo.parseGoogleMapsUrl(mapsUrl);
        
        let errorMessage = null;

        if (!directionsData) {
            errorMessage = 'This doesn\'t appear to be valid Google Maps URL.';

            // check if potentially it's a Google Maps URL, in case Google changes format of the URLs in future
            if (mapsUrl.match(/https:\/\/(maps\.|www\.)?google\.[a-z.]+\/.+/i)) {
                this.setState({ reportInvalidMapsUrl: true });
            }
        }
        else if (directionsData.waypoints.length < 2) {
            errorMessage = 'We weren\'t able to extract a driving route from this URL.';
        }
        else if (directionsData.transportation !== 'car') {
            errorMessage = 'Transportation mode must be set to "Driving" in Google Maps';
        }

        if (errorMessage) {
            this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', errorMessage) });
            return;
        }

        let waypoints = directionsData.waypoints;

        let start = waypoints[0];
        let end = waypoints[waypoints.length - 1];
        let midWaypoints = waypoints
            .slice(1, waypoints.length - 1)
            .map(coord => {
                return {
                    location: coord,
                    stopover: false
                };
            });

        // if start & end are only 20 meters appart, assume they are the same
        // i.e. it's a circular route
        let isLoop = false;

        let distanceInMeters = geo.getDistanceFromLatLngInKm(start, end) * 1000;
        if (distanceInMeters < 20) {
            end = {
                lat: start.lat,
                lng: start.lng
            };

            isLoop = true;
        }

        // check valid waypoints
        if (isLoop && midWaypoints.length === 0) {
            let message = 'Drive must be at least 0.5km (0.31 miles) long.'
            this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', message) });
            return;
        }

        // not logged in
        if (!this.state.authUser) {
            let redirectUrl = this.props.location.pathname + '?autoSubmitUrl=' + encodeURIComponent(this.state.form.mapsUrl.val);
            this.props.history.push('/account/register?redirectUrl=' +  encodeURIComponent(redirectUrl));
            return;
        }

        if (this.state.loading) return;
        this.setState({ loading: true });

        // get directions
        let directionsService = new window.google.maps.DirectionsService();

        let request = {
            origin: start,
            destination: end,
            waypoints: midWaypoints,
            avoidFerries: directionsData.avoidFerries,
            avoidHighways: directionsData.avoidHighways,
            avoidTolls: directionsData.avoidTolls,
            travelMode: 'DRIVING'
        };

        directionsService.route(request, (result, status) => {
            if (status !== 'OK') {
                console.error(status);

                let message = 'Sorry, there was a problem processing this URL. Please try again later.'
                this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', message), loading: false });
                return;
            }

            let route = result.routes[0];

            // get distance/duration
            let distanceInMeters = 0;
            let durationInSeconds = 0;

            route.legs.forEach(leg => {
                distanceInMeters += leg.distance.value;
                durationInSeconds += leg.duration.value;
            });

            // check drive is long enough
            if (distanceInMeters < 500) {
                let message = 'Drive must be at least 0.5km (0.31 miles) long.'
                this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', message), loading: false });
                return;
            }

            // get route polyline at full resolution
            let points = [];

            route.legs.forEach(leg => {
                leg.steps.forEach(step => {
                    step.lat_lngs.forEach(lat_lng => {
                        points.push({
                            lat: lat_lng.lat(),
                            lng: lat_lng.lng()
                        });
                    });
                });
            });

            let simplifiedPoints = geo.simplifyLine(points);
            let encodedPath = geo.encodePoints(simplifiedPoints);

            // get the bounds
            let northEast = route.bounds.getNorthEast();
            let southWest = route.bounds.getSouthWest();

            let bounds = {
                ne: {
                    lat: northEast.lat(),
                    lng: northEast.lng()
                },
                sw: {
                    lat: southWest.lat(),
                    lng: southWest.lng()
                }
            };

            // get information about start/end points for descriptions to show on confirmation screen
            let startLocation = null;
            let endLocation = null;
            let generalLocation = null;
            let nameCandidate = null;

            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ location: start }, (results, status) => {
                if (status !== 'OK') {
                    console.error(status);

                    let message = 'Sorry, there was a problem processing this URL. Please try again later.'
                    this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', message), loading: false });
                    return;
                }

                let result = results[0];

                let localities = result.address_components.filter(a => a.types.includes('locality'));
                let locality = (localities[localities.length - 1] || {}).long_name || '';

                let postalTown = (result.address_components.find(a => a.types.includes('postal_town')) || {}).long_name || '';
                let adminArea2 = (result.address_components.find(a => a.types.includes('administrative_area_level_2')) || {}).long_name || '';
                let postalCode = (result.address_components.find(a => a.types.includes('postal_code')) || {}).long_name || '';

                startLocation = (locality ? locality + ', ' : '') + (postalTown ? postalTown + ', ' : '') + postalCode;
                startLocation = startLocation || adminArea2 || 'Nowhere';

                // e.g. Scotland, GB
                let administrativeAreaLevel1 = (result.address_components.find(a => a.types.includes('administrative_area_level_1')) || {}).long_name;
                let country = (result.address_components.find(a => a.types.includes('country')) || {}).short_name;
                
                let generalLocationPrefix = administrativeAreaLevel1 || adminArea2 || locality;
                generalLocation = (generalLocationPrefix ? generalLocationPrefix + ', ' : '') + country;

                if (isLoop) {
                    endLocation = startLocation;

                    let candidateSummary = route.summary ? route.summary + ' ' : '';
                    let candidateTo = locality && postalTown ? (locality + ', ' + postalTown) : (locality || postalTown);
                    candidateTo = candidateTo || adminArea2;
                    let candidateTitle = candidateSummary + candidateTo;
                    candidateTitle = candidateTitle || 'uknown';
                    nameCandidate = 'The ' + candidateTitle + ' loop';
                }

                setRouteInfoAndContinue();
            });

            if (!isLoop) {
                geocoder.geocode({ location: end }, (results, status) => {
                    if (status !== 'OK') {
                        console.error(status);

                        let message = 'Sorry, there was a problem processing this URL. Please try again later.'
                        this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'mapsUrl', message), loading: false });
                        return;
                    }

                    let result = results[0];

                    let localities = result.address_components.filter(a => a.types.includes('locality'));
                    let locality = (localities[localities.length - 1] || {}).long_name || '';

                    let postalTown = (result.address_components.find(a => a.types.includes('postal_town')) || {}).long_name || '';
                    let adminArea2 = (result.address_components.find(a => a.types.includes('administrative_area_level_2')) || {}).long_name || '';
                    let postalCode = (result.address_components.find(a => a.types.includes('postal_code')) || {}).long_name || '';

                    endLocation = (locality ? locality + ', ' : '') + (postalTown ? postalTown + ', ' : '') + postalCode;
                    endLocation = endLocation || adminArea2 || 'Nowhere';

                    let candidateSummary = route.summary || 'road';
                    let candidateTo = (locality && postalTown ? (locality + ', ' + postalTown) : (locality || postalTown));
                    candidateTo = candidateTo || adminArea2 || 'nowhere';
                    nameCandidate = 'The ' + candidateSummary + ' to ' + candidateTo;

                    setRouteInfoAndContinue();
                });
            }

            let setRouteInfoAndContinue = () => {
                if (!startLocation || !endLocation) return;

                let routeInfo = {
                    start: start,
                    end: end,
                    midWaypoints: midWaypoints.map(w => {
                        return { lat: w.location.lat, lng: w.location.lng }
                    }),
                    isLoop: isLoop,
                    bounds: bounds,
                    avoid: {
                        ferries: directionsData.avoidFerries,
                        highways: directionsData.avoidHighways,
                        tolls: directionsData.avoidTolls,
                    },
                    distanceInMeters: distanceInMeters,
                    durationInSeconds: durationInSeconds,
                    encodedPath: encodedPath,
                    summary: {
                        nameCandidate: nameCandidate,
                        startLocation: startLocation,
                        endLocation: endLocation,
                        generalLocation: generalLocation
                    }
                };

                const routeInfoString = JSON.stringify(routeInfo);

                const storage = Storage.get(window);
                storage.setItem('AddDriveRouteInfo', routeInfoString);

                this.props.history.push('/drives/confirm');
            }
        });
    }

    render() {
        const body = DrivesAdd.InvalidMapsUrlMessage.Body.replace('##URL##', this.state.form.mapsUrl.val);

        const mailTo = `mailto:${this.props.config.email}?` +
            `subject=${encodeURIComponent(DrivesAdd.InvalidMapsUrlMessage.Subject)}&` +
            `body=${encodeURIComponent(body)}`

        return (
            <div className="main-page-full add-drive-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/drives/add" aria-current="location" className="link">Add Your Drive</NavLink></li>
                    </ol>
                </nav>

                <section className="add-your-drive narrow-form-section">
                    <header className="header">
                        <h1 className="title">Add Your Own Drive</h1>

                        <ul className="drive-upload-instructions">
                            <li>Visit <a target="_blank" rel="noreferrer" href="https://maps.google.com">Google Maps</a> in your browser (mobile app isn’t supported).</li>
                            <li>Create a driving route with a start and end.</li>
                            <li>Optional: Drag the road around with your mouse to force a specific route.</li>
                            <li>Copy ’n’ Paste the full web address from your browser’s address bar and paste it here.</li>
                        </ul>
                    </header>

                    <fieldset className="form" >
                        <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                            <div className="form-row inline">
                                <div className="control">
                                    <input name="mapsUrl" type="url" className="textbox" value={this.state.form.mapsUrl.val} onChange={this.handleInputChange}
                                        required placeholder="Google Maps URL"/>

                                    {this.state.form.mapsUrl.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/> {this.state.form.mapsUrl.err}
                                        </p>
                                    }

                                    {this.state.reportInvalidMapsUrl &&
                                        <p className="report-invalid-url-message">
                                            If you believe this <strong>is</strong> a valid Google Maps URL, please contact <a target="_blank" rel="noreferrer" href={mailTo}>{this.props.config.email}</a>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="form-row buttons centered">
                                <button type="submit" className="button cta chunky add-drive-button">
                                    <FontAwesomeIcon className="icon" fixedWidth spin={this.state.loading} icon={this.state.loading ? faSyncAlt : faRoad}/>
                                    <span className="text">Add Drive</span>
                                </button>
                            </div>
                        </form>
                    </fieldset>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(DrivesAdd)