import React from 'react';
import ImageModal from './modals/Image';

class PopupImage extends React.Component {
    state = {
        showModal: false
    };

    handleClick = (event) => {
        event.preventDefault();

        if (this.props.onClick) {
            this.props.onClick(this.props.imageId || null)
        }

        this.setState({ showModal: true });
    }

    handleClose = () => {
        if (this.props.onModalClose) {
            this.props.onModalClose(this.props.imageId || null);
        }

        this.setState({ showModal: false });
    }

    render() {
        return (<>
            <a className="popup-image" href={this.props.largeSrc} onClick={this.handleClick}>
                <img className="image" alt={this.props.caption} src={this.props.src}/>
            </a>

            {this.state.showModal &&
                <ImageModal onModalClose={this.handleClose} src={this.props.largeSrc || this.props.src} uploader={this.props.uploader}
                    caption={this.props.caption} source={this.props.source} sourceUrl={this.props.sourceUrl} />
            }
        </>)
    }
}

export default PopupImage;