import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

class AboutTerms extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'Terms & Conditions - Spirited Drive';
        let metaDescription = 'Spirited Drive Terms & Conditions.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    render() {
        return (
            <div className="main-page-full about-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/about" aria-current="location" className="link">About</NavLink></li>
                        <li className="item"><NavLink exact to="/about/terms" className="link">Terms &amp; Conditions</NavLink></li>
                    </ol>
                </nav>

                <section className="about-section">
                    <h1 className="title">Terms &amp; Conditions</h1>

                    <section className="sub-section">
                        <h2>Creating Your Own Drives</h2>

                        <p>
                            This is a crowdsourced website, so any drives/routes you create can
                            be seen and used (Liked, Reviewed etc.) by other users of the site.
                        </p>

                        <ol>
                            <li>
                                When naming drives/routes, choose a sensible name for the benefit of other users.
                                If possible use the provided default name, or use the common/canonically known name
                                for that road. We reserve the right to change any name you come up with, so if you
                                upload the “The Applecross Pass” as “Jane Smith’s Awesome Ride Over the Mountains”, a
                                moderator will likely change it to its more commonly known name “The Applecross Pass”.
                            </li>
                            <li>
                                Ensure the road’s start\end points and any waypoints are set up correctly in Google Maps 
                                before uploading. We reserve the right to slightly modify a drive if it’s a famous/well
                                known road/route and we feel a section is missing or inaccurate for instance.
                            </li>
                            <li>
                                When creating a drive, the website will try detect if an identical drive already exists. But in case
                                identical or similar drives end up on the site, we reserve the right to merge them into a single drive,
                                along with any related drive data (reviews, images etc.). In this instance the user who created the first
                                drive will receive the credit as its creator.
                            </li>
                            <li>
                                Avoid creating drives that wouldn’t be considered “Spirited”, such as drives that entirely consist of
                                a straight motorway/freeway, drives around a housing estate, drives through a town center/shopping center
                                with 20-30 mph speed limits etc. We reserve the right to delete these types of drives.
                            </li>
                            <li>
                                Avoid deliberately spamming the site by creating an excessive number of drives that consist of
                                boring/uninteresting roads. We will delete these drives, and your account could be suspended.
                            </li>
                            <li>
                                Drives you create can be modified by yourself afterwards until another user starts to interact
                                with it (add reviews, images, likes etc.) where the drive will become locked. Please contact us
                                on <a rel="noreferrer" target="_blank" href={`https://twitter.com/` + this.props.config.social.twitterUsername}>Twitter</a> or <a href={`mailto:` + this.props.config.email}>email</a> us if you want to make changes.
                            </li>
                        </ol>

                        <p>
                            These rules are in place to ensure the quality of data on
                            the website remains at a very high standard.
                        </p>
                    </section>

                    <section className="sub-section">
                        <h2>Leaving Reviews</h2>

                        <p>We encourage you to leave reviews of your favourite roads. You can leave a rating, or a full written review.</p>

                        <ol>
                            <li>
                                Avoid reviews that would be considered spam, or contain advertising/marketing for other services/websites,
                                or contain scams. These reviews will be deleted, and your account might be suspended.
                            </li>
                            <li>
                                Avoid excessively short reviews of one, two or just a few words. A review that consists
                                of a single medium length sentence would be ok though.
                            </li>
                            <li>
                                Avoid offensive language.
                            </li>
                            <li>
                                Don’t promote your website, or social media accounts in the review text.
                                You can use your profile page to do this instead.
                            </li>
                            <li>
                                Avoid language that promotes excessive speeding or dangerous/reckless driving.
                                We’re here to have fun, but stay safe.
                            </li>
                        </ol>
                    </section>


                    <section className="sub-section">
                        <h2>Uploading Photos</h2>

                        <p>You can upload your images for drives on the website.</p>

                        <ol>
                            <li>
                                Images should be related to the drive in question. Its fine for pictures to have cars,
                                motorbikes, or people (with permission) in the shot.
                            </li>
                            <li>
                                Avoid images that would be considered spam, or contain advertising/marketing for other services/websites.
                                These images will be deleted, and your account might be suspended.
                            </li>
                            <li>
                                No pornographic content, these images will be deleted and your account might be suspended.
                            </li>
                            <li>
                                Absolutely NO illegal content, these images will be deleted and
                                your account will suspended and reported!
                            </li>
                        </ol>
                    </section>


                    <section className="sub-section">
                        <h2>Adding YouTube Videos</h2>

                        <p>
                            You can add a YouTube video for a drive. It doesn’t necessary have to be
                            your own video, but the video’s creator will get the credit.
                        </p>

                        <ol>
                            <li>
                                The video must be related to the drive in question. This could be a video from a camera attached
                                to a vehicle, or a head mounted POV (point of view) video, or documentary style video of someone
                                taking about the drive. See <a rel="noreferrer" target="_blank" href={`https://www.youtube.com/channel/` + this.props.config.social.youTubeChannelId}>Spirited Drive’s YouTube channel</a> for examples.
                            </li>
                            <li>
                                Videos of road trips, car/bike meet ups, or other events, are OK, as long as
                                the drive features in the video.
                            </li>
                            <li>
                                It's fine if a portion of the video includes marketing, advertising, or promotion content,
                                as long as the main theme of the video is related to the drive in question.
                            </li>
                            <li>
                                Videos that show or encourage excessive speeding, dangerous, or reckless driving, or any
                                driving that puts other road users at risk, are strongly discouraged and may be removed.
                            </li>
                        </ol>

                        <p>
                            Although Spirited Drive operates its own YouTube channel of driving videos, we don’t see other YouTubers
                            uploading similar videos as competition. We would encourage you to film your own driving videos, upload
                            them to YouTube, and feature them on this website.
                        </p>
                    </section>


                    <section className="sub-section">
                        <h2>Profile Page</h2>

                        <p>
                            We encourage you to fill out your profile page once you have joined the website.
                            Here you can upload a photo/avatar, include a bio, social media links, and a link to your own website.
                            You can also upload pictures of your car or bike.
                        </p>

                        <ol>
                            <li>
                                Normal rules apply for image uploads. No pornographic, explicit, or illegal
                                content. Ride photo should just be pictures of your car or bike.
                            </li>
                            <li>
                                Your website link must NOT be a link to any pornographic, explicit, or any scam
                                website. A link to your business/company website is fine though.
                            </li>
                        </ol>
                    </section>


                    <section className="sub-section">
                        <h2 className="sub-title">On Safe Driving</h2>

                        <p className="elevator-pitch">
                            Spirited driving doesn’t mean dangerous or irresponsible driving! It doesn’t mean
                            driving at excessive speeds and putting yourself and other roads users at risk.
                        </p>

                        <p>
                            By all means have fun, but please drive safely within your own abilities and within the limits
                            of your vehicle, the road, and local weather conditions. Please obey your local driving
                            laws and be respectful to other motorists, pedestrians, and road users.
                        </p>

                        <p>
                            If you want to test the limits of your vehicle and drive at excessive speeds,
                            then take your vehicle to a local race track.
                        </p>

                        <p>
                            Spirited Drive doesn’t accept any responsibility for any financial loss or injury
                            as the result of dangerous driving on the part of the user of this website. So please drive safe!
                        </p>

                        <p>Thank you!</p>
                    </section>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AboutTerms)