import './get.scss'

import React from 'react'
import ReactDOMServer from 'react-dom/server';
import { NavLink } from 'react-router-dom'

import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro, faChevronLeft, faChevronRight, faAngleDoubleDown, faChevronCircleDown, faUserCircle, faHeart as fullHeart, faMapSigns, faImage, faSync, faLink, faMessageQuote } from '@fortawesome/pro-solid-svg-icons'
import { faWikipediaW, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faHeart as emptyHeart, faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faLink as fadLink, faMapMarkedAlt as fadMapMarkedAlt } from '@fortawesome/pro-duotone-svg-icons'
import { faMap, faMapMarked } from '@fortawesome/pro-solid-svg-icons'
import { faTire } from '@fortawesome/pro-light-svg-icons';

import geo from '../../utils/geo';
import format from '../../utils/format';
import statusCode from '../../utils/apiStatusCodes'

import ReviewModal from '../../components/modals/Review'
import ReviewDetailModal from '../../components/modals/ReviewDetail'
import VideosModal from '../../components/modals/Videos'
import DriveImage from '../../components/modals/DriveImage'
import PopupImage from '../../components/PopupImage'
import ImageModal from '../../components/modals/Image';
import ExternalLinks from '../../components/modals/ExternalLinks'
import EditDriveModal from '../../components/modals/EditDrive'
import PlacePopup from '../../components/modals/Place'
import AccidentPopup from '../../components/modals/Accident'

import ElevationGradientInfo from '../../components/ElevationGradientInfo';
import TrafficInfo from '../../components/TrafficInfo'
import AccidentControls from '../../components/AccidentControls';

import pinGray from '../../images/pin-gray.png';
import drivePinStart from '../../images/pin-drive-start.png';
import drivePinEnd from '../../images/pin-drive-end.png';
import drivePinLoop from '../../images/pin-drive-loop.png';

import {
    faGasPump as placeGas,
    faChargingStation as placeCharging,
    faUtensils as placeFood,
    faBedAlt as placeHotel,
    faChessRook as placeAttraction
} from '@fortawesome/pro-solid-svg-icons';

import DriveInfo from '../../components/DriveInfo';

import Stars from '../../components/Stars';
import ScrollIndicator from '../../components/ScrollIndicator'

class Drive extends React.Component {
    static PopupDelay = 500;
    static ReviewRecordsPerPage = 6;
    static VideoRecordsPerPage = 10;
    static ImageRecordsPerPage = 6;

    static PolylineOptions = {
        strokeColor: '#00b0ff',
        strokeOpacity: 1,
        strokeWeight: 5
    };

    static PolylineBorderOptions = {
        strokeColor: '#1767d2',
        strokeOpacity: 1,
        strokeWeight: 9
    }

    static PolylineOptionsFaded = {
        strokeColor: '#003D9E',
        strokeOpacity: 0.5,
        strokeWeight: 3
    }

    constructor(props) {
        super(props);

        document.title = 'Drive Info - Spirited Drive';
        this.props.pageType.setHalf();
    }

    state = {
        isLoading: true,
        isNotFound: false,
        drive: null,
        reviews: {
            isLoading: false,
            totalRecords: 0,
            page: 1,
            records: [],
            hasMoreRecords: false,
            detail: null
        },
        videos: {
            isLoading: false,
            totalRecords: 0,
            page: 0,
            records: [],
            hasMoreRecords: false
        },
        images: {
            isLoading: false,
            totalRecords: 0,
            page: 0,
            records: [],
            hasMoreRecords: false
        },
        links: {
            isLoading: false,
            records: []
        },
        traffic: {
            isLoading: false,
            data: null,
            countPoints: []
        },
        youTubeCarousel: {
            showLeft: false,
            showRight: false
        },
        editReview: {
            visible: false,
            review: null,
            isLoading: false
        },
        editVideos: {
            visible: false,
            video: []
        },
        uploadImage: {
            visible: false,
            image: null,
            numAuthUserImages: 0
        },
        editDrive: {
            visible: false,
            drive: null,
            isLocked: false
        },
        editLinks: {
            visible: false,
            links: []
        },
        visibleTab: 'elevation',
        accidentRemoveMode: false,
        accidents: {
            isLoading: false,
            safetyRecord: null,
            safetyAverages: null,
        },
        showImage: null,
        togglingFavourite: false,
        settingHeroImageId: null,
        placeTypes: {
            isLoading: false,
            records: []
        }
    };

    authUser = null;

    map = null
    google = null
    mapEvents = [];
    polyline = null;
    polylineBorder = null;
    startMarker = null;
    endMarker = null;
    elevationMarker = null;
    placeTooltip = null;
    placeTooltipTimeout = null;
    markerDropIntervals = [];

    currentPlacePopup = null;

    accidents = {
        data: [],
        markers: [],
        heatMap: null,
        popup: null
    };

    traffic = {
        countPointMarkers: []
    };

    carouselObserver = null;
    carouselScrollers = [];
    tabsObserver = null;
    tabsState = [];
    tabControl = null;

    componentDidMount() {
        this.props.mapReady((map, google) => {
            this.map = map;
            this.google = google;

            this.map._enableControls();
            this.map.streetView.setVisible(false);

            if (this.state.drive) {
                this.updateMap(this.map, this.google, this.state.drive);
            }

            this.placeTooltip = new this.google.maps.InfoWindow({
                disableAutoPan: true,
                pixelOffset: {
                    height: -30,
                    width: 0
                }
            });

            this.placeTooltip.addListener('domready', () => {
                const dialogParent = this.placeTooltip.content.closest('.gm-style-iw[role="dialog"]');
                if (dialogParent) dialogParent.classList.add('custom-map-dialog');
            });
        });

        this.props.authUserStateReady(authUser => {
            this.authUser = authUser;
            this.fetchDrive(authUser);

            let qs = format.querystring(window.location.search);
            let urlSlug = this.props.match.params.id;

            if (qs.showImage && urlSlug) {
                let imageDetailUrl = this.props.config.apiUrl + '/v1' +
                    '/drives/' + encodeURIComponent(urlSlug) +
                    '/images/' + encodeURIComponent(qs.showImage);

                this.props.fetcher.get(imageDetailUrl).then(response => {
                    let record = response.record;
                    if (record) {
                        this.setState({ showImage: record });
                    }
                    else {
                        this.closeImage();
                    }
                });
            }
        });

        this.carouselObserver = new MutationObserver(() => {
            document.querySelectorAll('.drive-info-main.carousel:not([data-initialised="true"]) .scroller').forEach(scroller => {
                this.setCarouselState(scroller);

                scroller.addEventListener('scroll', () => {
                    this.setCarouselState(scroller);
                });

                let carousel = scroller.closest('.carousel');
                carousel.setAttribute('data-initialised', 'true');

                this.carouselScrollers.push(scroller);
            });
        });

        window.addEventListener('resize', this.handleResizeWindow);

        this.carouselObserver.observe(document.body, {
            subtree: true,
            childList: true
        });

        this.unlistenLocationChanges = this.props.history.listen((location, action) => {
            let qs = format.querystring(location);
            if (!this.state.reviews.detail && qs.showReview) {
                this.fetchReviewDetail(this.authUser, this.state.drive.id, qs.showReview);
            }
            else if (this.state.reviews.detail && !qs.showReview) {
                let reviews = { ...this.state.reviews };
                reviews.detail = null;
                this.setState({ reviews: reviews });
            }
        });

        // update places
        let placeTypes = { ...this.state.placeTypes };
        placeTypes.isLoading = true;
        this.setState({ placeTypes: placeTypes });

        let placeTypesUrl = this.props.config.apiUrl + '/v1/placeTypes';

        this.props.fetcher.get(placeTypesUrl).then(async response => {
            let placeTypes = { ...this.state.placeTypes };
            placeTypes.isLoading = false;

            for (let placeType of response.records) {
                const pinIcon = (await require(`../../images/pin-place-${placeType.icon}.png`));

                let icon = null;

                switch (placeType.icon) {
                    case 'gas-pump':
                        icon = placeGas;
                        break;
                    case 'charging-station':
                        icon = placeCharging;
                        break;
                    case 'utensils':
                        icon = placeFood;
                        break;
                    case 'bed-alt':
                        icon = placeHotel;
                        break;
                    case 'chess-rook':
                        icon = placeAttraction;
                        break;
                    default:
                        icon = faMap;
                        break;
                }

                placeTypes.records.push({
                    ...placeType,
                    icon: icon || faMapMarked,
                    pinIcon: pinIcon,
                    isLoading: false,
                    places: null,
                    showPlaces: false
                });
            }

            this.setState({ placeTypes: placeTypes });
            return;
        });

        this.tabsObserver = new MutationObserver((mutationsList, observer) => {
            this.tabControl = document.querySelector('.tab-control')
            if (!this.tabControl) return;

            observer.disconnect();

            let tabsState = [];

            const tabs = this.tabControl.querySelectorAll('.tab-list .tab-button');
            tabs.forEach((tab, i) => {
                const bounds = tab.getBoundingClientRect();

                tabsState[i] = {
                    left: tab.offsetLeft,
                    top: tab.offsetTop,
                    width: bounds.width,
                    height: bounds.height
                };
            });

            const tabSections = this.tabControl.querySelectorAll('.tab-content');
            tabSections.forEach((section, i) => {
                const title = section.querySelector('.tab-title');
                if (!title) return;

                const state = tabsState[i];
                title.style['top'] = (state.top + 3) + 'px';
                title.style['left'] = (state.left + 2) + 'px';
                title.style['width'] = (state.width - 4) + 'px';
                title.style['height'] = (state.height - 4) + 'px';
            });

            this.tabsState = tabsState;
        });

        this.tabsObserver.observe(document.body, { childList: true, subtree: true });
    }

    showTab = (tab) => {
        this.setState({ visibleTab: tab });
    }

    handleTabKeyPress = (e) => {
        e.preventDefault();

        const button = e.target;
        const siblingButtons = button.parentNode.querySelectorAll('.tab-button');

        let activeTab = null;

        switch (e.key) {
            case 'ArrowLeft':
                activeTab = button.previousElementSibling;
                if (!activeTab) {
                    activeTab = siblingButtons[siblingButtons.length - 1];
                }
                break;
            case 'ArrowRight':
                activeTab = button.nextElementSibling;
                if (!activeTab) {
                    activeTab = siblingButtons[0];
                }
                break;
            case 'Home':
                activeTab = siblingButtons[0];
                break;
            case 'End':
                activeTab = siblingButtons[siblingButtons.length - 1];
                break;
            default:
                break;
        }

        if (activeTab) {
            siblingButtons.forEach(b => b.setAttribute('tabIndex', '-1'));

            activeTab.removeAttribute('tabindex');
            activeTab.focus();
            activeTab.click();
        }
    }

    setCarouselState = (scroller) => {
        let scrollLeft = Math.abs(scroller.scrollLeft);
        let scrollWidth = scroller.scrollWidth;
        let boxWidth = scroller.getBoundingClientRect().width;

        let showLeft = false;
        let showRight = false;

        if (scrollLeft > 0) {
            showLeft = true;
        }

        if (scrollLeft + boxWidth < (scrollWidth - 1)) {
            showRight = true;
        }

        this.setState({
            youTubeCarousel: {
                showLeft: showLeft,
                showRight: showRight
            }
        });
    }

    handleResizeWindow = () => {
        this.carouselScrollers.forEach(scroller => {
            this.setCarouselState(scroller);
        });

        if (this.tabControl) {
            let tabsState = [];
            let changeTabLayout = false;

            const tabs = this.tabControl.querySelectorAll('.tab-list .tab-button');
            tabs.forEach((tab, i) => {
                const bounds = tab.getBoundingClientRect();

                const tabState = {
                    left: tab.offsetLeft,
                    top: tab.offsetTop,
                    width: bounds.width,
                    height: bounds.height
                };
                tabsState[i] = tabState;

                const currentTabState = this.tabsState[i];

                const hasChanged = !currentTabState ||
                    currentTabState.left !== tabState.left ||
                    currentTabState.top !== tabState.top ||
                    currentTabState.width !== tabState.width ||
                    currentTabState.height !== tabState.height;

                if (hasChanged) changeTabLayout = true;
            });

            if (changeTabLayout) {
                const tabSections = this.tabControl.querySelectorAll('.tab-content');
                tabSections.forEach((section, i) => {
                    const title = section.querySelector('.tab-title');
                    if (!title) return;

                    const state = tabsState[i];
                    title.style['top'] = (state.top + 3) + 'px';
                    title.style['left'] = (state.left + 2) + 'px';
                    title.style['width'] = (state.width - 4) + 'px';
                    title.style['height'] = (state.height - 4) + 'px';
                });

                this.tabsState = tabsState;
            }
        }
    }

    fetchDrive = (authUser) => {
        let urlSlug = this.props.match.params.id;
        if (!urlSlug) {
            this.handleNotFound();
            return;
        }

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(urlSlug);

        return this.props.fetcher.get(url).then(response => {
            if (response.code === 44) {
                this.handleNotFound();
                return;
            }

            if (response.code !== 0) {
                this.setState(() => { throw new Error(`Unexpected response code: ${response.code}`); });
                return;
            }

            let record = response.record;

            // update the URL if the drive name has changed
            if (record.slug !== urlSlug) {
                this.props.history.replace('/drives/' + encodeURIComponent(record.slug));
            }

            // seo details
            document.title = record.name + ' - Spirited Drive';
            let description = '';
            let openGraphDescription = '';
            
            if (record.description) {
                description += format.truncate(record.description, 120, '...')
                openGraphDescription += format.truncate(record.description, 300, '...')
            }
            else {
                description = record.name;

                if (record.isLoop) {
                    description += ' starts and ends at ' + record.startAddress + ', ' + record.generalLocation;
                }
                else {
                    description += ' starts at ' + record.startAddress + ', and ends at ' + record.endAddress + ', ' + record.generalLocation;
                }

                openGraphDescription = description;
            }

            description = description.trim();
            openGraphDescription = openGraphDescription.trim();

            if (record.user) {
                if (!description.match(/.+\.$/)) {
                    description += '.'
                }

                if (!openGraphDescription.match(/.+\.$/)) {
                    openGraphDescription += '.'
                }

                description += ' Submitted by ' + record.user.fullname;
                openGraphDescription += ' Submitted by ' + record.user.fullname;
            }

            document.querySelector('head > meta[name="description"]').setAttribute('content', description)

            let createdDate = new Date(Date.parse(record.created));
            let updatedDate = record.lastUpdated ? new Date(Date.parse(record.lastUpdated)) : null;

            // open graph
            this.props.populateOpenGraph({
                title: record.name,
                description: openGraphDescription,
                url: window.location.origin + '/drives/' + encodeURIComponent(record.slug),
                imageUrl: record.primaryImage ? record.primaryImage.sizes.large : null,
                imageAlt: record.primaryImage ? record.primaryImage.caption : null,
                type: 'article',
                publishedTime: createdDate.toISOString(),
                modifiedTime: updatedDate ? updatedDate.toISOString() : null,
                author: record.user ? record.user.fullname : null
            });

            // set state
            let drive = {
                id: record.id,
                name: record.name,
                description: record.description,
                travelTime: record.travelTime,
                distance: record.distance,
                averageSpeed: record.averageSpeed,
                elevation: record.elevation,
                gradient: record.gradient,
                encodedPolyline: record.encodedPolyline,
                waypoints: record.waypoints,
                bounds: record.bounds,
                startAddress: record.startAddress,
                endAddress: record.endAddress,
                generalLocation: record.generalLocation,
                roadNames: record.roadNames,
                roadTypes: record.roadTypes,
                averageDailyTraffic: record.averageDailyTraffic,
                isLoop: record.isLoop,
                isLocked: record.isLocked,
                numFavourites: record.numFavourites,
                hasAuthUserFavourited: false,
                numRatings: record.numRatings,
                averageRating: null,
                created: record.created,
                primaryImage: record.primaryImage,
                placesAreIndexed: record.placesAreIndexed,
                placesSupported: record.placesSupported,
                supportsAccidentData: record.supportsAccidentData,
                supportsTrafficData: record.supportsTrafficData,
                authUserInfo: record.authUserInfo,
                createdBy: record.user ? {
                    id: record.user.id,
                    username: record.user.username
                } : null
            };

            let reviews = { ...this.state.reviews };
            reviews.records = [];
            reviews.totalRecords = record.numReviews;
            reviews.hasMoreRecords = record.numReviews > 0;
            reviews.page = 0;

            let videos = { ...this.state.videos };
            videos.records = [];
            videos.totalRecords = record.numVideos;
            videos.hasMoreRecords = record.numVideos > 0;
            videos.page = 0;

            let images = { ...this.state.images };
            images.records = [];
            images.totalRecords = record.numImages;
            images.hasMoreRecords = record.numImages > 0;
            images.page = 0;

            if (record.averageRating) {
                drive.averageRating = parseFloat(((5 / 10) * record.averageRating).toFixed(1));
            }

            let start = record.waypoints[0];
            let end = record.waypoints[record.waypoints.length - 1];
            let midWaypoints = [];

            if (record.waypoints.length > 2) {
                midWaypoints = record.waypoints.slice(1, record.waypoints.length - 1)
            }

            let startString = start.lat + ',' + start.lng;
            let endString = end.lat + ',' + end.lng;

            let midWaypointsString = '';
            midWaypoints.forEach((point, i) => {
                let pointAsString = point.lat + ',' + point.lng;
                midWaypointsString += pointAsString;

                let isLast = i === midWaypoints.length - 1;
                if (!isLast) {
                    midWaypointsString += '|';
                }
            })

            let googleMapsUrl = 'https://www.google.com/maps/dir/?' +
                'api=1&' +
                'origin=' + startString + '&' +
                'destination=' + endString + '&' +
                'travelmode=driving&' +
                'waypoints=' + encodeURIComponent(midWaypointsString);

            drive.googleMapsUrl = googleMapsUrl;

            let directionsUrl = 'https://www.google.com/maps/dir/?' +
                'api=1&' +
                'destination=' + endString + '&' +
                'travelmode=driving&' +
                'waypoints=' + encodeURIComponent(midWaypointsString);

            drive.directionsUrl = directionsUrl;
            
            this.setState({
                isLoading: false,
                drive: drive,
                reviews: reviews,
                videos: videos,
                images: images
            }, () => {
                if (this.map) {
                    this.updateMap(this.map, this.google, drive);
                }

                this.fetchReviews();
                this.fetchVideos();
                this.fetchImages();
                this.fetchLinks();

                if (this.state.drive.supportsTrafficData) {
                    this.fetchTrafficData();
                }

                if (this.state.drive.supportsAccidentData) {
                    this.fetchSafetyData();
                }

                let qs = format.querystring(window.location.search);
                if (qs.showReview) {
                    this.fetchReviewDetail(authUser, drive.id, qs.showReview);
                }
            });
        });
    }

    handleNotFound = () => {
        this.setState({
            isLoading: false,
            isNotFound: true
        });

        document.title = '404: Drive Not Found - Spirited Drive'
        this.props.pageType.set404();
    }

    updateMap = (map, google, drive) => {
        if (!this.polyline || !this.polylineBorder) {
            const path = google.maps.geometry.encoding.decodePath(drive.encodedPolyline);

            this.polylineBorder = new google.maps.Polyline({
                path: path,
                clickable: false,
                geodesic: true,
                ...Drive.PolylineBorderOptions
            });

            this.polyline = new google.maps.Polyline({
                path: path,
                clickable: false,
                geodesic: true,
                ...Drive.PolylineOptions
            });

            // path.forEach(point => {
            //     new google.maps.Marker({
            //         map: map,
            //         zIndex: 1,
            //         position: {
            //             lat: point.lat(),
            //             lng: point.lng()
            //         },
            //         icon: {
            //             url:  pinGray ,
            //             size: new this.google.maps.Size(20, 35),
            //             scaledSize: new this.google.maps.Size(20, 35)
            //         }
            //     });
            // });
        }

        this.polylineBorder.setMap(map);
        this.polyline.setMap(map);

        let expandedBounds = window.innerWidth <= 700 ? drive.bounds : geo.getBoundsForSplitView(drive.bounds, { side: 'left' });
        let bounds = new google.maps.LatLngBounds(expandedBounds.sw, expandedBounds.ne);
        map.fitBounds(bounds);

        let start = drive.waypoints[0];
        let end = drive.waypoints[drive.waypoints.length - 1];

        this.startMarker = new google.maps.Marker({
            map: map,
            zIndex: 1,
            position: {
                lat: start.lat,
                lng: start.lng
            },
            icon: {
                url: drive.isLoop ? drivePinLoop : drivePinStart,
                size: new this.google.maps.Size(26, 34),
                scaledSize: new this.google.maps.Size(26, 34)
            }
        });

        if (!drive.isLoop) {
            this.endMarker = new google.maps.Marker({
                map: map,
                zIndex: 1,
                position: {
                    lat: end.lat,
                    lng: end.lng
                },
                icon: {
                    url: drivePinEnd,
                    size: new this.google.maps.Size(26, 34),
                    scaledSize: new this.google.maps.Size(26, 34)
                }
            });
        }

        this.mapEvents.push(map.addListener('click', () => {
            if (this.currentPlacePopup) {
                this.map._enableControls();
                this.currentPlacePopup.close();
                this.currentPlacePopup = null;
            }

            if (this.accidents.popup) {
                this.map._enableControls();
                this.accidents.popup.close();
                this.accidents.popup = null;
            }
        }));

        this.mapEvents.push(map.addListener('zoom_changed', () => {
            this.setState({ currentZoom: map.getZoom() });

            if (this.accidents.heatMap) {
                this.accidents.heatMap.setOptions({
                    radius: geo.getHeatmapRadius(this.map, this.google)
                });
            }
        }));
    }

    handlePlaceTypeClick = (placeTypeId) => {
        let placeTypes = [...this.state.placeTypes.records];
        let placeType = placeTypes.find(pt => pt.id === placeTypeId);

        if (placeType.isLoading) return;

        if (!placeType.places) {
            placeType.isLoading = true;
            this.setState({ placeTypes: {
                ...this.state.placeTypes,
                records: placeTypes
            }});

            let placesUrl = this.props.config.apiUrl + '/v1/places/?' +
                'forDriveId=' + encodeURIComponent(this.state.drive.id) + '&' +
                'typeId=' + encodeURIComponent(placeType.id);

            this.props.fetcher.get(placesUrl).then(response => {
                let placeTypes = [...this.state.placeTypes.records];
                let placeType = placeTypes.find(pt => pt.id === placeTypeId);
                placeType.places = [];

                response.records.forEach(place => {
                    let marker = new this.google.maps.Marker({
                        position: {
                            lat: place.location.lat,
                            lng: place.location.lng
                        },
                        icon: {
                            url: placeType.pinIcon,
                            size: new this.google.maps.Size(30, 40),
                            scaledSize: new this.google.maps.Size(30, 40),
                            zIndex: 999
                        },
                        zIndex: 1,
                        animation: this.google.maps.Animation.DROP
                    });

                    marker.addListener('click', e => {
                        if (this.currentPlacePopup) {
                            this.currentPlacePopup.close();
                            this.currentPlacePopup = null;
                        }

                        let placeUrl = this.props.config.apiUrl + '/v1/places/' + encodeURIComponent(place.id);
                        this.props.fetcher.get(placeUrl).then(response => {
                            let placeDetail = response.record;

                            let infoWindow = new this.google.maps.InfoWindow({
                                minWidth: 300,
                                maxWidth: 400,
                                minHeight: 200
                            });

                            infoWindow.setPosition(e.latLng);

                            let content = ReactDOMServer.renderToString(<PlacePopup place={placeDetail} placeType={placeType}></PlacePopup>)
                            infoWindow.setContent(content);

                            infoWindow.open({
                                map: this.map,
                                shouldFocus: true
                            });

                            this.map._disableControls();

                            infoWindow.addListener('domready', () => {
                                PlacePopup.init(placeDetail, () => {
                                    if (this.currentPlacePopup) {
                                        this.map._enableControls();

                                        this.currentPlacePopup.close();
                                        this.currentPlacePopup = null;
                                    }
                                });
                            });

                            this.currentPlacePopup = infoWindow;

                            this.placeTooltip.close();
                            clearTimeout(this.placeTooltipTimeout);
                        })
                    });

                    marker.addListener('mouseover', e => {
                        this.placeTooltipTimeout = setTimeout(() => {
                            this.placeTooltip.setPosition(place.location);

                            const placeNameNode = document.createElement('span');
                            placeNameNode.classList.add('drive-name-hint-popup')
                            placeNameNode.textContent = place.name
                            this.placeTooltip.setContent(placeNameNode);

                            this.placeTooltip.open({
                                map: this.map,
                                shouldFocus: false
                            });
                        }, Drive.PopupDelay);
                    });

                    marker.addListener('mouseout', () => {
                        this.placeTooltip.close();
                        clearTimeout(this.placeTooltipTimeout);
                    });

                    placeType.places.push({
                        place: place,
                        marker: marker,
                        popup: null
                    });
                });

                placeType.isLoading = false;
                placeType.showPlaces = true;

                this.setState({ placeTypes: {
                    ...this.state.placeTypes,
                    records: placeTypes
                }});

                this.animateMarkers(placeType.places.map(p => p.marker), { duration: 1000 });
            });

            return;
        }

        if (placeType.showPlaces) {
            placeType.places.forEach(place => {
                place.marker.setMap(null)
            });

            placeType.showPlaces = false;
        }
        else {
            placeType.places.forEach(place => {
                place.marker.setMap(this.map)
            });

            placeType.showPlaces = true;
        }

        this.setState({ placeTypes: {
            ...this.state.placeTypes,
            records: placeTypes
        }});
    }

    fetchReviews = (options) => {
        options = options || {};

        let reviews = { ...this.state.reviews };

        if (!reviews.hasMoreRecords || reviews.isLoading) return;

        reviews.isLoading = true;
        this.setState({ reviews: reviews });

        const initialRecordsPerPage = 2;

        let reviewsUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/reviews';

        let numRecordsBeingShown = reviews.records.length;

        let firstFetch = numRecordsBeingShown === 0;
        if (firstFetch) {
            reviewsUrl += `?page=1&recordsPerPage=${initialRecordsPerPage}&writtenOnly=true`;
        }
        else {
            reviews.page = numRecordsBeingShown === initialRecordsPerPage ? 1 : reviews.page + 1;
            reviewsUrl += `?page=${reviews.page}&recordsPerPage=${Drive.ReviewRecordsPerPage}&writtenOnly=true`;
        }

        if (options.includeMetaData) {
            reviewsUrl += '&includeMetaData=true';
        }

        this.props.fetcher.get(reviewsUrl).then(response => {
            let totalRecords = response.paging.totalRecords;

            let reviews = { ...this.state.reviews };

            let records = reviews.records;
            records.push(...response.records
                .filter(review => {
                    return !records.some(r => r.id === review.id);
                })
                .map(review => {
                    return {
                        id: review.id,
                        text: review.text,
                        rating: review.rating ? parseFloat(((5 / 10) * review.rating).toFixed(1)) : null,
                        author: review.author
                    };
                }));

            reviews.isLoading = false;
            reviews.totalRecords = totalRecords;
            reviews.records = records;
            reviews.hasMoreRecords = totalRecords > records.length;

            if (options.includeMetaData && response.metaData) {
                let averageRating = response.metaData.averageRating;

                let drive = { ...this.state.drive }
                drive.averageRating = averageRating ? averageRating / 2 : null;
                drive.numRatings = response.metaData.numRatings || 0;
                this.setState({ drive: drive })
            }

            this.setState({ reviews: reviews })
        });
    }

    fetchLinks = () => {
        let links = { ...this.state.links };
        if (links.isLoading) return;

        links.isLoading = true;
        this.setState({ links: links });

        let linksUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/links';
        this.props.fetcher.get(linksUrl).then(response => {
            let links = { ...this.state.links };
            links.records = [];

            links.records.push({
                id: null,
                name: 'View in Google Maps',
                url: this.state.drive.googleMapsUrl,
                sortIndex: 0
            });

            links.records.push(...response.records);

            links.records.forEach(link => {
                if (link.url.includes('wikipedia.org')) {
                    link.icon = faWikipediaW;
                }
                else if (link.url.includes('google.com/maps')) {
                    link.icon = fadMapMarkedAlt
                }
                else {
                    link.icon = fadLink
                }
            });

            links.isLoading = false;
            this.setState({ links: links });
        });
    }

    fetchTrafficData = () => {
        let trafficState = {...this.state.traffic};
        if (trafficState.isLoading) return;

        trafficState.isLoading = true;
        this.setState({ traffic: trafficState });

        const trafficUrl = this.props.config.apiUrl + '/v1/traffic/' + this.state.drive.id;
        this.props.fetcher.get(trafficUrl)
            .then(response => {
                const trafficDataRecords = response.record;

                if (this.authUser && this.authUser.isAdmin) {
                    const countPointsUrl = this.props.config.apiUrl + '/v1/traffic/' + this.state.drive.id + '/countPoints';
                    this.props.fetcher.get(countPointsUrl)
                        .then(response => {
                            let trafficState = {...this.state.traffic};
                            trafficState.isLoading = false;
                            trafficState.data = trafficDataRecords;
                            trafficState.countPoints = response.records;

                            this.setState({ traffic: trafficState });
                        });
                }
                else {
                    let trafficState = {...this.state.traffic};
                    trafficState.isLoading = false;
                    trafficState.data = trafficDataRecords;

                    this.setState({ traffic: trafficState });
                }
            });
    }

    fetchSafetyData = () => {
        const state = {...this.state.accidents};
        state.isLoading = true;
        this.setState({ accidents: state });

        const url = this.props.config.apiUrl + '/v1/accidents?' +
            'driveId=' + encodeURIComponent(this.state.drive.id) + '&' +
            'safetyOnly=true';

        return this.props.fetcher.get(url)
            .then(response => {
                const state = {...this.state.accidents};
                state.isLoading = false;
                state.safetyRecord = response.safetyRecord;
                state.safetyAverages = response.safetyAverages;

                this.setState({ accidents: state });
            });
    }

    toggleReviewModal = () => {
        // hide modal
        if (this.state.editReview.visible) {
            this.setState({ editReview: { visible: false } });
            return;
        }

        if (!this.authUser) {
            let redirectUrl = this.props.location.pathname + this.props.location.search;
            this.props.history.push('/account/register?redirectUrl=' +  encodeURIComponent(redirectUrl));
            return;
        }

        let reviewId = (this.state.drive.authUserInfo || {}).reviewId;

        // creating new Review
        if (!reviewId) {
            this.setState({
                editReview: {
                    visible: true,
                    review: null
                }
            });

            return;
        }

        if (this.state.editReview.isLoading) return;
        this.setState({
            editReview: {
                ...this.state.editReview,
                isLoading: true
            }
        });

        // or editing existing Review
        let driveId = this.state.drive.id;
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/reviews/' + encodeURIComponent(reviewId);

        this.props.fetcher.get(url).then(response => {
            this.setState({
                editReview: {
                    visible: true,
                    review: response.record,
                    isLoading: false
                }
            });
        });
    }

    saveReview = (review) => {
        let driveId = this.state.drive.id;
        let reviewId = this.state.editReview.review ? this.state.editReview.review.id : null

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/reviews';

        if (reviewId) {
            url += '/' + encodeURIComponent(reviewId);
        }

        let fetcher = reviewId ? this.props.fetcher.put : this.props.fetcher.post;

        fetcher(url, {
            text: review.text,
            rating: review.rating
        })
        .then(response => {
            if (!reviewId) {
                reviewId = response.id
            }

            this.setState({ editReview: { visible: false, review: null } });
            
            let reviews = { ...this.state.reviews };
            reviews.page = 1;
            reviews.records = [];
            reviews.hasMoreRecords = true;

            // new text review so increment record count
            if (reviewId && review.text) {
                reviews.totalRecords += 1;
            }

            let drive = { ...this.state.drive };
            if (drive.authUserInfo) {
                drive.authUserInfo.reviewId = reviewId;
            }

            this.setState({ 
                drive: drive,
                reviews: reviews
            });

            this.fetchReviews({ includeMetaData: true });
        });
    }

    deleteReview = (review) => {
        let driveId = this.state.drive.id;
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/reviews/' + encodeURIComponent(review.id);

        this.props.fetcher.delete(url).then(() => {
            this.setState({ editReview: { visible: false, review: null } });
            
            let reviews = { ...this.state.reviews };
            reviews.page = 1;
            reviews.totalRecords -= 1;
            reviews.records = [];
            reviews.hasMoreRecords = true;

            let drive = { ...this.state.drive };
            if (drive.authUserInfo) {
                drive.authUserInfo.reviewId = null;
            }

            this.setState({
                drive: drive,
                reviews: reviews
            });

            this.fetchReviews({ includeMetaData: true });
        });
    }

    toggleEditLinks = () => {
        // hide modal
        if (this.state.editLinks.visible) {
            this.setState({ editLinks: { visible: false } });
            return;
        }

        let linksUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/links';
        this.props.fetcher.get(linksUrl).then(response => {
            let links = response.records.map(link => {
                return {
                    id: link.id,
                    name: link.name,
                    url: link.url,
                    isReadOnly: false,
                    sortIndex: link.sortIndex
                };
            });

            links.unshift({
                id: -1,
                name: 'View in Google Maps',
                url: this.state.drive.googleMapsUrl,
                isReadOnly: true,
                sortIndex: -1
            });

            this.setState({
                editLinks: {
                    visible: true,
                    links: links
                }
            });
        });
    }

    saveLinks = async (links) => {
        let editableLinks = links.filter(l => !l.isReadOnly);
        editableLinks.forEach((link, i) => {
            link.sortIndex = i;
        });

        let linksUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/links/';
        let linksToDelete = editableLinks.filter(l => l.isDeleted);

        await Promise.all(linksToDelete.map(link => {
            let deleteUrl = linksUrl + encodeURIComponent(link.id);
            return this.props.fetcher.delete(deleteUrl);
        }));

        let linksToUpdate = editableLinks.filter(l => !l.isDeleted);

        this.props.fetcher.get(linksUrl).then(async response => {
            let existingLinks = response.records;

            let httpPromises = [];
            
            linksToUpdate.forEach(link => {
                let data = {
                    name: link.name,
                    url: link.url,
                    sortIndex: link.sortIndex
                }

                let existingLink = existingLinks.find(l => l.id === link.id);
                if (existingLink) {
                    // update
                    let hasChanged = existingLink.name !== link.name ||
                        existingLink.url !== link.url ||
                        existingLink.sortIndex !== link.sortIndex;

                    if (hasChanged) {
                        let updateUrl = linksUrl + encodeURIComponent(link.id);
                        httpPromises.push(this.props.fetcher.put(updateUrl, data));
                    }
                }
                else {
                    // create
                    httpPromises.push(this.props.fetcher.post(linksUrl, data));
                }
            });

            await Promise.all(httpPromises);
            
            this.setState({ editLinks: { visible: false } });
            this.fetchLinks();
        });
    }

    fetchVideos = () => {
        let videos = { ...this.state.videos };

        if (!videos.hasMoreRecords || videos.isLoading) return;

        videos.isLoading = true;
        this.setState({ videos: videos });

        let page = videos.page + 1;
        let videosUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/videos' +
            `?page=${page}&recordsPerPage=${Drive.VideoRecordsPerPage}`;

        this.props.fetcher.get(videosUrl).then(response => {
            videos.isLoading = false;
            videos.totalRecords = response.paging.totalRecords;
            videos.records.push(...response.records);
            videos.hasMoreRecords = videos.totalRecords > videos.records.length;
            videos.page = response.paging.page;

            this.setState({ videos: videos })
        });
    }

    toggleVideoModal = () => {
        // hide modal
        if (this.state.editVideos.visible) {
            this.setState({ editVideos: { visible: false } });
            return;
        }

        if (!this.authUser) {
            let redirectUrl = this.props.location.pathname + this.props.location.search;
            this.props.history.push('/account/register?redirectUrl=' +  encodeURIComponent(redirectUrl));
            return;
        }

        this.fetchAuthUsersVideos()
            .then(response => {
                this.setState({
                    editVideos: {
                        visible: true,
                        videos: response.records
                    }
                });
            });
    }

    saveVideos = (newVideos) => {
        this.fetchAuthUsersVideos()
            .then(response => {
                let currentVideos = response.records;

                let videosToAdd = newVideos.filter(newVideo => !currentVideos.some(v => v.youTubeId === newVideo.youTubeId));
                let videosToDelete = currentVideos.filter(currentVideo => !newVideos.some(v => v.youTubeId === currentVideo.youTubeId));

                if (videosToAdd.length === 0 && videosToDelete.length === 0) {
                    this.setState({ editVideos: { visible: false, videos: null } });
                    return;
                }

                let driveId = this.state.drive.id;
                let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/videos/';

                videosToAdd.forEach(video => {
                    this.props.fetcher.post(url, {
                        youTubeVideoID: video.youTubeId
                    })
                    .then(response => {
                        video._complete = true;
                    });
                });

                videosToDelete.forEach(video => {
                    let deleteUrl = url + encodeURIComponent(video.id)

                    this.props.fetcher.delete(deleteUrl).then(() => {
                        video._complete = true;
                    });
                });

                let checkComplete = setInterval(() => {
                    if (videosToAdd.every(v => v._complete) && videosToDelete.every(v => v._complete)) {
                        this.setState({
                            editVideos: {
                                visible: false,
                                videos: null
                            }
                        });

                        let changeInVideoCount = videosToAdd.length - videosToDelete.length;

                        let videos = { ...this.state.videos };
                        videos.page = 0;
                        videos.totalRecords += changeInVideoCount;
                        videos.records = [];
                        videos.hasMoreRecords = true;

                        let drive = { ...this.state.drive };
                        if (drive.authUserInfo) {
                            let remainingVideos = (currentVideos.length + videosToAdd.length) - videosToDelete.length;
                            drive.authUserInfo.hasVideos = remainingVideos > 0;
                        }

                        this.setState({
                            drive: drive,
                            videos: videos
                        });

                        this.fetchVideos();

                        clearInterval(checkComplete);
                    }
                }, 100);
            });
    }

    fetchAuthUsersVideos = () => {
        let driveId = this.state.drive.id;
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/videos/?fromUserId=' + encodeURIComponent(this.authUser.id);

        return this.props.fetcher.get(url);
    }

    fetchImages = () => {
        let images = { ...this.state.images };

        if (!images.hasMoreRecords || images.isLoading) return;

        images.isLoading = true;
        this.setState({ images: images });

        let page = images.page + 1;
        let imagesUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) + '/images' +
            `?page=${page}&recordsPerPage=${Drive.ImageRecordsPerPage}`;

        this.props.fetcher.get(imagesUrl).then(response => {
            images.isLoading = false;
            images.totalRecords = response.paging.totalRecords;
            images.records.push(...response.records);
            images.hasMoreRecords = images.totalRecords > images.records.length;
            images.page = response.paging.page;

            this.setState({ images: images })
        });
    }

    toggleImagesModal = (image) => {
        // hide modal
        if (this.state.uploadImage.visible) {
            this.setState({
                uploadImage: {
                    ...this.state.uploadImage,
                    visible: false,
                    image: null
                }
            });
            return;
        }

        if (!this.authUser) {
            let redirectUrl = this.props.location.pathname + this.props.location.search;
            this.props.history.push('/account/register?redirectUrl=' +  encodeURIComponent(redirectUrl));
            return;
        }

        this.fetchAuthUsersImages()
            .then(response => {
                this.setState({
                    uploadImage: {
                        ...this.state.uploadImage,
                        visible: true,
                        image: image,
                        numAuthUserImages: response.records.length
                    }
                });
            });
    }

    saveImage = (image) => {
        let driveId = this.state.drive.id;  
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/images/';

        if (image.id) {
            url += encodeURIComponent(image.id);

            this.props.fetcher.put(url, {
                caption: image.caption || null,
                source: image.source || null,
                sourceUrl: image.sourceUrl || null
            })
            .then(() => {
                let stateImage = this.state.images.records.find(i => i.id === image.id);
                if (stateImage) {
                    stateImage.caption = image.caption;
                    stateImage.source = image.source;
                    stateImage.sourceUrl = image.sourceUrl;
                }

                this.setState({
                    uploadImage: {
                        ...this.state.uploadImage,
                        visible: false,
                        image: null
                    }
                });
            });
        }
        else {
            this.props.fetcher.post(url, {
                imageData: image.url,
                caption: image.caption || null,
                source: image.source || null,
                sourceUrl: image.sourceUrl || null
            })
            .then(() => {
                this.setState({
                    uploadImage: {
                        ...this.state.uploadImage,
                        visible: false,
                        image: null
                    }
                });

                this.props.trackEvent('PhotoUploaded', this.state.uploadImage.numAuthUserImages)
    
                let images = { ...this.state.images };
                images.page = 0;
                images.totalRecords += 1;
                images.records = [];
                images.hasMoreRecords = true;
    
                let drive = { ...this.state.drive };
                if (drive.authUserInfo) {
                    drive.authUserInfo.hasImages = true;
                }
    
                this.setState({
                    ...this.state.uploadImage,
                    drive: drive,
                    images: images
                });
    
                this.fetchImages();
            });
        }
    }

    deleteImage = (imageId) => {
        let driveId = this.state.drive.id;
        let deleteUrl = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/images/' + encodeURIComponent(imageId);

        this.props.fetcher.delete(deleteUrl).then(() => {
            this.setState({
                uploadImage: {
                    ...this.state.uploadImage,
                    visible: false,
                    image: null
                }
            });

            let images = { ...this.state.images };
            images.page = 0;
            images.totalRecords -= 1;
            images.records = [];
            images.hasMoreRecords = true;

            this.setState({
                images: images
            });

            this.fetchImages();
        });
    }

    showImage = (imageId) => {
        let pathname = window.location.pathname;
        let qs = format.querystring(window.location.search);

        let includes = {
            showImage: imageId
        };

        let showModalImageUrl = pathname + qs.toString(includes);
        this.props.history.push(showModalImageUrl);
    }

    closeImage = () => {
        let pathname = window.location.pathname;
        let qs = format.querystring(window.location.search);
        delete qs.showImage

        let urlSansImage = pathname + qs.toString();
        this.props.history.push(urlSansImage);

        this.setState({ showImage: null });
    }

    formatImageCaption = (caption) => {
        caption = (caption || '').trim();
        if (!caption) return '';

        if (caption.length > 70) {
            return caption.substring(0, 70) + '...';
        }
        else {
            return caption + (caption.endsWith('.') ? '' : '.');
        }
    }

    fetchAuthUsersImages = () => {
        let driveId = this.state.drive.id;
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(driveId) + '/images/?fromUserId=' + encodeURIComponent(this.authUser.id);

        return this.props.fetcher.get(url);
    }

    toggleFavourite = () => {
        if (!this.authUser) {
            let redirectUrl = this.props.location.pathname + this.props.location.search;
            this.props.history.push('/account/register?redirectUrl=' +  encodeURIComponent(redirectUrl));
            return;
        }

        if (this.state.togglingFavourite) return;
        this.setState({ togglingFavourite: true });

        let url = this.props.config.apiUrl + '/v1/users/' + encodeURIComponent(this.authUser.id) + '/favourites/';

        if (this.state.drive.authUserInfo && this.state.drive.authUserInfo.favouriteId) {
            let deleteUrl = url + encodeURIComponent(this.state.drive.authUserInfo.favouriteId);

            this.props.fetcher.delete(deleteUrl).then(response => {
                if (response.code === 0 || response.code === statusCode.notFound) {
                    let drive = { ...this.state.drive };
                    drive.authUserInfo = drive.authUserInfo || {};
                    drive.authUserInfo.favouriteId = null;
                    drive.numFavourites -= 1;

                    this.setState({
                        drive: drive,
                        togglingFavourite: false
                    });
                }
                else {
                    // TODO: handle error
                    console.error(response.code + ': ' + response.message);
                }
            });
        }
        else {
            this.props.fetcher.post(url, {
                name: null,
                driveId: this.state.drive.id,
                isPrivate: false 
            })
            .then(response => {
                if (response.code === 0 || response.code === statusCode.alreadyExists) {
                    let drive = { ...this.state.drive };
                    drive.authUserInfo = drive.authUserInfo || {};
                    drive.authUserInfo.favouriteId = response.id;
                    drive.numFavourites += 1;

                    this.setState({
                        drive: drive,
                        togglingFavourite: false
                    });
                }
                else {
                    // TODO: handle error
                    console.error(response.code + ': ' + response.message);
                }
            });
        }
    }

    fetchReviewDetail = (authUser, driveId, reviewId) => {
        let reviewDetailsUrl = this.props.config.apiUrl + '/v1' +
            '/drives/' + encodeURIComponent(driveId) +
            '/reviews/' + encodeURIComponent(reviewId);

        this.props.fetcher.get(reviewDetailsUrl).then(response => {
            if (response.code !== 0) return;

            let reviews = { ...this.state.reviews };
            reviews.detail = response.record;
            this.setState({ reviews: reviews });
        });
    }

    closeReviewDetail = () => {
        let qs = format.querystring(window.location.search);
        delete qs.showReview;
        this.props.history.push(this.props.location.pathname + qs.toString());
    }

    toggleEditDrive = () => {
        if (this.state.editDrive.visible) {
            this.setState({
                editDrive: {
                    visible: false,
                    drive: null
                }
            });

            return;
        }

        let url = this.props.config.apiUrl + '/v1/roadTypes';

        this.props.fetcher.get(url)
            .then(response => {
                let roadTypes = response.records;

                this.setState({
                    editDrive: {
                        visible: true,
                        roadTypes: roadTypes,
                        drive: {
                            name: this.state.drive.name,
                            startAddress: this.state.drive.startAddress,
                            endAddress: this.state.drive.endAddress,
                            generalLocation: this.state.drive.generalLocation,
                            isLoop: this.state.drive.isLoop,
                            description: this.state.drive.description,
                            roadNames: this.state.drive.roadNames,
                            roadTypes: this.state.drive.roadTypes,
                            links: this.state.links.records
                        }
                    }
                });
            });
    }

    saveDrive = (drive) => {
        const lockedForEditing = 5;

        let data = {
            name: drive.name,
            description: drive.description,
            startAddress: drive.startAddress,
            endAddress: drive.endAddress,
            generalLocation: drive.generalLocation,
            isPrivate: false,
            roadNames: drive.roadNames,
            roadTypes: drive.roadTypes
        };

        this.setState({
            editDrive: {
                visible: false,
                drive: null
            }
        });
        
        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id);

        this.props.fetcher.put(url, data)
            .then(response => {
                if (response.code !== 0 && response.subCode !== lockedForEditing) {
                    this.setState(() => { throw new Error(`Unexpected response code: ${response.code}`); });
                    return;
                }

                if (this.state.drive.slug !== response.slug) {
                    this.props.history.replace('/drives/' + encodeURIComponent(response.slug));
                }

                this.fetchDrive(this.authUser)
                    .then(() => {
                        if (response.code !== 0 && response.subCode === lockedForEditing) {
                            this.setState({
                                editDrive: {
                                    ...this.state.editDrive,
                                    isLocked: true
                                }
                            });
                        }
                        else {
                            this.setState({
                                editDrive: {
                                    visible: false,
                                    drive: null
                                }
                            });
                        }
                    });
            });
    }

    deleteDrive = () => {
        const lockedForEditing = 5;

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id);

        this.props.fetcher.delete(url)
            .then(response => {
                if (response.code !== 0 && response.subCode === lockedForEditing) {
                    this.fetchDrive(this.authUser)
                        .then(() => {
                            this.setState({
                                editDrive: {
                                    ...this.state.editDrive,
                                    isLocked: true
                                }
                            });
                        });
                    
                    return;
                }

                if (response.code !== 0) {
                    this.setState(() => { throw new Error(`Unexpected response code: ${response.code}`); });
                    return;
                }

                this.props.history.push('/');
            });
    }

    setHeroImage = (image) => {
        if (this.state.settingHeroImageId) return;
        if (!window.confirm('Set this image as this drive\'s main hero image?')) return;

        this.setState({ settingHeroImageId: image.id });

        let url = this.props.config.apiUrl + '/v1/drives/' + encodeURIComponent(this.state.drive.id) +
            '/images/' + encodeURIComponent(image.id) + '/setPrimary'

        this.props.fetcher.put(url, {})
            .then(response => {
                if (response.code !== 0) {
                    this.setState(() => { throw new Error(`Unexpected response code: ${response.code}`); });
                    return;
                }

                this.fetchDrive(this.authUser)
                    .then(() => {
                        this.setState({ settingHeroImageId: null });
                    })
            });
    }

    readyToRenderElevationPoint = true;

    handleElevationGraphPointSelected = (elevationItem) => {
        if (!this.google) return;

        if (!elevationItem) {
            setTimeout(() => {
                if (this.elevationMarker) {
                    this.elevationMarker.setMap(null);
                }
            }, 50);

            return;
        }

        if (!this.readyToRenderElevationPoint) return;
        this.readyToRenderElevationPoint = false;

        let point = elevationItem.point;

        let elevationMarker = new this.google.maps.Marker({
            map: this.map,
            position: {
                lat: point.lat,
                lng: point.lng
            },
            label: {
                text: elevationItem.height.toFixed(0),
                color: '#ffffff',
                fontSize: '10px'
            }
        });

        setTimeout(() => {
            if (this.elevationMarker) {
                this.elevationMarker.setMap(null);
            }
    
            this.elevationMarker = elevationMarker;
        }, 50);

        setTimeout(() => {
            this.readyToRenderElevationPoint = true;
        }, 100)
    }

    renderTrafficCountLocations = (countPoints, toggle) => {
        if (!this.google) return;

        this.traffic.countPointMarkers.forEach(marker => {
            if (!countPoints.some(cp => cp.id === marker.id)) {
                marker.setMap(null);
            }
        });

        this.traffic.countPointMarkers = countPoints.map(cp => {
            let marker = this.traffic.countPointMarkers.find(m => m.id === cp.id);

            if (!marker) {
                marker = new this.google.maps.Marker({
                    id: cp.id,
                    map: this.map,
                    position: {
                        lat: cp.lat,
                        lng: cp.lng
                    },
                    title: cp.roadName,
                    zIndex: 2,
                    icon: {
                        url: cp.pin,
                        size: new this.google.maps.Size(25, 20),
                        scaledSize: new this.google.maps.Size(25, 20),
                        zIndex: 2
                    }
                });

                marker.addListener('click', (e) => {
                    toggle(cp);
                });
            }

            marker.setIcon({
                url: cp.pin,
                size: new this.google.maps.Size(25, 20),
                scaledSize: new this.google.maps.Size(25, 20),
                zIndex: 2
            });

            return marker;
        });
    }

    saveTrafficDriveCountLocations = (countPointIds) => {
        const data = {
            countPointIds: countPointIds
        };

        let url = this.props.config.apiUrl + '/v1/traffic/' + encodeURIComponent(this.state.drive.id);
        return this.props.fetcher.put(url, data)
            .then(() => {
                return this.fetchTrafficData();
            });
    }

    renderAccidentHeatMap = (filteredAccidents) => {
        this.accidents.markers.forEach(m => m.setMap(null));

        if (this.accidents.heatMap) {
            this.accidents.heatMap.setMap(null);
            this.accidents.heatMap = null;
        }

        if (this.polyline) {
            this.polylineBorder.setMap(null);
            this.polyline.setOptions(Drive.PolylineOptionsFaded);
        }

        let heatMapData = filteredAccidents
            .map(record => {
                let weight = record.severity.id === 1 ? 3 : (record.severity.id === 3 ? 1 : 2);

                return {
                    location: new this.google.maps.LatLng(record.location.lat, record.location.lng),
                    weight: weight
                };
            });

        this.accidents.heatMap = new this.google.maps.visualization.HeatmapLayer({
            data: heatMapData,
            dissipating: true,
            radius: geo.getHeatmapRadius(this.map, this.google)
        });
        this.accidents.heatMap.setMap(this.map);
    }

    renderAccidents = (filteredAccidents, animate) => {
        this.accidents.markers.forEach(m => m.setMap(null));

        if (this.accidents.heatMap) {
            this.accidents.heatMap.setMap(null);
            this.accidents.heatMap = null;
        }

        if (this.polyline) {
            this.polyline.setOptions(Drive.PolylineOptions);
        }

        if (this.polylineBorder) {
            this.polylineBorder.setMap(this.map);
        }

        const pinSettings = {
            size: new this.google.maps.Size(20, 35),
            scaledSize: new this.google.maps.Size(20, 35),
            zIndex: 999
        };

        this.accidents.markers = filteredAccidents
            .map(record => {
                const icon = {
                    url: record.severity.pin,
                    ...pinSettings
                }

                const marker = new this.google.maps.Marker({
                    position: record.location,
                    optimized: true,
                    zIndex: record.severity.id === 1 ? 4 : (record.severity.id === 3 ? 2 : 3),
                    icon: icon,
                    animation: animate ? this.google.maps.Animation.DROP : null,
                });

                marker._originalIcon = icon;
                marker._severityId = record.severity.id;

                marker.addListener('click', e => {
                    if (this.accidents.popup) {
                        this.accidents.popup.close();
                        this.accidents.popup = null;
                    }

                    if (this.state.accidentRemoveMode) {
                        let accidentRecord = this.accidents.data.find(a => a.id === record.id);
                        if (!accidentRecord) return;

                        accidentRecord.markedForRemoval = !accidentRecord.markedForRemoval;

                        if (accidentRecord.markedForRemoval) {
                            marker.setIcon({
                                url: pinGray,
                                ...pinSettings
                            });
                        }
                        else {
                            marker.setIcon(marker._originalIcon);
                        }

                        return;
                    }

                    let accidentUrl = this.props.config.apiUrl + '/v1/accidents/' + record.id;
                    this.props.fetcher.get(accidentUrl).then(response => {
                        let accidentDetail = response.record;

                        let infoWindow = new this.google.maps.InfoWindow({
                            minWidth: 310,
                            maxWidth: 400,
                            minHeight: 200
                        });

                        infoWindow.addListener('domready', () => {
                            AccidentPopup.init(accidentDetail, () => {
                                if (this.accidents.popup) {
                                    this.map._enableControls();
                                    this.accidents.popup.close();
                                    this.accidents.popup = null;
                                }
                            });
                        });

                        infoWindow.setPosition(record.location);

                        let content = ReactDOMServer.renderToString(<AccidentPopup accident={accidentDetail}></AccidentPopup>)
                        infoWindow.setContent(content);
                        infoWindow.open({
                            map: this.map,
                            shouldFocus: true
                        });

                        this.map._disableControls();

                        this.accidents.popup = infoWindow;
                    });
                });

                return marker;
            });

        if (animate) {
            this.accidents.markers.sort((m1, m2) => {
                return m2._severityId - m1._severityId;
            });

            this.animateMarkers(this.accidents.markers);
        }
        else {
            this.accidents.markers.forEach(m => m.setMap(this.map));
        }
    }

    animateMarkers = (markers, options) => {
        options = options || {};

        const intervalTime = 20;
        const duration = options.duration || 1500;

        let increment = 0;
        let dropsPerInterval = Math.ceil(markers.length / (duration / intervalTime));

        const markerDropInterval = setInterval(() => {
            for (let i = 0; i < dropsPerInterval; i++) {
                const marker = markers[increment];
                if (!marker) {
                    clearInterval(markerDropInterval);

                    const index = this.markerDropIntervals.indexOf(markerDropInterval);
                    this.markerDropIntervals.splice(index, 1);

                    return;
                }

                marker.setMap(this.map);
                increment++;
            }
        }, intervalTime);

        this.markerDropIntervals.push(markerDropInterval);
    }

    handleAccidentControlOpen = () => {
        const url = this.props.config.apiUrl + '/v1/accidents?driveId=' + encodeURIComponent(this.state.drive.id);

        return this.props.fetcher.get(url)
            .then(response => {
                let accidents = response.records;
                this.accidents.data = accidents;

                if (this.accidents.data.length > 0) {
                    if (this.startMarker) {
                        this.startMarker.setMap(null);
                    }
            
                    if (this.endMarker) {
                        this.endMarker.setMap(null);
                    }
                }

                return accidents;
            });
    }

    handleAccidentControlClose = () => {
        if (this.startMarker) {
            this.startMarker.setMap(this.map);
        }

        if (this.endMarker) {
            this.endMarker.setMap(this.map);
        }

        if (this.accidents.heatMap) {
            this.accidents.heatMap.setMap(null);
            this.accidents.heatMap = null;
        }

        if (this.polyline) {
            this.polyline.setOptions(Drive.PolylineOptions);
        }

        if (this.polylineBorder) {
            this.polylineBorder.setMap(this.map);
        }

        this.accidents.data = [];
        this.accidents.markers.forEach(m => m.setMap(null));
        this.accidents.markers = [];
    }

    handleAccidentRemoveModeToggle = () => {
        const removedMode = this.state.accidentRemoveMode;
        this.setState({ accidentRemoveMode: !removedMode });

        if (removedMode) {
            this.accidents.data.forEach(a => delete a.markedForRemoval);
            this.accidents.markers.forEach(m => m.setIcon(m._originalIcon));
        }
    }

    handleAccidentRemoveSave = (callback) => {
        const removedAccidents = this.accidents.data.filter(a => a.markedForRemoval);

        const numAccidents = removedAccidents.length;
        let message = null;
        if (numAccidents > 0) {
            message = `About to remove ${numAccidents} accident${numAccidents !== 1 ? `s`: ``}. Are you sure?`;
        }
        else {
            message = 'No accidents selected. This will force the Safety Record to be re-calculated. Are you sure?';
        }

        if (!window.confirm(message)) return;
        
        const url = this.props.config.apiUrl + '/v1/accidents/removeFromDrive/' + encodeURIComponent(this.state.drive.id);

        const data = {
            accidentIds: removedAccidents.map(a => a.id)
        };

        this.props.fetcher.put(url, data).then(response => {
            this.setState({ accidentRemoveMode: false });
            callback();

            this.fetchSafetyData();
        });
    }

    componentWillUnmount() {
        if (this.polyline) {
            this.polyline.setMap(null);
        }

        if (this.polylineBorder) {
            this.polylineBorder.setMap(null);
        }

        if (this.carouselObserver) {
            this.carouselObserver.disconnect();
        }

        if (this.tabsObserver) {
            this.tabsObserver.disconnect();
        }

        if (this.startMarker) {
            this.startMarker.setMap(null);
        }

        if (this.endMarker) {
            this.endMarker.setMap(null);
        }

        if (this.elevationMarker) {
            this.elevationMarker.setMap(null);
        }

        if (this.placeTooltip) {
            this.placeTooltip.setMap(null);
        }

        this.mapEvents.forEach(e => this.google.maps.event.removeListener(e));

        window.removeEventListener('resize', this.handleResizeWindow);
        this.unlistenLocationChanges();

        this.state.placeTypes.records.forEach(placeType => {
            if (placeType.places) {
                placeType.places.forEach(place => {
                    if (place.marker) {
                        place.marker.setMap(null);
                    }
                });
            }
        });

        if (this.currentPlacePopup) {
            this.currentPlacePopup.close();
            this.currentPlacePopup = null;
        }

        this.accidents.markers.forEach(m => m.setMap(null));
        if (this.accidents.popup) {
            this.accidents.popup.close();
            this.accidents.popup = null;
        }

        if (this.accidents.heatMap) {
            this.accidents.heatMap.setMap(null);
        }

        this.traffic.countPointMarkers.forEach(marker => {
            marker.setMap(null);
        });

        this.markerDropIntervals.forEach(interval => {
            clearInterval(interval);
        });
    }

    render() {
        const averageRating = this.state.drive ? this.state.drive.averageRating : null;
        const showBreadcrumbs = !this.state.drive || (this.state.drive && !this.state.drive.primaryImage);
        const hasPrimaryImage = this.state.drive && this.state.drive.primaryImage;

        let canEditDrive = false;
        
        if (this.state.drive && this.authUser) {
            if (this.authUser.isAdmin) {
                canEditDrive = true;
            }
            else {
                canEditDrive = this.state.drive.createdBy &&
                    this.state.drive.createdBy.id === this.authUser.id &&
                    !this.state.drive.isLocked;
            }
        }

        let canSetHeroImage = false;

        if (this.state.drive && this.authUser) {
            if (this.authUser.isAdmin) {
                canSetHeroImage = true;
            }
            else {
                canSetHeroImage = this.state.drive.createdBy &&
                    this.state.drive.createdBy.id === this.authUser.id;
            }
        }

        let qs = format.querystring(window.location.search);

        return (<>
            <div className="main-page-half drive-detail-page">
                {showBreadcrumbs &&
                    <nav className="breadcrumbs" aria-label="Breadcrumb">
                        <ol className="list">
                            <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                            <li className="item"><NavLink exact to={`/drives/${this.props.match.params.id}`} aria-current="location" className="link">Drive</NavLink></li>
                        </ol>
                    </nav>
                }

                {this.state.isNotFound &&
                    <div className="not-found-content">
                        <h1 className="title"><FontAwesomeIcon className="icon" icon={faMapSigns}/> 404: Drive Not Found</h1>

                        <p>
                            Sorry, but we couldn't locate this drive. If you came from another
                            site, they might need to update their links.
                        </p>

                        <p>
                            In the meantime try <NavLink to="/">navigating to the home page</NavLink>,
                            and searching for exciting driving roads near where you live.
                        </p>

                        <p>Thank you!</p>
                    </div>
                }

                {this.state.isLoading &&
                    <div className="loading-detail-page">
                        <p className="loading-message" aria-label="Loading drive, please wait...">
                            <FontAwesomeIcon className="icon" icon={faTire} spin={true}/>
                        </p>
                    </div>
                }

                {this.state.drive &&
                    <article className={`split-page-content drive-detail-article` + (hasPrimaryImage ? ` has-image` : ``)}>
                        <header className={`content-image-header` + (hasPrimaryImage ? ` has-image` : ``)}>
                            <div className="title-container">
                                <h1 className="title">{this.state.drive.name}</h1>
                            
                                <div className="title-sub-info">
                                    <p className="drive-author">
                                        {this.state.drive.createdBy && 
                                            <span>Submitted by <NavLink to={`/profile/` + this.state.drive.createdBy.username}>{this.state.drive.createdBy.username}</NavLink></span>
                                        }
                                        {!this.state.drive.createdBy &&
                                            <span>Submitted</span>
                                        }
                                        <span> on {format.date(this.state.drive.created)}</span>
                                    </p>

                                    {canEditDrive && <button className="link-button edit-drive-button" onClick={this.toggleEditDrive}>Edit Drive</button>}
                                </div>
                            </div>

                            {this.state.drive.primaryImage &&
                                <figure className="drive-hero-image">
                                    <img className="image" alt={this.state.drive.primaryImage.caption}
                                        src={this.state.drive.primaryImage.sizes.large}></img>
                                </figure>
                            }

                            <div className="drive-actions">
                                <div className={`drive-review` + (!averageRating ? ` no-rating` : ``)}>
                                    <p className='aggregate-review-text'>
                                        <Stars className="star-rating" readOnly={true} rating={averageRating} aria-label={averageRating ?
                                            `This drive has an average rating of ` + averageRating  + ` out of 5 stars` :
                                            `This drive hasn't received any ratings yet`} onClick={this.toggleReviewModal}/>

                                        {this.state.drive.numRatings > 0 &&
                                            <span className="review-summary"> (Based on {this.state.drive.numRatings} rating{this.state.drive.numRatings !== 1 ? 's' : ''})</span>
                                        }
                                    </p>

                                    {this.state.drive.numRatings === 0 &&
                                        <button type="button" className="link-button add-review-button" onClick={this.toggleReviewModal}>
                                            <FontAwesomeIcon className="icon" icon={faMessageQuote}/>
                                            <span className="text">Add Your Review</span>
                                        </button>
                                    }
                                </div>

                                <div className="send-to-google">
                                    <p className="google-maps-icon" id="google-maps-links-title">Google Maps Options</p>

                                    <ul className="map-links" aria-labelledby="google-maps-links-title">
                                        <li>
                                            <a className="link-button maps-view-link" target="_blank" rel="noreferrer" href={this.state.drive.googleMapsUrl}>
                                                <span className="text">View In Google Maps</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="link-button maps-directions-link" target="_blank" rel="noreferrer" href={this.state.drive.directionsUrl}>
                                                or <span className="text">Get Directions</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="drive-likes">
                                    <button type="button" className="link-button like-button" onClick={this.toggleFavourite}
                                        aria-label={this.state.drive.authUserInfo && this.state.drive.authUserInfo.favouriteId ? `You have liked this drive, click to remove your like` : `Like this drive`}>
                                        <FontAwesomeIcon className="icon" fixedWidth={true} spin={this.state.togglingFavourite}
                                            icon={this.state.togglingFavourite ? faSync : (this.state.drive.authUserInfo && this.state.drive.authUserInfo.favouriteId ? fullHeart : emptyHeart)}/>
                                    </button>

                                    <p className="like-summary" aria-label={(this.state.drive.numFavourites === 1 ? `1 person has` : `${this.state.drive.numFavourites} people have`) + ` liked this drive`}>
                                        <span aria-hidden="true">({this.state.drive.numFavourites})</span>
                                    </p>
                                </div>
                            </div>
                        </header>

                        {this.state.drive.description &&
                            <div className="drive-description">
                                <h2 className="title">Summary<span aria-hidden="true"> -</span></h2>
                                {format.textBlock(this.state.drive.description, "summary-text")}
                            </div>
                        }

                        <DriveInfo className="drive-meta-data"
                            name={this.state.drive.name}
                            startAddress={this.state.drive.startAddress}
                            endAddress={this.state.drive.endAddress}
                            generalLocation={this.state.drive.generalLocation}
                            isLoop={this.state.drive.isLoop}
                            averageSpeed={this.state.drive.averageSpeed}
                            distanceInMeters={this.state.drive.distance}
                            travelTimeInMinutes={this.state.drive.travelTime}
                            roadTypes={this.state.drive.roadTypes}
                            roadNames={this.state.drive.roadNames}
                            averageDailyTraffic={this.state.drive.averageDailyTraffic} />

                        <div className="tab-control drive-info-tabs">
                            <div className="tab-list" role="tablist" aria-label="Drive Technical Information">
                                {this.state.drive.elevation &&
                                    <button className={`tab-button` + (this.state.visibleTab === 'elevation' ? ` selected` :``)}
                                        type="button" role="tab" aria-selected={this.state.visibleTab === 'elevation'}
                                        aria-controls="elevation_gradient_tab_content" id="elevation_gradient_tab"
                                        onClick={() => this.showTab('elevation')} onKeyUp={e => this.handleTabKeyPress(e)}>Elevation/Gradient</button>
                                }

                                {this.state.drive.supportsTrafficData &&
                                    <button className={`tab-button` + (this.state.visibleTab === 'traffic' ? ` selected` :``)}
                                        type="button" role="tab" aria-selected={this.state.visibleTab === 'traffic'}
                                        aria-controls="traffic_data_tab_content" id="traffic_data_tab" tabIndex="-1"
                                        onClick={() => this.showTab('traffic')} onKeyUp={e => this.handleTabKeyPress(e)}>Traffic Data</button>
                                }

                                {this.state.drive.supportsAccidentData &&
                                    <button className={`tab-button` + (this.state.visibleTab === 'accidents' ? ` selected` :``)}
                                        type="button" role="tab" aria-selected={this.state.visibleTab === 'accidents'}
                                        aria-controls="accident_data_tab_content" id="accident_data_tab" tabIndex="-1"
                                        onClick={() => this.showTab('accidents')} onKeyUp={e => this.handleTabKeyPress(e)}>Safety Data</button>
                                }
                            </div>

                            {this.state.drive.elevation &&
                                <section tabIndex="0" role="tabpanel" id="elevation_gradient_tab_content" aria-labelledby="elevation_gradient_tab"
                                    className={`elevation-details tab-content ${this.state.visibleTab === 'elevation' ? `tab-visible` : `tab-hidden`}`}>
                                    <h2 className="tab-title">Elevation/Gradient</h2>
                                    <ElevationGradientInfo elevation={this.state.drive.elevation} gradient={this.state.drive.gradient}
                                        adminMode={this.authUser && this.authUser.isAdmin}
                                        distanceInMeters={this.state.drive.distance} onElevationGraphPointSelected={this.handleElevationGraphPointSelected}/>
                                </section>
                            }

                            {this.state.drive.supportsTrafficData &&
                                <section tabIndex="0" role="tabpanel" id="traffic_data_tab_content" aria-labelledby="traffic_data_tab"
                                    className={`traffic-info-tab tab-content ${this.state.visibleTab === 'traffic' ? `tab-visible` : `tab-hidden`}`}>
                                    <h2 className="tab-title">Traffic Data</h2>
                                    <TrafficInfo trafficData={this.state.traffic.data} countPoints={this.state.traffic.countPoints}
                                        renderCountLocations={this.renderTrafficCountLocations} adminMode={this.authUser && this.authUser.isAdmin}
                                        saveDriveCountLocations={this.saveTrafficDriveCountLocations}/>
                                </section>
                            }

                            {this.state.drive.supportsAccidentData &&
                                <section tabIndex="0" role="tabpanel" id="accident_data_tab_content" aria-labelledby="accident_data_tab"
                                    className={`accidents-tab tab-content ${this.state.visibleTab === 'accidents' ? `tab-visible` : `tab-hidden`}`}>
                                    <h2 className="tab-title">Safety Data</h2>
                                    <AccidentControls onOpen={this.handleAccidentControlOpen} onClose={this.handleAccidentControlClose}
                                        renderHeatMap={this.renderAccidentHeatMap} renderPins={this.renderAccidents} drive={this.state.drive}
                                        adminMode={this.authUser && this.authUser.isAdmin} removeMode={this.state.accidentRemoveMode}
                                        onToggleRemoveMode={this.handleAccidentRemoveModeToggle} onRemoveSave={this.handleAccidentRemoveSave}
                                        safetyRecord={this.state.accidents.safetyRecord} safetyAverages={this.state.accidents.safetyAverages}/>
                                </section>
                            }
                        </div>

                        {/* ---- PLACES SECTION ---- */}
                        {this.state.drive.placesSupported && 
                            <section className="drive-places drive-info-section">
                                <header className="header">
                                    <h2 className="title">
                                        <span className="icon-container" aria-hidden="true">
                                            <FontAwesomeIcon className="icon" icon={faMap}/>
                                        </span>
                                        Show Places On Map
                                    </h2>
                                </header>

                                <div className="places-list drive-info-main">
                                    {this.state.drive.placesAreIndexed &&
                                        <div className="place-types-control">
                                            {this.state.placeTypes.records.map(placeType => 
                                                <button key={placeType.id} type="button" className={'button toggle-type-button' + (placeType.showPlaces ? ` selected` : ``)}
                                                    style={{ background: placeType.color }} aria-label={`Show ${placeType.name} on the map`}
                                                    onClick={() => this.handlePlaceTypeClick(placeType.id)}>
                                                    <FontAwesomeIcon className="icon" fixedWidth={true} icon={placeType.icon}/>
                                                    <span className="text">{placeType.name}</span>
                                                </button>
                                            )}
                                        </div>
                                    }

                                    {!this.state.drive.placesAreIndexed &&
                                        <p className="loading-message">
                                            <FontAwesomeIcon className="icon" icon={faTire} spin={true}/>
                                            <span className="text">Places are being indexed, please return in 5 - 10 minutes...</span>
                                        </p>
                                    }
                                </div>
                            </section>
                        }

                        {/*---- REVIEW SECTION ----*/}
                        {this.state.reviews.totalRecords === 0 && averageRating !== null &&
                            <div className="drive-info-not-present">
                                <button type="button" className="link-button add-review-button" onClick={() => this.toggleReviewModal()}>
                                    <FontAwesomeIcon className="icon" fixedWidth={true} icon={faMessageQuote}/>
                                    Add Your Review
                                </button>
                            </div>
                        }

                        {this.state.reviews.totalRecords > 0 &&
                            <section className="drive-reviews drive-info-section">
                                <header className="header">
                                    <h2 className="title">
                                        <span className="icon-container" aria-hidden="true">
                                            <FontAwesomeIcon className="icon" icon={faMessageQuote}/>
                                        </span>
                                        Reviews {this.state.reviews.totalRecords > 0 && <span className="count">({this.state.reviews.totalRecords})</span>}
                                    </h2>
                                    <div className="controls">
                                        <button type="button" className="link-button add-review-button" onClick={this.toggleReviewModal}>
                                            {this.state.drive.authUserInfo && this.state.drive.authUserInfo.reviewId ? 'Update' : 'Add'}
                                            <span className="superfluous"> Your</span> Review
                                        </button>
                                    </div>
                                </header>

                                <div className="review-list drive-info-main" aria-label={`Showing ${this.state.reviews.totalRecords} review` + (this.state.reviews.totalRecords !== 1 ? `s`: ``)}>
                                    {this.state.reviews.isLoading &&
                                        <p className="loading-message">
                                            <FontAwesomeIcon className="icon" icon={faTire} spin={true}/>
                                            <span className="text">Loading reviews...</span>
                                        </p>
                                    }

                                    <div className={`drive-reviews-list` + (this.state.reviews.records.length === 1 ? ' just-one' : ``)}>
                                        {this.state.reviews.records.map(review => 
                                            <article key={review.id} className="drive-review">
                                                <div className="review-text">
                                                    {format.textBlock(format.truncate(review.text, 400, '...'))}

                                                    {review.text.length > 400 &&
                                                        <NavLink className="read-more" to={`${qs.toString({ showReview: review.id })}`}>
                                                            <FontAwesomeIcon className="icon" icon={faChevronCircleDown}/>
                                                            <span className="text">Read more</span>
                                                        </NavLink>
                                                    }
                                                </div>

                                                <footer className="review-info">
                                                    {review.author.avatarImages &&
                                                        <img className="review-avatar" alt="" src={review.author.avatarImages.small}/>
                                                    }

                                                    {!review.author.avatarImages &&
                                                        <FontAwesomeIcon className="review-avatar no-image" icon={faUserCircle}/>
                                                    }

                                                    <div className="rating-author">
                                                        <NavLink className="star-rating-link" to={`${qs.toString({ showReview: review.id })}`}>
                                                            <Stars className="star-rating-bw" readOnly={true} rating={review.rating}
                                                                aria-label={`Rated ` + review.rating + ` out of 5 stars`}/>
                                                        </NavLink>
                                                            
                                                        <p className="author">By <NavLink to={`/profile/` + review.author.username}>{review.author.fullname}</NavLink></p>
                                                    </div>
                                                </footer>
                                            </article>
                                        )}
                                    </div>

                                    {this.state.reviews.hasMoreRecords && !this.state.reviews.isLoading &&
                                        <footer className="expand-records">
                                            <button className="link-button" onClick={this.fetchReviews}>
                                                <FontAwesomeIcon className="icon" icon={faAngleDoubleDown}/> Show more reviews
                                            </button>
                                        </footer>
                                    }
                                </div>
                            </section>
                        }

                        {/* ---- YOUTUBE SECTION ---- */}
                        {this.state.videos.totalRecords === 0 &&
                            <div className="drive-info-not-present">
                                <button type="button" className="link-button add-video-button" onClick={this.toggleVideoModal}>
                                    <FontAwesomeIcon className="icon" fixedWidth={true} icon={faYoutube}/>
                                    Add a YouTube Video
                                </button>
                            </div>
                        }
                            
                        {this.state.videos.totalRecords > 0 &&
                            <section className="youTube-carousel drive-info-section">
                                <header className="header">
                                    <h2 className="title">
                                        <span className="icon-container" aria-hidden="true">
                                            <FontAwesomeIcon className="icon" fixedWidth={true} icon={faYoutube}/>
                                        </span>
                                        YouTube Videos {this.state.videos.totalRecords > 0 && <span className="count">({this.state.videos.totalRecords})</span>}
                                    </h2>
                                    <div className="controls">
                                        <button type="button" className="link-button add-video-button" onClick={this.toggleVideoModal}>
                                            {this.state.drive.authUserInfo && this.state.drive.authUserInfo.hasVideos ? 'Update' : 'Add'}
                                            <span className="superfluous"> Your</span> Videos
                                        </button>
                                    </div>
                                </header>

                                <div className="carousel drive-info-main" aria-label={`Showing ${this.state.videos.totalRecords} video` + (this.state.videos.totalRecords !== 1 ? `s`: ``)}>
                                    {this.state.videos.isLoading &&
                                        <p className="loading-message">
                                            <FontAwesomeIcon className="icon" icon={faTire} spin={true}/>
                                            <span className="text">Loading videos...</span>
                                        </p>
                                    }

                                    {this.state.youTubeCarousel.showLeft &&
                                        <button type="button" className="scroll-left" aria-hidden="true">
                                            <FontAwesomeIcon className="icon rtl-flip" icon={faChevronLeft}/>
                                        </button>
                                    }

                                    <div className="scroller">
                                        {this.state.videos.records.map(video =>
                                            <article key={video.id} className="carousel-item yt-item">
                                                <h3 className="screen-reader-only">{video.title} by {video.channelTitle}</h3>

                                                <iframe width="360" height="206" src={`https://www.youtube.com/embed/` + video.youTubeId} title="YouTube video player"
                                                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    className="framed-image yt-video" allowFullScreen></iframe>
                                                <p className="author">
                                                    <a target="_blank" rel="noreferrer" href={`https://www.youtube.com/channel/` + video.channelId}
                                                        aria-label={`Visit the YouTube channel ${video.channelTitle}`}>
                                                        {video.channelTitle}
                                                    </a>
                                                </p>
                                            </article>
                                        )}
                                    </div>

                                    {this.state.youTubeCarousel.showRight &&
                                        <button type="button" className="scroll-right" aria-hidden="true">
                                            <FontAwesomeIcon className="icon rtl-flip" icon={faChevronRight}/>
                                        </button>
                                    }
                                </div>
                            </section>
                        }

                        {/* ---- PHOTOS SECTION ---- */}
                        {this.state.images.totalRecords === 0 &&
                            <div className="drive-info-not-present">
                                <button type="button" className="link-button add-photo-button" onClick={() => this.toggleImagesModal()}>
                                    <FontAwesomeIcon className="icon" fixedWidth={true} icon={faCameraRetro}/>
                                    Upload a photo
                                </button>
                            </div>
                        }

                        {this.state.images.totalRecords > 0 &&
                            <section className="drive-photos drive-info-section">
                                <header className="header">
                                    <h2 className="title">
                                        <span className="icon-container" aria-hidden="true">
                                            <FontAwesomeIcon className="icon" fixedWidth={true} icon={faCameraRetro}/>
                                        </span>
                                        Photos {this.state.images.totalRecords > 0 && <span className="count">({this.state.images.totalRecords})</span>}
                                    </h2>

                                    <div className="controls">
                                        <button type="button" className="link-button add-photo-button" onClick={() => this.toggleImagesModal()}>
                                            Upload a Photo
                                        </button>
                                    </div>
                                </header>

                                <div className="drive-info-main" aria-label={`Showing ${this.state.images.totalRecords} photo` + (this.state.images.totalRecords !== 1 ? `s`: ``)}>
                                    {this.state.images.isLoading &&
                                        <p className="loading-message">
                                            <FontAwesomeIcon className="icon" icon={faTire} spin={true}/>
                                            <span className="text">Loading images...</span>
                                        </p>
                                    }

                                    <div className="photo-grid">
                                        {this.state.images.records.map(image =>
                                            <figure key={image.id} className="photo-item">
                                                <div className="photo">
                                                    <PopupImage className="image" imageId={image.id} caption={image.caption} uploader={image.uploader}
                                                        source={image.source} sourceUrl={image.sourceUrl} src={image.sizes.small} largeSrc={image.sizes.large}
                                                        onClick={this.showImage} onModalClose={this.closeImage}/>
                                                </div>
                                                <figcaption className="author">
                                                    {image.caption && <span className="caption">{this.formatImageCaption(image.caption)}</span>}

                                                    {image.source &&
                                                        <span className="author-by"> by {image.sourceUrl &&
                                                                <a href={image.sourceUrl} className="link" target="_blank" rel="noreferrer">{image.source}</a>
                                                            }
                                                            {!image.sourceUrl && <span>{image.source}</span>}
                                                        </span>
                                                    }

                                                    {!image.source &&
                                                        <span className="author-by"> by <NavLink className="link"
                                                            to={`/profile/` + image.uploader.username}>{image.uploader.username}</NavLink></span>
                                                    }
                                                </figcaption>

                                                <footer className="image-controls">
                                                    {this.authUser && (this.authUser.isAdmin || this.authUser.id === image.uploader.id) &&
                                                        <button type="button" className="icon-button edit-image-button" aria-label="Edit this image"
                                                            title="Edit this image" onClick={() => this.toggleImagesModal(image)}>
                                                            <FontAwesomeIcon className="icon" icon={faEdit}/> 
                                                        </button>
                                                    }

                                                    {canSetHeroImage &&
                                                        <button type="button" className="icon-button hero-image-button" aria-label="Set Hero Image"
                                                            title="Set Hero Image" onClick={() => this.setHeroImage(image)}>
                                                            <FontAwesomeIcon className="icon" icon={this.state.settingHeroImageId === image.id ? faSync : faImage}
                                                                fixedWidth={true} spin={this.state.settingHeroImageId === image.id}/>
                                                        </button>
                                                    }
                                                </footer>
                                            </figure>
                                        )}
                                    </div>

                                    {this.state.images.hasMoreRecords && !this.state.images.isLoading &&
                                        <footer className="expand-records">
                                            <button className="link-button" onClick={this.fetchImages}>
                                                <FontAwesomeIcon className="icon" icon={faAngleDoubleDown}/> Show more images
                                            </button>
                                        </footer>
                                    }
                                </div>
                            </section>
                        }

                        {/* ---- LINKS SECTION ---- */}
                        <section className="drive-resources drive-info-section">
                            <header className="header">
                                <h2 className="title">
                                    <span className="icon-container">
                                        <FontAwesomeIcon className="icon" fixedWidth={true} icon={faLink}/>
                                    </span>
                                    External Links
                                </h2>

                                {canEditDrive &&
                                    <div className="controls">
                                        <button type="button" className="link-button" onClick={() => this.toggleEditLinks()}>
                                            Edit Links
                                        </button>
                                    </div>
                                }
                            </header>

                            <div className="drive-info-main">
                                <ul className="links-list with-icons">
                                    {this.state.links.records.map(link =>
                                        <li key={link.id} className="item">
                                            <FontAwesomeIcon className="icon" fixedWidth={true} icon={link.icon}/>
                                            <a className="link" target="_blank" rel="noreferrer" href={link.url}>{link.name}</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </section>
                    </article>
                }
            </div>

            {this.state.editReview.visible &&
                <ReviewModal review={this.state.editReview.review} handleClose={this.toggleReviewModal}
                    handleSave={this.saveReview} handleDelete={this.deleteReview}/>
            }

            {this.state.editVideos.visible &&
                <VideosModal handleClose={this.toggleVideoModal} handleSave={this.saveVideos}
                    videos={this.state.editVideos.videos} googleApiKey={this.props.config.googleApiKey} />
            }

            {this.state.uploadImage.visible &&
                <DriveImage handleClose={() => this.toggleImagesModal()} handleSave={this.saveImage} handleDelete={this.deleteImage}
                    image={this.state.uploadImage.image} maxImageAllowance={this.props.config.maxPhotosPerDrive}
                    totalUserImages={this.state.uploadImage.numAuthUserImages} />
            }

            {this.state.reviews.detail &&
                <ReviewDetailModal handleClose={this.closeReviewDetail} drive={this.state.drive} review={this.state.reviews.detail}/>
            }

            {this.state.editLinks.visible &&
                <ExternalLinks handleClose={this.toggleEditLinks} handleSave={this.saveLinks} links={this.state.editLinks.links}/>
            }

            {this.state.showImage &&
                <ImageModal onModalClose={this.closeImage} src={this.state.showImage.sizes.large}
                    caption={this.state.showImage.caption} author={this.state.showImage.uploader}/>
            }

            {this.state.editDrive.visible &&
                <EditDriveModal handleClose={this.toggleEditDrive} handleSave={this.saveDrive} handleDelete={this.deleteDrive}
                    drive={this.state.editDrive.drive} adminMode={this.authUser && this.authUser.isAdmin} isLocked={this.state.editDrive.isLocked}
                    roadTypes={this.state.editDrive.roadTypes}/>
            }

            <ScrollIndicator/>
        </>)
    }
}

export default basePageWrapper(Drive) // 2838