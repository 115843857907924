import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'

class About extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'About - Spirited Drive';
        let metaDescription = 'Spirited Drive is a crowdsourced searchable online database of the ' +
            'world\'s best driving roads. Created by Dominic Pettifer.';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    render() {
        return (
            <div className="main-page-full about-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/about" aria-current="location" className="link">About</NavLink></li>
                    </ol>
                </nav>

                <section className="about-section">
                    <h1 className="title">About Spirited Drive</h1>

                    <p className="elevator-pitch">
                        Spirited Drive is a crowdsourced searchable online database of the world's best driving roads.
                    </p>

                    <p>
                        It features reviews, photos, YouTube videos, and other general information about each driving road,
                        all submitted by other fellow petrol-heads, motorbike, and car enthusiasts.
                    </p>

                    <p>
                        We would encourage you to register and upload your own favourite driving roads, and share them
                        with the rest of he community. Or you can favourite existing roads uploaded by other drivers,
                        and keep a list of roads you want to try out.
                    </p>


                    <h2 className="sub-title">About The Developer</h2>

                    <p>
                        My name is Dominic Pettifer and I build and maintain this website. I am a software developer of
                        20 years and a fan of Ford Mustangs. I love taking my car out for an exhilarating drive on great
                        driving roads. However, when researching the best driving roads, I always find myself resorting
                        to the following sources:
                    </p>

                    <ul>
                        <li>Random forum posts,</li>
                        <li>Recommendations from friends,</li>
                        <li>Other poorly maintained or abandoned driving road websites,</li>
                        <li>Old Top Gear episodes,</li>
                        <li>Randomly zooming/panning around Google Maps.</li>
                    </ul>

                    <p>
                        Although there are other driving road websites, I felt there was a gap in the market for a truly
                        crowdsourced offering that allows submissions from the public. So I built this website.
                    </p>

                    <p>
                        If you have any ideas or feature requests, or want to report any bugs you’ve found,
                        I would love to hear from you. Please use the contact details below. Thank you!
                    </p>

                    <p>Drive Safe!</p>

                    <h2 className="sub-title">Contact</h2>

                    <ul className="about-links">
                        <li className="item">
                            <FontAwesomeIcon className="icon" fixedWidth icon={faEnvelope}/>
                            <strong className="label">Email:</strong>
                            <a className="link" href={`mailto:${this.props.config.email}`}>{this.props.config.email}</a>
                        </li>
                        <li className="item">
                            <FontAwesomeIcon className="icon" fixedWidth icon={faTwitter}/>
                            <strong className="label">Twitter:</strong>
                            <a className="link" rel="noreferrer" target="_blank" href={`https://twitter.com/` + this.props.config.social.twitterUsername}>
                                @{this.props.config.social.twitterUsername}
                            </a>
                        </li>
                    </ul>

                    <br/>
                    <br/>

                    <p>You can also find us on:</p>

                    <ul className="about-links">
                        <li className="item">
                            <FontAwesomeIcon className="icon" fixedWidth icon={faInstagram}/>
                            <a className="link" rel="noreferrer" target="_blank" href={`https://www.instagram.com/` + this.props.config.social.instagramId}>Instagram</a>
                        </li>
                        <li className="item">
                            <FontAwesomeIcon className="icon" fixedWidth icon={faYoutube}/>
                            <a className="link" rel="noreferrer" target="_blank" href={`https://www.youtube.com/channel/` + this.props.config.social.youTubeChannelId}>YouTube</a>
                        </li>
                    </ul>
                </section>
                
            </div>
        )
    }
}

export default basePageWrapper(About)