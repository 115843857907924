import './index.scss'

import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faRoad, faUserEdit, faUserCircle , faCarSide, faMotorcycle, faTrashAlt, faCloudUploadAlt, faPlusCircle, faExclamationCircle, faSync, faBook, faUserSlash } from '@fortawesome/pro-solid-svg-icons'

import PasswordField from '../../components/PasswordField'

import validation from '../../utils/validation';
import images from '../../utils/images';
import format from '../../utils/format';
import statusCode from '../../utils/apiStatusCodes';

class Profile extends React.Component {
    static maxRides = 10;

    constructor(props) {
        super(props)

        document.title = 'User Info - Spirited Drive';
        document.querySelector('head > meta[name="description"]').setAttribute('content', 'User profile page');

        this.props.pageType.setFull();
    }

    state = {
        isLoading: false,
        isNotFound: false,
        isSubmitted: false,
        authUser: null,
        user: null,
        userEdit: null,
        savingUser: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.username !== this.props.match.params.username) {
            this.componentDidMount();
        }
    }
    
    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });

        this.props.authUserStateReady(authUser => {
            let username = this.props.match.params.username;
            if (!username) {
                if (authUser) {
                    username = authUser.username;
                    this.props.history.replace('/profile/' + authUser.username);
                }
                else {
                    this.props.history.replace('/profile/sign-in');
                    return;
                }
            }

            this.setState({ authUser: authUser }, () => {;
                this.setState({ isLoading: true });

                this.fetchUser(username)
                    .then(user => {
                        if (user) {
                            document.title = user.fullname + ' - Spirited Drive';

                            let metaDesciption = 'Profile page for ' + user.fullname;
                            if (user.bio) {
                                metaDesciption = user.bio;
                            }

                            document.querySelector('head > meta[name="description"]').setAttribute('content', metaDesciption);

                            this.populateOpenGraph(user);
                        }
                        else {
                            document.title = '404: Driver Not Found - Spirited Drive';
                            this.props.pageType.set404();
                        }

                        this.setState({
                            isLoading: false,
                            isNotFound: user === null,
                            user: user
                        });
                    });
            });
        });
    }

    fetchUser = (username) => {
        let url = this.props.config.apiUrl + '/v1/users/' + username;

        return this.props.fetcher.get(url).then(response => {
            let user = response.record;
            if (user) {
                user.noSocial = 
                    !user.twitterHandle &&
                    !user.instagramHandle &&
                    !user.youTubeUrl &&
                    !user.website;

                user.isAuthUser = this.state.authUser && this.state.authUser.id === user.id;
            }

            return response.code === 44 ? null : response.record;
        });
    }

    populateOpenGraph = (user) => {
        this.props.populateOpenGraph({
            title: user.fullname,
            description: user.bio ? user.bio : 'Profile page for ' + user.fullname,
            twitterUsername: user.twitterHandle,
            url: window.location.origin + '/profile/' + user.username,
            imageUrl: user.avatarImages ? user.avatarImages.large : this.props.config.cdnUrl + '/images/default-profile.jpeg',
            imageAlt: user.avatarImages ? 'Avatar image of ' + user.fullname : null,
            type: 'profile',
            username: user.username
        });
    }

    startEdit = () => {
        let user = this.state.user;

        let userEdit = {
            username: { val: user.username, err: null },
            fullname: { val: user.fullname, err: null },
            email: { val: user.email, err: null },
            password: { val: '', err: null },
            twitterHandle: { val: user.twitterHandle || '', err: null },
            instagramHandle: { val: user.instagramHandle || '', err: null },
            youTubeUrl: { val: user.youTubeUrl || '', err: null },
            website: { val: user.website || '', err: null },
            bio: { val: user.bio || '', err: null },
            avatarUrl: user.avatarImages ? user.avatarImages.medium : null,
            avatarImageLoading: false,
            rides: user.rides.map(ride => {
                return {
                    id: ride.id,
                    name: { val: ride.name, err: null },
                    description: { val: ride.description, err: null },
                    imageUrl: ride.images ? ride.images.small : null,
                    imageLoading: false
                }
            })
        };

        this.setState({
            userEdit: userEdit
        });
    }

    cancelEdit = () => {
        if (this.state.savingUser) return;

        this.setState({
            isSubmitted: false,
            userEdit: null
        });
    }

    uploadRideImage = (event, ride) => {
        let file = event.target.files[0];
        if (!file) return;

        let userEdit = { ...this.state.userEdit }
        let userRide = userEdit.rides[this.state.userEdit.rides.indexOf(ride)];
        if (userRide.imageLoading) return;

        userRide.imageLoading = true;
        this.setState({ userEdit: userEdit });

        images.resize(file)
            .then(dataUrl => userRide.imageUrl = dataUrl)
            .catch(() => userRide.imageUrl = null)
            .finally(() => {
                userRide.imageLoading = false;
                this.setState({ userEdit: userEdit });
        
                event.target.value = '';
            });
    }

    uploadAvatarImage = (event) => {
        let file = event.target.files[0];
        if (!file) return;

        let userEdit = { ...this.state.userEdit }
        if (userEdit.avatarImageLoading) return;

        userEdit.avatarImageLoading = true;
        this.setState({ userEdit: userEdit });

        images.cropAndResize(file, 1000, 1000)
            .then(dataUrl => userEdit.avatarUrl = dataUrl)
            .catch(() => userEdit.avatarUrl = null)
            .finally(() => {
                userEdit.avatarImageLoading = false;
                this.setState({ userEdit: userEdit });
        
                event.target.value = '';
            });
    }

    clearAvatarImage = () => {
        let userEdit = { ...this.state.userEdit }
        userEdit.avatarUrl = null;
        this.setState({ userEdit: userEdit });
    }

    clearRideImage = (ride) => {
        let userEdit = { ...this.state.userEdit }

        let userRide = userEdit.rides[this.state.userEdit.rides.indexOf(ride)];
        userRide.imageUrl = null;

        this.setState({
            userEdit: userEdit
        });
    }

    addRide = () => {
        if (this.state.userEdit.rides.length >= Profile.maxRides) return;

        let userEdit = { ...this.state.userEdit }
        userEdit.rides.push({
            id: null,
            name: { val: '', err : null },
            description: { val: '', err: null },
            imageUrl: null
        });

        this.setState({
            userEdit: userEdit
        });
    }

    removeRide = (ride) => {
        let userEdit = { ...this.state.userEdit }
        let index = userEdit.rides.indexOf(ride);
        userEdit.rides.splice(index, 1);

        this.setState({
            userEdit: userEdit
        });
    }

    handleInputChange = (event) => {
        if (event.target.name === 'username') {
            event.target.value = format.sanitiseUsername(event.target.value, event.target.maxLength);
        }

        this.setState({ userEdit: validation.handleInputChange(event.target, this.state.userEdit) });
    }

    handleSave = (event) => {
        event.preventDefault();

        this.setState({
            isSubmitted: true
        });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.savingUser) return;
        this.setState({ savingUser: true });

        let user = this.state.userEdit;
        let avartarImageData = user.avatarUrl && user.avatarUrl.indexOf('data:image/jpeg;') === 0 ? user.avatarUrl : null;

        let twitterHandle = user.twitterHandle.val.trim() || null;
        if (twitterHandle) {
            let twitterUrlMatch = twitterHandle.match(/^https:\/\/twitter.com\/([a-z0-9_]+)\/?$/i);
            if (twitterUrlMatch) {
                twitterHandle = twitterUrlMatch[1];
            }
        }

        let instagramHandle = user.instagramHandle.val.trim() || null;
        if (instagramHandle) {
            let instagramUrlMatch = instagramHandle.match(/^https:\/\/www.instagram.com\/([a-z0-9_.]+)\/?$/i);
            if (instagramUrlMatch) {
                instagramHandle = instagramUrlMatch[1];
            }
        }

        let data = {
            username: user.username.val.trim(),
            fullname: user.fullname.val.trim(),
            email: user.email.val.trim(),
            password: user.password.val.trim() || null,
            avatarImageData: avartarImageData,
            clearAvatarImage: !user.avatarUrl,
            bio: user.bio.val.trim() || null,
            twitterHandle: twitterHandle,
            instagramHandle: instagramHandle,
            youTubeUrl: user.youTubeUrl.val.trim() || null,
            website: user.website.val.trim() || null,
            isAdmin: user.isAdmin,
            isConfirmed: true,
            rides: []
        };

        user.rides.forEach(ride => {
            let imageData = ride.imageUrl && ride.imageUrl.indexOf('data:image/jpeg;') === 0 ? ride.imageUrl : null;

            data.rides.push({
                id: ride.id,
                name: ride.name.val.trim(),
                description: ride.description.val.trim(),
                imageData: imageData,
                clearImage: !ride.imageUrl
            })
        });

        let updateUrl = this.props.config.apiUrl + '/v1/users/' + this.state.user.id;

        this.props.fetcher.put(updateUrl, data).then(response => {
            if (response.code === statusCode.bespokeModelError) {
                const usernameExists = 1
                const emailExists = 2
                const usernameAndEmailExists = 3

                if (response.subCode === usernameExists || response.subCode === usernameAndEmailExists) {
                    let errorMessage = 'Username already exists.';
                    this.setState({ userEdit: validation.markFieldInvalid(event.target, this.state.userEdit, 'username', errorMessage) });
                }

                if (response.subCode === emailExists || response.subCode === usernameAndEmailExists) {
                    let errorMessage = 'Email already exists.';
                    this.setState({ userEdit: validation.markFieldInvalid(event.target, this.state.userEdit, 'email', errorMessage) });
                }

                this.setState({ savingUser: false });
                return;
            }

            if (response.code !== 0) {
                console.error(response.code + ': ' + response.message);
                return;
                // TODO: handle error
            }

            // redirect if username changes to update URL
            if (this.state.user.username !== data.username) {
                this.props.history.replace('/profile/' + data.username);
            }

            this.fetchUser(data.username)
                .then(user => {
                    this.populateOpenGraph(user);

                    this.setState({
                        savingUser: false,
                        isSubmitted: false,
                        userEdit: null,
                        isNotFound: user === null,
                        user: user
                    });
                });
        });
    }

    handleImageError = (event, backupImage) => {
        let backupAttempted = event.target.getAttribute('data-backup-attempted') === 'true';
        if (backupAttempted) return;

        event.target.src = this.props.config.cdnUrl + '/rides/' + backupImage;
        event.target.setAttribute('data-backup-attempted', 'true');
    }

    render() {
        return (
            <div className="main-page-full profile-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to={`/profile/${this.props.match.params.username}`} aria-current="location" className="link">Profile</NavLink></li>
                    </ol>
                </nav>

                {this.state.isNotFound &&
                    <div className="not-found-content">
                        <h1 className="title"><FontAwesomeIcon className="icon" icon={faUserSlash}/> 404: Spirited Driver Not Found</h1>

                        <p>
                            Sorry, but we couldn't locate this driver. If you came from another
                            site, they might need to update their links.
                        </p>

                        <p>
                            In the meantime try <NavLink to="/">navigating to the home page</NavLink>,
                            and searching for exciting driving roads near where you live.
                        </p>

                        <p>Thank you!</p>
                    </div>
                }

                {this.state.isLoading &&
                    <div className="loading-detail-page">
                        <p className="loading-message" aria-label="Loading user profile, please wait...">
                            <FontAwesomeIcon className="icon" icon={faSync} spin={true}/>
                        </p>
                    </div>
                }

                {this.state.user && !this.state.userEdit &&
                    <article className="profile">
                        <div className="profile-details">
                            <h1 className="profile-title">{this.state.user.fullname}</h1>

                            <div className="joined-actions">
                                <p className="joined">
                                    <strong className="username">@{this.state.user.username}</strong>
                                    <time className="datetime" dateTime={this.state.user.created}>
                                        Joined: {format.date(this.state.user.created)}
                                    </time>
                                </p>

                                {this.state.authUser && (this.state.user.id === this.state.authUser.id || this.state.authUser.isAdmin) &&
                                    <button className="button cta edit-profile-link" onClick={this.startEdit}>
                                        <FontAwesomeIcon className="icon" icon={faUserEdit}/>
                                        <span className="text">Edit<span className="superfluous"> Your Profile</span></span>
                                    </button>
                                }
                            </div>

                            {!this.state.user.noSocial &&
                                <ul className="social-links">
                                    {this.state.user.twitterHandle &&
                                        <li className="item twitter">
                                            <a href={`https://twitter.com/${this.state.user.twitterHandle}`} target="_blank" rel="noreferrer" className="link">
                                                <FontAwesomeIcon className="icon" icon={faTwitter} fixedWidth/>
                                                <span className="text">@{this.state.user.twitterHandle}</span>
                                            </a>
                                        </li>
                                    }

                                    {this.state.user.instagramHandle &&
                                        <li className="item instagram">
                                            <a href={`https://www.instagram.com/${this.state.user.instagramHandle}`} target="_blank" rel="noreferrer" className="link">
                                                <FontAwesomeIcon className="icon" icon={faInstagram} fixedWidth/>
                                                <span className="text">@{this.state.user.instagramHandle}</span>
                                            </a>
                                        </li>
                                    }

                                    {this.state.user.youTubeUrl &&
                                        <li className="item youtube">
                                            <a href={this.state.user.youTubeUrl} target="_blank" rel="noreferrer" className="link">
                                                <FontAwesomeIcon className="icon" icon={faYoutube} fixedWidth/>
                                                <span className="text">Visit YouTube Channel</span>
                                            </a>
                                        </li>
                                    }

                                    {this.state.user.website &&
                                        <li className="item website">
                                            <span className="label">Website:</span>

                                            <a href={this.state.user.website} target="_blank" rel="noreferrer" className="link">
                                                <span className="text">{this.state.user.website}</span>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            }

                            {this.state.user.rides.length === 0 && this.state.user.isAuthUser &&
                                <section className="rides">
                                    <h2 className="rides-title">My Rides</h2>

                                    <p className="no-rides">
                                        <FontAwesomeIcon className="icon" icon={faCarSide} fixedWidth/>
                                        <FontAwesomeIcon className="icon" icon={faMotorcycle} fixedWidth/>
                                        You have no rides. Edit your profile to let people know what you drive or ride.
                                    </p>
                                </section>
                            }

                            {this.state.user.rides.length > 0 &&
                                <section className="rides">
                                    <h2 className="rides-title">My Ride{this.state.user.rides.length > 1 ? 's' : ''}</h2>

                                    {this.state.user.rides.map(ride => 
                                        <article key={ride.id} className="ride">
                                            <div className="details">
                                                <h3 className="name">{ride.name}</h3>
                                                <div className="description">
                                                    {format.textBlock(ride.description)}
                                                </div>
                                            </div>

                                            <figure className="ride-image">
                                                {ride.images &&
                                                    <img className="polaroid" alt={ride.name} src={ride.images.small}/>
                                                }
                                                {!ride.images &&
                                                    <FontAwesomeIcon className="polaroid no-image-icon" icon={faCarSide}/>
                                                }
                                            </figure>
                                        </article>
                                    )}
                                </section>
                            }

                            {this.state.user.numDrives > 0 &&
                                <NavLink to={`/profile/` + this.state.user.username + `/drives`} className="view-drives-link">
                                    <FontAwesomeIcon className="icon" icon={faRoad}/>
                                    <span className="text">View Drives ({this.state.user.numDrives})</span>
                                </NavLink>
                            }
                        </div>

                        <aside className="avatar-bio">
                            <figure className="avatar-image">
                                {this.state.user.avatarImages && 
                                    <img className="image polaroid" alt={this.state.user.username + ` avatar`} src={this.state.user.avatarImages.medium}
                                        onError={(e) => this.handleImageError(e, 'e3aeb538-85e4-11eb-b30e-87b621d3fe33-medium.jpeg')}/>
                                }
                                {!this.state.user.avatarImages && 
                                    <FontAwesomeIcon className="polaroid no-image-icon" icon={faUserCircle}/>
                                }
                            </figure>

                            {this.state.user.bio &&
                                <div className="bio">{format.textBlock(this.state.user.bio)}</div>
                            }

                            {!this.state.user.bio && this.state.user.isAuthUser &&
                                <div className="no-bio">
                                    <FontAwesomeIcon className="icon" icon={faBook}/>
                                    <p className="text">You have no bio. Update your profile to tell other Spirited Drivers about yourself.</p>
                                </div>
                            }
                        </aside>
                    </article>
                }

                {this.state.userEdit &&
                    <form onSubmit={this.handleSave} className={`profile edit-profile ` + (this.state.isSubmitted ? 'submitted' : '')} noValidate>
                        <div className="profile-details">
                            <h1 className="profile-title">{this.state.userEdit.fullname.val || '-'}</h1>

                            <div className="joined-actions">
                                <p className="joined">
                                    <strong className="username">@{this.state.userEdit.username.val}</strong>
                                    <time className="datetime" dateTime={this.state.user.created}>
                                        Joined: {format.date(this.state.user.created)}
                                    </time>
                                </p>

                                <div className="edit-profile-actions">
                                    <button type="button" className="link-button cancel-profile" onClick={this.cancelEdit}>cancel</button>
                                    <button type="submit" className="button cta save-profile">
                                        <FontAwesomeIcon className="icon" spin={this.state.savingUser} icon={this.state.savingUser ? faSync : faUserEdit} fixedWidth={true}/>
                                        <span className="text">Save<span className="superfluous"> Profile</span></span>
                                    </button>
                                </div>
                            </div>

                            <fieldset className="form profile-details-form">
                                <legend className="screen-reader-only">Profile details</legend>

                                <div className="form-row stacked">
                                    <label htmlFor="username" className="label">Username *</label>
                                    <div className="control">
                                        <input id="username" name="username" type="text" className="textbox" required minLength="3" maxLength="30"
                                            value={this.state.userEdit.username.val} onChange={this.handleInputChange}/>
                                    </div>

                                    {this.state.userEdit.username.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.userEdit.username.err}
                                        </p>
                                    }
                                </div>

                                <div className="form-row stacked">
                                    <label htmlFor="fullname" className="label">Fullname *</label>
                                    <div className="control">
                                        <input id="fullname" name="fullname" type="text" className="textbox" required minLength="2" maxLength="50"
                                            value={this.state.userEdit.fullname.val} onChange={this.handleInputChange}/>
                                    </div>

                                    {this.state.userEdit.fullname.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.userEdit.fullname.err}
                                        </p>
                                    }
                                </div>

                                <div className="form-row stacked">
                                    <label htmlFor="email" className="label">Email *</label>
                                    <div className="control">
                                        <input id="email" name="email" type="email" className="textbox" required
                                            value={this.state.userEdit.email.val} onChange={this.handleInputChange}/>
                                    </div>

                                    {this.state.userEdit.email.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.userEdit.email.err}
                                        </p>
                                    }
                                </div>

                                <PasswordField className="form-row" id="password" value={this.state.userEdit.password.val}
                                    label="Password" optionalLabel="(leave blank to use current password)" required={false}
                                    onChange={this.handleInputChange} error={this.state.isSubmitted && this.state.userEdit.password.err} />
                            </fieldset>

                            <fieldset className="form profile-details-form">
                                <div className="form-row inline">
                                    <div className="label-control">
                                        <label htmlFor="twitterHandle" className="label">
                                            <FontAwesomeIcon className="icon" icon={faTwitter} fixedWidth/>
                                        </label>
                                        <div className="control">
                                            <input id="twitterHandle" name="twitterHandle" type="text" className="textbox"
                                                placeholder="Your Twitter @username or profile URL"
                                                pattern="^(https:\/\/twitter.com\/[a-zA-Z0-9_]+\/?)|(@?[a-zA-Z0-9_]+)$"
                                                data-error-pattern="Must be a valid Twitter profile URL or @username"
                                                value={this.state.userEdit.twitterHandle.val} onChange={this.handleInputChange}/>

                                            {this.state.userEdit.twitterHandle.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.userEdit.twitterHandle.err}
                                                </p>
                                            }
                                        </div>
                                        <span className="optional-label">(optional)</span>
                                    </div>
                                </div>

                                <div className="form-row inline">
                                    <div className="label-control">
                                        <label htmlFor="instagramHandle" className="label">
                                            <FontAwesomeIcon className="icon" icon={faInstagram} fixedWidth/>
                                        </label>
                                        <div className="control">
                                            <input id="instagramHandle" name="instagramHandle" type="text" className="textbox"
                                                placeholder="Your Instagram @username or profile URL"
                                                pattern="^(https:\/\/www.instagram.com\/[a-zA-Z0-9_.]+\/?)|(@?[a-zA-Z0-9_.]+)$"
                                                data-error-pattern="Must be a valid Instagram profile URL or @username"
                                                value={this.state.userEdit.instagramHandle.val} onChange={this.handleInputChange}/>
                                        </div>
                                        <span className="optional-label">(optional)</span>
                                    </div>
                                </div>

                                <div className="form-row inline">
                                    <div className="label-control">
                                        <label htmlFor="youTubeUrl" className="label">
                                            <FontAwesomeIcon className="icon" icon={faYoutube} fixedWidth/>
                                        </label>
                                        <div className="control">
                                            <input id="youTubeUrl" name="youTubeUrl" type="url" className="textbox"
                                                placeholder="YouTube channel URL"
                                                pattern="^https:\/\/(www.youtube.com\/(channel|user|c|u)\/.+)|(youtu.be\/.+)$"
                                                data-error-pattern="Must be a valid YouTube channel page URL"
                                                value={this.state.userEdit.youTubeUrl.val} onChange={this.handleInputChange}/>

                                            {this.state.userEdit.youTubeUrl.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.userEdit.youTubeUrl.err}
                                                </p>
                                            }
                                        </div>
                                        <span className="optional-label">(optional)</span>
                                    </div>
                                </div>

                                <div className="form-row stacked">
                                    <label htmlFor="website" className="label">Website <span className="optional-label">(optional)</span></label>
                                    <input id="website" name="website" type="url" className="textbox"
                                        value={this.state.userEdit.website.val} onChange={this.handleInputChange}/>
                                </div>
                            </fieldset>

                            <section className="rides">
                                <h2 className="rides-title">My Ride{this.state.userEdit.rides.length > 1 ? 's' : ''} ({this.state.userEdit.rides.length})</h2>

                                {this.state.userEdit.rides.map((ride, i) =>
                                    <fieldset key={i} className="form ride-form">
                                        <legend className="with-button">
                                            <h3 className="text">{ride.name.val || `New Ride`}</h3>
                                            <button type="button" className="button remove-ride" onClick={() => this.removeRide(ride)}>
                                                <FontAwesomeIcon className="icon" icon={faTrashAlt}/> Remove Ride
                                            </button>
                                        </legend>

                                        <div className="name-image-box">
                                            <div className="name-box">
                                                <div className="form-row stacked">
                                                    <label htmlFor={`ride-name-${i}`} className="label">Name *</label>
                                                    <input id={`ride-name-${i}`} name={`rides[${i}].name`} type="text" className="textbox"
                                                        required maxLength="60" value={ride.name.val} onChange={this.handleInputChange}/>
                                                </div>

                                                <div className="form-row stacked">
                                                    <label htmlFor={`ride-description-${i}`} className="label">
                                                        Description <span className="optional-label">(optional)</span>
                                                    </label>
                                                    <textarea id={`ride-description-${i}`} name={`rides[${i}].description`} className="ride-description"
                                                        maxLength="500" value={ride.description.val} onChange={this.handleInputChange}></textarea>
                                                </div>
                                            </div>
                                            <figure className="image-box">
                                                {ride.imageUrl &&
                                                    <img className="polaroid" alt={ride.name.val} src={ride.imageUrl}/>
                                                }
                                                {!ride.imageUrl &&
                                                    <FontAwesomeIcon className="polaroid no-image-icon" icon={faCarSide}/>
                                                }

                                                <div className="image-options">
                                                    {ride.imageUrl &&
                                                        <button type="button" className="link-button"
                                                            onClick={() => this.clearRideImage(ride)}>
                                                            <FontAwesomeIcon className="icon" icon={faTrashAlt}/> remove
                                                        </button>
                                                    }
                                                    <div className="file-upload">
                                                        <input id={`ride-upload-${i}`} type="file" className="file"
                                                            onChange={(e) => this.uploadRideImage(e, ride)}
                                                            accept="image/png, image/jpeg, image/heic"/>
                                                        <label htmlFor={`ride-upload-${i}`} className="button upload-image">
                                                            <FontAwesomeIcon className="icon" icon={faCloudUploadAlt}/> Upload
                                                        </label>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </fieldset>
                                )}

                                {this.state.userEdit.rides.length < Profile.maxRides &&
                                    <footer className="ride-actions">
                                        <button type="button" className="button add-ride" onClick={this.addRide}>
                                            <FontAwesomeIcon className="icon" icon={faPlusCircle}/> Add New Ride
                                        </button>
                                    </footer>
                                }
                            </section>
                        </div>

                        <div className="avatar-bio">
                            <figure className="avatar-image">
                                {this.state.userEdit.avatarUrl && 
                                    <img className="image polaroid" alt={this.state.userEdit.username.val + ` avatar`} src={this.state.userEdit.avatarUrl}/>
                                }
                                {!this.state.userEdit.avatarUrl && 
                                    <FontAwesomeIcon className="polaroid no-image-icon" icon={faUserCircle}/>
                                }

                                <div className="avatar-actions">
                                    {this.state.userEdit.avatarUrl &&
                                        <button type="button" className="link-button" onClick={() => this.clearAvatarImage()}>
                                            <FontAwesomeIcon className="icon" icon={faTrashAlt}/> remove
                                        </button>
                                    }
                                    <div className="file-upload">
                                        <input id="avatar-upload" type="file" className="file" onChange={(e) => this.uploadAvatarImage(e)}
                                            accept="image/png, image/jpeg, image/heic"/>
                                        <label htmlFor="avatar-upload" className="button upload-avatar">
                                            <FontAwesomeIcon className="icon" icon={faCloudUploadAlt}/> Upload Profile Picture
                                        </label>
                                    </div>
                                </div>
                            </figure>

                            <fieldset className="form">
                                <div className="form-row stacked">
                                    <label htmlFor="bio" className="label">Bio <span className="optional-label">(optional - max 500 characters)</span></label>
                                    <textarea id="bio" name="bio" className="bio-description" maxLength="500"
                                        value={this.state.userEdit.bio.val} onChange={this.handleInputChange}></textarea>
                                </div>
                            </fieldset>
                        </div>
                    </form>
                }
            </div>
        )
    }
}

export default basePageWrapper(Profile)