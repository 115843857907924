import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faEdit, faTrashAlt, faExclamationCircle, faLock, faSync, faPlusCircle } from '@fortawesome/pro-solid-svg-icons'

import validation from '../../utils/validation'

import ModalPortal from './ModalPortal'

import './EditDrive.scss'
import { NavLink } from 'react-router-dom'

class EditDrive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                name: {
                    val: props.drive ? props.drive.name : '',
                    err: null
                },
                startAddress: {
                    val: props.drive ? props.drive.startAddress : '',
                    err: null
                },
                endAddress: {
                    val: props.drive ? props.drive.endAddress : '',
                    err: null
                },
                generalLocation: {
                    val: props.drive ? props.drive.generalLocation : '',
                    err: null
                },
                roadNames: {
                    val: props.drive ? (props.drive.roadNames || '').join(', ') : '',
                    err: null
                },
                description: {
                    val: props.drive ? (props.drive.description || '') : '',
                    err: null
                }
            },
            roadTypes: {
                selected: '',
                list: props.drive.roadTypes || [],
                canAdd: false
            },
            isLoop: props.drive ? props.drive.isLoop : false,
            adminMode: props.adminMode || false,
            saving: false,
            deleteing: false,
            isSubmitted: false
        };
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleSave = (event) => {
        if (!this.props.handleSave) return;

        event.preventDefault();

        if (this.state.saving || this.state.deleting) return;
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        this.setState({ saving: true });

        let roadNames = [];
        this.state.form.roadNames.val.split(',').forEach(name => {
            let sanitisedName = name.trim();
            if (sanitisedName) {
                roadNames.push(sanitisedName);
            }
        });

        let roadTypes = [];
        this.state.roadTypes.list.forEach(type => {
            roadTypes.push({
                id: type.id,
                proportion: type.proportion
            });
        });

        this.props.handleSave({
            name: this.state.form.name.val,
            startAddress: this.state.form.startAddress.val,
            endAddress: this.state.form.endAddress.val,
            generalLocation: this.state.form.generalLocation.val,
            description: this.state.form.description.val,
            roadNames: roadNames,
            roadTypes: roadTypes
        }, () => {
            this.setState({ saving: false });
        });
    }

    handleDelete = () => {
        if (!this.props.handleDelete) return;

        if (this.state.deleting || this.state.saving) return;
        if (!window.confirm('Are you sure you want to delete this drive?')) return;

        this.setState({ deleting: true });

        this.props.handleDelete(() => {
            this.setState({ deleting: false });
        });
    }

    handleRoadTypeChange = (e) => {
        let canAdd = e.target.value && !this.state.roadTypes.list.some(t => t.id === e.target.value)

        this.setState({
            roadTypes: {
                ...this.state.roadTypes,
                selected: e.target.value,
                canAdd: canAdd
            }
        });
    }

    addRoadType = (e) => {
        e.preventDefault();

        let selectedType = this.props.roadTypes.find(rt => rt.id === this.state.roadTypes.selected);
        if (!selectedType || this.state.roadTypes.list.some(t => t.id === selectedType.id)) return;

        let list = [...this.state.roadTypes.list];

        let proportionPerItem = Math.floor(100 / (list.length + 1));

        list.forEach(type => {
            type.proportion = proportionPerItem;
        });
        
        list.push({
            id: selectedType.id,
            name: selectedType.name,
            proportion: proportionPerItem
        });

        this.setState({
            roadTypes: {
                ...this.state.roadTypes,
                list: list,
                canAdd: false
            }
        });
    }

    removeRoadType = (typeId) => {
        let list = [...this.state.roadTypes.list];

        let toRemove = list.find(t => t.id === typeId);
        if (!toRemove) return;

        let index = list.indexOf(toRemove);
        if (index !== -1) {
            list.splice(index, 1);
        }

        let selected = this.state.roadTypes.selected;
        let canAdd = selected && !list.some(t => t.id === selected.id);

        if (list.length > 0) {
            let proportionPerItem = Math.floor(100 / list.length);

            list.forEach(type => {
                type.proportion = proportionPerItem;
            });
        }

        let lockedIndex = this.lockedOrder.indexOf(toRemove.id);
        if (lockedIndex !== -1) {
            this.lockedOrder.slice(lockedIndex, 1);
        }

        this.setState({
            roadTypes: {
                ...this.state.roadTypes,
                list: list,
                canAdd: canAdd
            }
        });
    }

    lockedOrder = [];

    roadTypeRangeChanged = (event, typeId) => {
        let proportion = Number(event.target.value);
        
        let list = [...this.state.roadTypes.list];

        let thisType = list.find(t => t.id === typeId);
        if (!thisType || list.length === 1) return;

        let maxProportion = 100 - (list.length - 1)
        proportion = proportion > maxProportion ? maxProportion : proportion;

        if (list.length === 2) {
            list.forEach(type => {
                if (type.id === typeId) {
                    type.proportion = proportion;
                }
                else {
                    type.proportion = 100 - proportion;
                }
            });
        }
        else {
            thisType.proportion = proportion;
            thisType.locked = true;

            if (!this.lockedOrder.includes(thisType.id)) {
                this.lockedOrder.push(thisType.id);
            }

            // unlock an adjacent item if all locked
            let allLocked = list.every(t => t.locked);
            if (allLocked) {
                let toUnlockId = this.lockedOrder.shift();
                let toUnlock = list.find(t => t.id === toUnlockId);
                if (toUnlock) {
                    toUnlock.locked = false;
                }
            }

            let remainderProportionPerItem = 100;
            list.forEach(type => {
                if (type.locked) remainderProportionPerItem -= type.proportion;
            });

            let numUnlocked = list.filter(t => !t.locked).length;

            if (remainderProportionPerItem < numUnlocked) {
                let numOtherLocked = list.filter(t => t.locked).length - 1;
                if (numOtherLocked > 0) {
                    let reduction = Math.floor((Math.abs(remainderProportionPerItem) / numOtherLocked)) + numUnlocked;
                    list.forEach(type => {
                        if (type.locked && type !== thisType) {
                            type.proportion -= reduction;
                            if (type.proportion < 1) {
                                type.proportion = 1;
                            }
                        }
                    });
                }

                remainderProportionPerItem = numUnlocked;
            }

            remainderProportionPerItem = Math.floor(remainderProportionPerItem / numUnlocked);

            list.forEach(type => {
                if (type.id !== typeId && !type.locked) {
                    type.proportion = remainderProportionPerItem;
                }
            });
        }

        this.setState({
            roadTypes: {
                ...this.state.roadTypes,
                list: list
            }
        });
    }

    close = () => {
        if (this.state.deleting || this.state.saving) return;

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (
            <ModalPortal className="standard-modal drive-edit-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faEdit}/>
                        <span className="text">Edit Drive</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>

                <div className="main">
                    {this.props.isLocked && <>
                        <p>
                            <FontAwesomeIcon className="icon icon-floated" icon={faLock}/> Sorry,
                            but this drive is locked for editing/deleting because another
                            user has added a review or an image to it.
                        </p>

                        <p>
                            If you want to modify or remove this drive,
                            please <NavLink to="/about/contact">contact an admin</NavLink> for help.
                        </p>
                    </>}

                    {!this.props.isLocked &&
                        <form id="editDriveForm" onSubmit={this.handleSave} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                            <div className="form-row">
                                <label htmlFor="editDrive_name" className="label">Name *</label>
                                <div className="control">
                                    <input id="editDrive_name" name="name" type="text" className="textbox"
                                        required minLength="5" maxLength="80" placeholder="Drive name"
                                        value={this.state.form.name.val} onChange={this.handleInputChange}/>

                                    {this.state.form.name.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.name.err}
                                        </p>
                                    }
                                </div>
                            </div>

                            {this.state.adminMode && <>
                                <fieldset className="legend-form edit-drive-location-form">
                                    <legend>Location Details</legend>

                                    <div className="form-row inline-row">
                                        <label htmlFor="editDrive_startAddress" className="label">
                                            Start{this.state.isLoop && <span>/End</span>} *
                                        </label>
                                        <div className="control">
                                            <input id="editDrive_startAddress" name="startAddress" type="text" className="textbox"
                                                required minLength="3" maxLength="50" placeholder="place name, postal code"
                                                value={this.state.form.startAddress.val} onChange={this.handleInputChange}/>

                                            {this.state.form.startAddress.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.startAddress.err}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    {!this.state.isLoop &&
                                        <div className="form-row inline-row">
                                            <label htmlFor="editDrive_endAddress" className="label">End *</label>
                                            <div className="control">
                                                <input id="editDrive_endAddress" name="endAddress" type="text" className="textbox"
                                                    required minLength="3" maxLength="50" placeholder="place name, postal code"
                                                    value={this.state.form.endAddress.val} onChange={this.handleInputChange}/>

                                                {this.state.form.endAddress.err && this.state.isSubmitted &&
                                                    <p className="validation-error">
                                                        <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.endAddress.err}
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className="form-row inline-row">
                                        <label htmlFor="editDrive_generalLocation" className="label">General *</label>
                                        <div className="control">
                                            <input id="editDrive_generalLocation" name="generalLocation" type="text" className="textbox"
                                                required minLength="3" maxLength="50" placeholder="state/locale, country code"
                                                value={this.state.form.generalLocation.val} onChange={this.handleInputChange}/>

                                            {this.state.form.generalLocation.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.generalLocation.err}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </fieldset>
                            </>}

                            <fieldset className="legend-form edit-drive-roads-form">
                                <legend>Road Information</legend>

                                <div className="form-row inline-row">
                                    <label htmlFor="editDrive_roadNames" className="label">Road Names</label>
                                    <div className="control">
                                        <input id="editDrive_roadNames" name="roadNames" type="text" className="textbox"
                                            maxLength="200" placeholder="A123, B456 etc"
                                            value={this.state.form.roadNames.val} onChange={this.handleInputChange}/>

                                        {this.state.form.roadNames.err && this.state.isSubmitted &&
                                            <p className="validation-error">
                                                <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.roadNames.err}
                                            </p>
                                        }
                                    </div>
                                </div>

                                <div className="form-row inline-row">
                                    <label htmlFor="editDrive_roadTypes" className="label">Road Types</label>
                                    <div className="control with-editable-list">
                                        <div className="controls">
                                            <select id="editDrive_roadTypes" value={this.state.roadTypes.selected} onChange={this.handleRoadTypeChange} onKeyPress={this.addRoadType}>
                                                <option value="">[ Select road type ]</option>
                                                {(this.props.roadTypes || []).map(type =>
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                )}
                                            </select>
                                            <button type="button" className="button icon-only" disabled={!this.state.roadTypes.canAdd}
                                                aria-label="Add road type" onClick={this.addRoadType}>
                                                <FontAwesomeIcon className="icon" icon={faPlusCircle}/>
                                            </button>
                                        </div>

                                        <div className="list-content">
                                            <ul className="linked-range-controls">
                                                {this.state.roadTypes.list.map(type =>
                                                    <li key={type.id} className="range-item">
                                                        <div className="main-controls">
                                                            <label className="range-name">{type.name}</label>
                                                            <div className="range-control">
                                                                <input type="range" className="range-input" min="1" max="100" step="1"
                                                                    value={type.proportion} onChange={(e) => this.roadTypeRangeChanged(e, type.id)}/>
                                                                <span className="range-value">{type.proportion}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="remove-controls">
                                                            <button type="button" className="icon-button" aria-label="Remove this road type"
                                                                onClick={() => this.removeRoadType(type.id)}>
                                                                <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                                                            </button>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div className="form-row">
                                <label htmlFor="editDrive_description" className="label">
                                    Description <span className="optional-label">(optional)</span>
                                </label>
                                <div className="control">
                                    <textarea id="editDrive_description" name="description" maxLength="2000" style={{ minHeight: '100px' }}
                                        value={this.state.form.description.val} onChange={this.handleInputChange}/>

                                    {this.state.form.description.err && this.state.isSubmitted &&
                                        <p className="validation-error">
                                            <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{this.state.form.description.err}
                                        </p>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </div>

                <footer className="footer">
                    {this.props.isLocked &&
                        <button type="button" className="button primary-button" onClick={this.close}>
                            <FontAwesomeIcon className="icon" icon={faTimesCircle}/> Close
                        </button>
                    }

                    {!this.props.isLocked && <>
                        <button type="submit" className="button primary-button" form="editDriveForm">
                            <FontAwesomeIcon className="icon" icon={this.state.saving ? faSync : faEdit} spin={this.state.saving}/> Save &amp; Close
                        </button>
                        <button type="button" className="link-button cancel-button" onClick={this.close}>Nevermind</button>

                        <span className="flex-gap"></span>
                        <button type="button" className="button warn delete-button" onClick={this.handleDelete}>
                            <FontAwesomeIcon className="icon" icon={this.state.deleting ? faSync : faTrashAlt} spin={this.state.deleting}/>
                            <span className="text">Delete<span className="superfluous"> Drive</span></span>
                        </button>
                    </>}
                </footer>
            </ModalPortal>
        );
    }
}

export default EditDrive