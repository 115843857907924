import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as fullStar, faStarHalfAlt as halfStar } from '@fortawesome/pro-solid-svg-icons'
import { faStar as emptyStar} from '@fortawesome/pro-regular-svg-icons'

class Stars extends React.Component {
    constructor(props) {
        super(props)

        let rating = props.rating || 0;
        rating = rating < 0 ? 0 : (rating > 5 ? 5 : rating);

        this.state = {
            rating: rating
        }
    }

    handleRating = (rating) => {
        this.setState({
            rating: rating
        });

        if (this.props.onChange) {
            this.props.onChange(rating);
        }
    }

    render() {
        let rating = this.state.rating;

        if (this.props.readOnly) {
            rating = this.props.rating || 0;
            rating = rating < 0 ? 0 : (rating > 5 ? 5 : rating);
        }

        let props = { ...this.props };
        delete props.readOnly;
        delete props.rating;

        let ariaLabel = props['aria-label'];
        if (!ariaLabel) {
            ariaLabel = rating ? 
                `Rating of ${rating} out of 5 stars` :
                `No rating has been assigned.`;
        }

        return (<>
            {this.props.readOnly &&
                <span {...props} data-rating={rating}>
                    <span className="screen-reader-only">{ariaLabel}</span>
                
                    <span className="star-icon">
                        {rating <= 0 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 0 && rating < 1 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 1 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </span>
                    <span className="star-icon">
                        {rating <= 1 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 1 && rating < 2 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 2 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </span>
                    <span className="star-icon">
                        {rating <= 2 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 2 && rating < 3 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 3 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </span>
                    <span className="star-icon">
                        {rating <= 3 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 3 && rating < 4 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 4 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </span>
                    <span className="star-icon">
                        {rating <= 4 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 4 && rating < 5 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 5 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </span>
                </span>
            }

            {!this.props.readOnly &&
                <div {...props} data-rating={rating} aria-label={ariaLabel}>
                    <button type="button" className="star-button" onClick={() => this.handleRating(1)}>
                        {rating <= 0 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 0 && rating < 1 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 1 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </button>
                    <button type="button" className="star-button" onClick={() => this.handleRating(2)}>
                        {rating <= 1 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 1 && rating < 2 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 2 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </button>
                    <button type="button" className="star-button" onClick={() => this.handleRating(3)}>
                        {rating <= 2 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 2 && rating < 3 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 3 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </button>
                    <button type="button" className="star-button" onClick={() => this.handleRating(4)}>
                        {rating <= 3 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 3 && rating < 4 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 4 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </button>
                    <button type="button" className="star-button" onClick={() => this.handleRating(5)}>
                        {rating <= 4 && <FontAwesomeIcon className="icon" icon={emptyStar}/>}
                        {rating > 4 && rating < 5 && <FontAwesomeIcon className="icon" icon={halfStar}/>}
                        {rating>= 5 && <FontAwesomeIcon className="icon" icon={fullStar}/>}
                    </button>
                </div>
            }
        </>)
    }
}

export default Stars