import React from 'react'

import basePageWrapper from './../BasePage'

import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFrown, faSyncAlt, faKey } from '@fortawesome/pro-solid-svg-icons'

import PasswordField from '../../components/PasswordField'
import validation from '../../utils/validation'

import './styles.scss'

class AccountResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitted: false,
            reseting: false,
            resetSuccess: false,
            authTokenInvalid: false,
            user: null,
            form: {
                password: {
                    val: '',
                    err: null
                }
            }
        }

        document.title = 'Reset My Password - Spirited Drive';
        let metaDescription = 'Reset your Spirited Drive password ';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    userId = null;
    token = null;

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });

        let querystring = this.props.location.search;
        let qs = {}; querystring.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });

        this.userId = qs.userId;
        this.token = qs.token;

        if (!this.userId || !this.token) {
            this.props.history.replace("/account/forgot-password");
            return;
        }

        let url = this.props.config.apiUrl + '/v1/accounts/whoami?' +
            'userId=' + encodeURIComponent(this.userId) + '&' +
            'token=' + encodeURIComponent(this.token);

        this.props.fetcher.get(url).then(response => {
            if (!response.record) {
                this.setState({ authTokenInvalid: true });
                return;
            }
            
            this.setState({ user: response.record });
        });
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        if (this.state.reseting) return;
        this.setState({ reseting: true });

        let url = this.props.config.apiUrl + '/v1/accounts/resetPassword';

        let data = {
            userId: this.userId,
            token: this.token,
            password: this.state.form.password.val
        };

        this.props.fetcher.put(url, data).then(response => {
            if (response.code !== 0) {
                // TODO: handle error
                console.error(response.code + ': ' + response.message);
                return;
            }

            this.setState({ resetSuccess: true });
        });
    }

    render() {
        return (
            <div className="main-page-full reset-password-page narrow-form-page">
                <header className="header">
                    <h1 className="title">Reset My Password</h1>
                    {this.state.user &&
                        <p className="intro">
                            Hello <strong>{this.state.user.fullname}</strong>. Use the form below to change your password.
                        </p>
                    }
                </header>

                <section className="inner">
                    {!this.state.authTokenInvalid && this.state.user &&
                        <fieldset className="form">
                            {!this.state.resetSuccess &&
                                <form onSubmit={this.handleSubmit} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                                    <PasswordField className="form-row" id="password" label="New Password" required={true} value={this.state.form.password.val}
                                        onChange={this.handleInputChange} error={this.state.isSubmitted && this.state.form.password.err} />

                                    <div className="form-row buttons centered">
                                        <button type="submit" className="button">
                                            <FontAwesomeIcon className="icon" fixedWidth spin={this.state.reseting} icon={this.state.reseting ? faSyncAlt : faKey}/>
                                            <span className="text">Reset Password</span>
                                        </button>
                                    </div>
                                </form>
                            }

                            {this.state.resetSuccess &&
                                <div className="reset-success">
                                    <p className="intro">
                                        Your password has been reset.
                                    </p>

                                    <FontAwesomeIcon className="icon" icon={faCheckCircle}/>

                                    <p>
                                        Sign-in to continue...
                                    </p>

                                    <div className="form-row buttons centered">
                                        <NavLink to="/account/sign-in" className="button chunky cta">Sign-in</NavLink>
                                    </div>
                                </div>
                            }
                        </fieldset>
                    }

                    {this.state.authTokenInvalid &&
                        <div className="form error">
                            <p className="intro">
                                Sorry, there was a problem retrieving your account details.
                            </p>

                            <FontAwesomeIcon className="icon" icon={faFrown}/>

                            <p>
                                Please try resetting your password again and follow the reset link in the email.
                                If the problem persists, contact an administrator.
                            </p>

                            <div className="form-row buttons centered">
                                <NavLink to="/account/forgot-password" className="button">Forgot My Password</NavLink>
                            </div>
                        </div>
                    }
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AccountResetPassword);