import React from 'react';
import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

import ModalPortal from './ModalPortal';

class Image extends React.Component {
    handleClose = () => {
        if (this.props.onModalClose) {
            this.props.onModalClose(this.props.imageId || null);
        }
    }

    formatCaption = (caption) => {
        caption = caption.trim();
        return caption + (caption.endsWith('.') ? '' : '.');
    }

    render() {
        return (
            <ModalPortal className="image-modal" onClose={this.handleClose}>
                <button type="button" className="icon-button close-button" aria-label="Close image" onClick={this.handleClose}>
                    <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                </button>

                <figure className="image-and-caption">
                    <div className="image-container">
                        <img className="image" src={this.props.src} alt={this.props.caption}/>
                    </div>

                    {(this.props.caption || this.props.source) &&
                        <figcaption className="caption">
                            {this.props.caption && <span className="text">{this.formatCaption(this.props.caption)}</span>}

                            {this.props.source &&
                                <span className="author">
                                    Photo by {this.props.sourceUrl && 
                                        <a className="link" href={this.props.sourceUrl} target="_blank" rel="noreferrer">{this.props.source}</a>
                                    }
                                    {!this.props.sourceUrl && this.props.source}
                                </span>
                            }

                            {this.props.uploader &&
                                <span className="uploader"> (uploaded by <NavLink className="link" to={`/profile/${this.props.uploader.username}`}>
                                        {this.props.uploader.username}
                                    </NavLink>)
                                </span>
                            }
                        </figcaption>
                    }
                </figure>
            </ModalPortal>
        )
    }
}

export default Image;