import React from 'react'

import { faChevronDown, faExternalLink, faMapMarkerAlt, faMapMarkerAltSlash, faPhone, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Stars from '../Stars';

import './Place.scss'

class Place extends React.Component {

    static init = (place, closehandler) => {
        const popup = document.querySelector('.place-info-window');
        if (!popup) return;

        let closeButton = popup.querySelector('.header .close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                if (closehandler) closehandler(place);
            });
        }

        let toggleOpeningButton = popup.querySelector('.opening-times .toggle-opening-times');
        let openList = popup.querySelector('.open-list');
        if (toggleOpeningButton && openList) {
            toggleOpeningButton.addEventListener('click', () => {
                openList.classList.toggle('collapsed');
            });
        }

        const dialogParent = popup.closest('.gm-style-iw[role="dialog"]');
        if (dialogParent) {
            dialogParent.classList.add('custom-map-dialog')
        }
        else {
            console.warn('Can\'t find Google Maps modal popup element')
        }
    }

    formatStatus = (status) => {
        let asWords = status.toLowerCase().replace('_', ' ').trim();
        return asWords[0].toUpperCase() + asWords.substring(1);
    }

    formatUrl = (url) => {
        let sansScheme = url.replace(/^https?:\/\/(.+)/i, '$1');

        if (sansScheme.endsWith('/')) {
            sansScheme = sansScheme.substring(0, sansScheme.length - 1);
        }

        return sansScheme;
    }

    render() {
        const place = this.props.place;
        const placeType = this.props.placeType;

        if (!place) {
            return (
                <aside className="place-info-window not-found">
                    <div className="header">
                        <p className="title">
                            <FontAwesomeIcon className="icon" icon={faMapMarkerAltSlash}/>
                            Place Not Found
                        </p>
                        <button type="button" className="link-button close" aria-label="Close">
                            <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                        </button>
                    </div>
                </aside>
            )
        };

        return (
            <aside data-place-id={place.id} className="place-info-window">
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" fixedWidth icon={placeType.icon}/>
                        <span className="text">{place.name}</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close">
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="content">
                    {place.imageUrl &&
                        <figure className="image-container">
                            <img alt="" className="image" src={place.imageUrl}/>
                        </figure>
                    }
                    <div className="info">
                        <div className="info-rows">
                            {place.address &&
                                <address className="info-row">
                                    <FontAwesomeIcon className="icon" fixedWidth icon={faMapMarkerAlt}/>
                                    <span className="text">{place.address}</span>
                                </address>
                            }

                            {place.telephone &&
                                <p className="info-row">
                                    <FontAwesomeIcon className="icon" fixedWidth icon={faPhone}/>
                                    <span className="text">{place.telephone}</span>
                                </p>
                            }

                            {place.website &&
                                <p className="info-row">
                                    <FontAwesomeIcon className="icon" fixedWidth icon={faExternalLink}/>
                                    <a className="link" href={place.website} target="_blank" rel="noreferrer">{this.formatUrl(place.website)}</a>
                                </p>
                            }
                        </div>

                        {place.rating &&
                            <div className="review-info">
                                <Stars className="star-rating" readOnly={true} rating={place.rating}
                                    aria-label={`${place.name} has an average rating of ${place.rating} out of 5 stars`}/>
                                <p className="num-reviews">{place.totalRatings} review{place.totalRatings !== 1 ? `s` : ``}</p>
                            </div>
                        }

                        {place.businessStatus !== 'OPERATIONAL' &&
                            <p className="info-row status-row">
                                <strong className="label">Status: </strong>
                                <span className="text warn">{this.formatStatus(place.businessStatus)}</span>
                            </p>
                        }

                        {place.opening && place.businessStatus === 'OPERATIONAL' &&
                            <div className={`opening-times ` + (place.opening.isOpen ? `open` : `closed`)}>
                                <header className="opening-header">
                                    <h3 className="title">{place.opening.isOpen ? `Open` : `Closed`} now</h3>
                                    <button type="button" className="link-button toggle-opening-times">
                                        <FontAwesomeIcon className="icon" fixedWidth icon={faChevronDown}/>
                                        <span className="text">Show times</span>
                                    </button>
                                </header>
                                <ul className="open-list collapsed">
                                    {place.opening.openingDays.map((day, i) =>
                                        <li key={i} className="item">{day}</li>    
                                    )}
                                </ul>
                            </div>
                        }

                        <a className="maps-url" target="_blank" rel="noreferrer" href={place.mapsUrl}>
                            View in Google Maps
                        </a>
                    </div>
                </div>
            </aside>
        )
    }
}

export default Place