import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'

import './styles.scss'

class AboutContact extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'Contact - Spirited Drive';
        let metaDescription = 'Contact information for Spirited Drive. Email: ' + this.props.config.email + ', Twitter: @' + this.props.config.social.twitterUsername;
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    render() {
        return (
            <div className="main-page-full about-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/about" className="link">About</NavLink></li>
                        <li className="item"><NavLink exact to="/about/contact" aria-current="location" className="link">Contact</NavLink></li>
                    </ol>
                </nav>

                <section className="about-section contact-section">
                    <h1 className="title">Contact Us</h1>

                    <p>Please report any bugs to our Twitter account. Thank you.</p>

                    <ul className="contact-list">
                        <li className="item">
                            <a className="link email" href={`mailto:${this.props.config.email}`}>
                                <FontAwesomeIcon className="icon" fixedWidth icon={faEnvelope}/>
                                <h2 className="heading">Email</h2>
                                <p className="value">{this.props.config.email}</p>
                            </a>
                        </li>

                        <li className="item">
                            <a className="link twitter" rel="noreferrer" target="_blank" href={`https://twitter.com/` + this.props.config.social.twitterUsername}>
                                <FontAwesomeIcon className="icon" fixedWidth icon={faTwitter}/>
                                <h2 className="heading">Twitter</h2>
                                <p className="value">@{this.props.config.social.twitterUsername}</p>
                            </a>
                        </li>

                        <li className="item">
                            <a className="link instagram" rel="noreferrer" target="_blank" href={`https://www.instagram.com/` + this.props.config.social.instagramId}>
                                <FontAwesomeIcon className="icon" fixedWidth icon={faInstagram}/>
                                <h2 className="heading">Instagram</h2>
                                <p className="value">@{this.props.config.social.instagramId}</p>
                            </a>
                        </li>

                        <li className="item">
                            <a className="link youtube" rel="noreferrer" target="_blank" href={`https://www.youtube.com/channel/` + this.props.config.social.youTubeChannelId}>
                                <FontAwesomeIcon className="icon" fixedWidth icon={faYoutube}/>
                                <h2 className="heading">YouTube</h2>
                                <p className="value">Spirited Drive</p>
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AboutContact)