import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

class AboutPrivacy extends React.Component {
    constructor(props) {
        super(props)

        document.title = 'Privacy Policy - Spirited Drive';
        let metaDescription = 'Spirited Drive privacy policy';
        document.querySelector('head > meta[name="description"]').setAttribute('content', metaDescription);

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });
    }

    render() {
        return (
            <div className="main-page-full about-page">
                <nav className="breadcrumbs" aria-label="Breadcrumb">
                    <ol className="list">
                        <li className="item"><NavLink exact to="/" className="link">Home</NavLink></li>
                        <li className="item"><NavLink exact to="/about" aria-current="location" className="link">About</NavLink></li>
                        <li className="item"><NavLink exact to="/about/privacy" className="link">Privacy Policy</NavLink></li>
                    </ol>
                </nav>

                <section className="about-section">
                    <h1 className="title">Privacy Policy</h1>

                    <p>
                        At Spirited Drive, accessible from https://spiriteddrive.club, one of our main priorities is the privacy of our visitors.
                        This Privacy Policy document contains types of information that is collected and recorded by Spirited Drive and how we use it.
                    </p>

                    <p>
                        If you have additional questions or require more information about
                        our Privacy Policy, do not hesitate to contact us.
                    </p>

                    <p>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our
                        website with regards to the information that they shared and/or collect in Spirited Drive.
                        This policy is not applicable to any information collected offline or via channels other
                        than this website. Our Privacy Policy was created with the help of
                        the <a target="_blank" rel="noreferrer" href="https://www.privacypolicygenerator.info/">Privacy Policy Generator</a>.
                    </p>

                    <section className="sub-section">
                        <h2>Consent</h2>

                        <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                    </section>

                    <section className="sub-section">
                        <h2>Information we collect</h2>

                        <p>
                            The personal information that you are asked to provide, and the reasons why you are asked to
                            provide it, will be made clear to you at the point we ask you to provide your personal information.
                        </p>
                        <p>
                            If you contact us directly, we may receive additional information about you such as your name, email
                            address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                        </p>
                        <p>
                            When you register for an Account, we may ask for your contact information, including items such as
                            name, company name, address, email address, and telephone number.
                        </p>
                    </section>


                    <section className="sub-section">
                        <h2>How we use your information</h2>

                        <p>We use the information we collect in various ways, including to:</p>

                        <ul>
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalise, and expand our website</li>
                            <li>Understand and analyse how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>
                                Communicate with you, either directly or through one of our partners, including for
                                customer service, to provide you with updates and other information relating
                                to the website, and for marketing and promotional purposes
                            </li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                    </section>


                    <section className="sub-section">
                        <h2>Log Files</h2>

                        <p>
                            Spirited Drive follows a standard procedure of using log files. These files log
                            visitors when they visit websites. All hosting companies do this and a part of hosting services'
                            analytics. The information collected by log files include internet protocol (IP) addresses,
                            browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages,
                            and possibly the number of clicks. These are not linked to any information that is personally
                            identifiable. The purpose of the information is for analysing trends, administering the site,
                            tracking users' movement on the website, and gathering demographic information.
                        </p>
                    </section>

                    <section className="sub-section">
                        <h2>Tracking and Analytics</h2>

                        <p>
                            We want to process as little personal information as possible when you use our website. That's why we've
                            chosen Fathom Analytics for our website analytics, which doesn't use cookies and complies with the GDPR,
                            ePrivacy (including PECR), COPPA and CCPA. Using this privacy-friendly website analytics software, your IP
                            address is only briefly processed, and we (running this website) have no way of identifying you. As per the
                            CCPA, your personal information is de-identified. You can read more about this
                            on <a target="_blank" rel="noreferrer" href="https://usefathom.com/compliance">Fathom Analytics' website</a>.
                        </p>

                        <p>
                            The purpose of us using this software is to understand our website traffic in the most privacy-friendly way
                            possible so that we can continually improve our website and business. The lawful basis as per the GDPR is "f);
                            where our legitimate interests are to improve our website and business continually." As per the explanation, no
                            personal data is stored over time.
                        </p>
                    </section>


                    <section className="sub-section">
                        <h2>Advertising Partners Privacy Policies</h2>

                        <p>
                            You may consult this list to find the Privacy Policy for each
                            of the advertising partners of Spirited Drive.
                        </p>

                        <p>
                            Third-party ad servers or ad networks uses technologies like cookies, JavaScript,
                            or Web Beacons that are used in their respective advertisements and links that appear
                            on Spirited Drive, which are sent directly to users' browser. They automatically
                            receive your IP address when this occurs. These technologies are used to measure
                            the effectiveness of their advertising campaigns and/or to personalise the
                            advertising content that you see on websites that you visit.
                        </p>

                        <p>
                            Note that Spirited Drive doesn't currently feature any advertising on the website just yet,
                            but we may include advertising soon in order to support the site. When we do this
                            Privacy Policy will be updated accordingly.
                        </p>
                    </section>


                    <section className="sub-section">
                        <h2>Third Party Privacy Policies</h2>

                        <p>
                            Spirited Drive's Privacy Policy does not apply to other advertisers, services, or websites.
                            Thus, we are advising you to consult the respective Privacy Policies of these
                            third-party services for more detailed information. It may include their practices
                            and instructions about how to opt-out of certain options.
                        </p>

                        <p>
                            You can choose to disable cookies through your individual browser options. To know more
                            detailed information about cookie management with specific web browsers, it can be found
                            at the browsers' respective websites.
                        </p>

                        <p>Spirited Drive makes use of the following third party services:</p>

                        <ul>
                            <li>
                                <strong>Google Maps</strong>: powers the main map for this website, plus additional
                                functionality such as search, route info, address lookup etc. See Google's privacy policy
                                here: <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                            </li>
                            <li>
                                <strong>YouTube</strong>: this website embeds YouTube videos in certain parts of the site, such as
                                drive detail pages. YouTube may set cookies to facilitate this functionality. YouTube is owned by
                                Google, see their privacy policy here: <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                            </li>
                            <li>
                                <strong>Fathom Analytics</strong>: a fully GDPR compliant tracking and analytics service that helps
                                us see how users navigate and use the Spirited Drive website. See their privacy policy
                                here: <a target="_blank" rel="noreferrer" href="https://usefathom.com/privacy">https://usefathom.com/privacy</a>
                            </li>
                        </ul>
                    </section>

                    <section className="sub-section">
                        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                        <p>
                            Request that a business that collects a consumer's personal data disclose the categories and
                            specific pieces of personal data that a business has collected about consumers.
                        </p>
                        <p>
                            Request that a business delete any personal data about the
                            consumer that a business has collected.
                        </p>
                        <p>
                            Request that a business that sells a consumer's personal
                            data, not sell the consumer's personal data.
                        </p>
                        <p>
                            If you make a request, we have one month to respond to you. If you would like to exercise
                            any of these rights, please contact us.
                        </p>
                    </section>


                    <section className="sub-section">
                        <h2>GDPR Data Protection Rights</h2>

                        <p>
                            We would like to make sure you are fully aware of all of your data
                            protection rights. Every user is entitled to the following:
                        </p>
                        <p>
                            The right to access – You have the right to request copies of
                            your personal data. We may charge you a small fee for this service.
                        </p>
                        <p>
                            The right to rectification – You have the right to request that we correct any information
                            you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                        </p>
                        <p>
                            The right to erasure – You have the right to request that we erase your
                            personal data, under certain conditions.
                        </p>
                        <p>
                            The right to restrict processing – You have the right to request that we restrict
                            the processing of your personal data, under certain conditions.
                        </p>
                        <p>
                            The right to object to processing – You have the right to object to our processing
                            of your personal data, under certain conditions.
                        </p>
                        <p>
                            The right to data portability – You have the right to request that we transfer
                            the data that we have collected to another organisation, or directly to you, under certain conditions.
                        </p>
                        <p>
                            If you make a request, we have one month to respond to you. If you would
                            like to exercise any of these rights, please contact us.
                        </p>
                    </section>

                    <section className="sub-section">
                        <h2>Children's Information</h2>

                        <p>
                            Another part of our priority is adding protection for children while using the internet.
                            We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                        </p>

                        <p>
                            Spirited Drive does not knowingly collect any Personal Identifiable Information from children
                            under the age of 13. If you think that your child provided this kind of information on our
                            website, we strongly encourage you to contact us immediately and we will do our best efforts to
                            promptly remove such information from our records.
                        </p>
                    </section>
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AboutPrivacy)