import React from 'react';
import ReactDOM from 'react-dom';

import './ModalPortal.scss'

class ModalPortal extends React.Component {
    static portalContainerId = 'root-modal';
    static backgroundClassName = 'modal-background';

    handleBackgroundClicked = (e) => {
        if (this.props.onClose) {
            let backgroundClicked = e.target.classList.contains(ModalPortal.backgroundClassName);
            if (backgroundClicked) {
                this.props.onClose();
            }
        }
    }

    render() {
        const portalContainer = document.getElementById(ModalPortal.portalContainerId);
        if (!portalContainer) return null;

        return ReactDOM.createPortal(
            <div className={ModalPortal.backgroundClassName} onMouseDown={this.handleBackgroundClicked}>
                <aside className={this.props.className}>
                    {this.props.children}
                </aside>
            </div>,
            portalContainer
        );
    }
}

export default ModalPortal;