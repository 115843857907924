import React from 'react'
import { NavLink } from 'react-router-dom'
import basePageWrapper from './../BasePage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle, faFrown } from '@fortawesome/pro-solid-svg-icons'

import './styles.scss'

class AccountActivate extends React.Component {
    constructor(props) {
        super(props)

        let qs = {}; this.props.location.search.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });
        let redirectUrl = (qs.redirectUrl || '').trim();
        redirectUrl = redirectUrl.startsWith('/') ? redirectUrl : '';

        this.state = {
            activated: false,
            redirectUrl: redirectUrl,
            error: false
        }

        document.title = 'Activate Your Account - Spirited Drive';
        document.querySelector('head > meta[name="description"]').setAttribute('content', 'Activate Your Account');

        this.props.pageType.setFull();
    }

    componentDidMount() {
        this.props.mapReady((map) => {
            map._disableControls();
            map.streetView.setVisible(false);
        });

        let querystring = this.props.location.search;
        let qs = {}; querystring.substr(1).split('&').forEach(x => { x = x.split('='); qs[x[0]] = decodeURIComponent(x[1] || ''); });

        let userId = qs.userId;
        let token = qs.token;

        if (!userId || !token) {
            this.props.history.replace("/");
            return;
        }

        let url = this.props.config.apiUrl + '/v1/accounts/activate';

        let data = {
            userId: userId,
            token: token
        };

        this.props.fetcher.put(url, data).then(response => {
            if (response.code === 0) {
                this.setState({
                    activated: true
                });

                this.props.trackEvent('AccountActivated');

                return;
            }

            console.error(response.code + ': ' + response.message);

            this.setState({
                error: true
            });
        });
    }

    getSigninUrl = () => {
        return '/account/sign-in' + (this.state.redirectUrl ? '?redirectUrl=' + encodeURIComponent(this.state.redirectUrl) : '');
    }

    render() {
        return (
            <div className="main-page-full activate-page narrow-form-page">
                <header className="header">
                    <h1 className="title">Activating Your Account</h1>
                </header>

                <section className="inner">
                    {!this.state.activated && !this.state.error &&
                        <div className="form">
                            <p className="intro">
                                Just a moment...
                            </p>

                            <FontAwesomeIcon className="icon" icon={faSpinner} spin/>
                        </div>
                    }

                    {this.state.activated && !this.state.error &&
                        <div className="form">
                            <p className="intro">
                                Your account has been activated.
                            </p>

                            <FontAwesomeIcon className="icon" icon={faCheckCircle}/>

                            <p>
                                Sign-in to continue...
                            </p>

                            <div className="form-row buttons centered">
                                <NavLink to={this.getSigninUrl()} className="button cta chunky sign-in-button">Sign-in</NavLink>
                            </div>
                        </div>
                    }

                    {this.state.error &&
                        <div className="form error">
                            <p className="intro">
                                Sorry, there was a problem activating your account.
                            </p>

                            <FontAwesomeIcon className="icon" icon={faFrown}/>

                            <p>
                                Please try registering again and follow the activation link in the email.
                                If the problem persists, contact an administrator.
                            </p>

                            <div className="form-row buttons centered">
                                <NavLink to="/account/register" className="button chunky">Register</NavLink>
                            </div>
                        </div>
                    }
                </section>
            </div>
        )
    }
}

export default basePageWrapper(AccountActivate)