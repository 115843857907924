import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faEdit, faSync, faPlusCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import validation from '../../utils/validation'
import format from '../../utils/format'

import ModalPortal from './ModalPortal'

class ExternalLinks extends React.Component {
    constructor(props) {
        super(props)

        let links = (props.links || []).map((link, i) => {
            return {
                id: link.id,
                isNew: false,
                isReadOnly: link.isReadOnly,
                name: {
                    val: link.name,
                    err: null
                },
                url: {
                    val: link.url,
                    err: null
                },
                sortIndex: i
            };
        });

        this.state = {
            form: {
                links: links
            },
            deletedLinks: [],
            isSubmitted: false,
            saving: false
        };
    }

    deletedLinks = [];

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    handleSave = (event) => {
        if (!this.props.handleSave) return;

        event.preventDefault();

        if (this.state.saving) return;
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        // check for duplicate links
        let hasDuplicateLinks = false;

        let linksToCheck = this.state.form.links.map(link => {
            return {
                url: link.url.val.toLowerCase().trim(),
                ignoreDuplicateCheck: false
            };
        })

        for (let i = linksToCheck.length - 1; i >= 0; i--) {
            let link = linksToCheck[i];

            let linkWithExistingUrl = linksToCheck.find(l =>
                l !== link &&
                l.ignoreDuplicateCheck === false &&
                l.url === link.url);

            if (linkWithExistingUrl) {
                link.ignoreDuplicateCheck = true;
                linkWithExistingUrl.ignoreDuplicateCheck = true;
                
                this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, `links[${i}].url`, 'Link already exists') });
                hasDuplicateLinks = true;
            }
        }

        if (hasDuplicateLinks) return;

        // save
        this.setState({ saving: true });

        let links = this.state.form.links.map(link => {
            return {
                id: link.isNew ? null : link.id,
                name: link.name.val,
                url: link.url.val,
                isDeleted: false,
                isReadOnly: link.isReadOnly,
                sortIndex: link.sortIndex
            };
        });

        links.push(...this.deletedLinks.map(link => {
            return {
                id: link.id,
                name: link.name.val,
                url: link.url.val,
                isDeleted: true,
                isReadOnly: link.isReadOnly,
                sortIndex: link.sortIndex
            };
        }));

        this.props.handleSave(links, () => {
            this.setState({ saving: false });
        });
    }

    removeLink = (link) => {
        let links = [...this.state.form.links];

        let linkToRemove = links.find(l => l.id === link.id);
        if (!linkToRemove) return;

        links.splice(links.indexOf(linkToRemove), 1);
        
        if (!linkToRemove.isNew) {
            this.deletedLinks.push(linkToRemove)
        }

        this.setState({ form: {
            ...this.state.form,
            links: links
        }});
    }

    handleAdd = () => {
        let links = [...this.state.form.links];
        links.push({
            id: format.uuidv4(),
            isNew: true,
            name: {
                val: '',
                err: null
            },
            url: {
                val: '',
                err: null
            },
            sortIndex: links.length
        });

        this.setState({ form: {
            ...this.state.form,
            links: links
        }});
    }

    close = () => {
        if (this.state.deleting || this.state.saving) return;

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (
            <ModalPortal className="standard-modal links-edit-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faEdit}/>
                        <span className="text">Edit External Links</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>

                <div className="main">
                    <form id="editExternalLinksForm" onSubmit={this.handleSave} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                        <div className="edit-links-list">
                            {this.state.form.links.map((link, i) =>
                                <fieldset key={link.id} className={`link-item` + (link.isReadOnly ? ` read-only-item` : ``)}>
                                    <legend className="legend">
                                        <h3 className="title">{link.name.val}</h3>
                                        {!link.isReadOnly &&
                                            <button type="button" className="button remove-button"
                                                aria-label="Remove link" onClick={() => this.removeLink(link)}>Remove</button>
                                        }
                                    </legend>
                                    
                                    {link.isReadOnly &&
                                        <div className="controls">
                                            <div className="row label-row">
                                                <label className="label">Label</label>
                                                <p className="read-only-value">{link.name.val}</p>
                                            </div>
                                            <div className="row url-row">
                                                <label className="label">URL</label>
                                                <p className="read-only-value">{link.url.val}</p>
                                            </div>
                                        </div>
                                    }

                                    {!link.isReadOnly &&
                                        <div className="controls">
                                            <div className="row label-row">
                                                <label className="label" htmlFor={`editLinkName_${i}`}>Label</label>
                                                <input id={`editLinkName_${i}`} name={`links[${i}].name`} type="text" className="textbox"
                                                    required minLength="1" maxLength="200" value={link.name.val} onChange={this.handleInputChange}/>
                                            </div>
                                            <div className="row url-row">
                                                <label className="label" htmlFor={`editLinkUrl_${i}`}>URL</label>
                                                <input id={`editLinkUrl_${i}`} name={`links[${i}].url`} type="url" className="textbox"
                                                    required maxLength="500" value={link.url.val} onChange={this.handleInputChange}/>
                                            </div>

                                            {link.url.err && this.state.isSubmitted &&
                                                <p className="validation-error">
                                                    <FontAwesomeIcon className="icon" icon={faExclamationCircle}/>{link.url.err}
                                                </p>
                                            }
                                        </div>
                                    }
                                    
                                </fieldset>
                            )}

                            <div className="actions">
                                <button className="button" type="button" onClick={this.handleAdd}>
                                    <FontAwesomeIcon className="icon" icon={faPlusCircle}/>
                                    Add Another External Link
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <footer className="footer">
                    <button type="submit" className="button primary-button" form="editExternalLinksForm">
                        <FontAwesomeIcon className="icon" icon={this.state.saving ? faSync : faEdit} spin={this.state.saving}/> Save &amp; Close
                    </button>
                    <button type="button" className="link-button cancel-button" onClick={this.close}>Nevermind</button>
                    <span className="flex-gap"></span>
                </footer>
            </ModalPortal>
        );
    }
}

export default ExternalLinks