import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faCar, faCarCrash, faChartScatter, faCloudRain, faFog, faIcicles, faLampDesk, faMoon, faMotorcycle, faOilCan, faQuestionCircle, faSnowflake, faSun, faTimesCircle, faTireRugged, faTruck, faWater, faWind } from '@fortawesome/pro-solid-svg-icons'
import { faFrown } from '@fortawesome/pro-light-svg-icons';

import pinBlack from '../images/pin-black.png';
import pinRed from '../images/pin-red.png';
import pinOrange from '../images/pin-orange.png';
import pinGray from '../images/pin-gray.png';

import Drive from '../pages/drives/get';

class AccidentControls extends React.Component {
    static PinDisabled = pinGray;

    accidents = []

    state = {
        isLoading: false,
        showAccidents: false,
        showHeatMap: false,
        severities: [
            {
                id: 3,
                name: 'Slight',
                pin: pinOrange,
                count: null,
                visible: true
            },
            {
                id: 2,
                name: 'Serious',
                pin: pinRed,
                count: null,
                visible: true
            },
            {
                id: 1,
                name: 'Fatal',
                pin: pinBlack,
                count: null,
                visible: true
            }
        ],
        startYear: 2000,
        endYear: 2022,
        minYear: 2000,
        maxYear: 2020,
        vehicleTypes: [
            {
                key: 'bicycles',
                name: 'Bicycle',
                icon: faBicycle,
                visible: true,
                count: null,
                flag: 1
            },
            {
                key: 'motorcycles',
                name: 'Motorcycle',
                icon: faMotorcycle,
                visible: true,
                count: null,
                flag: 2
            },
            {
                key: 'cars',
                name: 'Car',
                icon: faCar,
                visible: true,
                count: null,
                flag: 4
            },
            {
                key: 'trucks',
                name: 'Truck/HGV',
                icon: faTruck,
                visible: true,
                count: null,
                flag: 8
            }
        ],
        lightConditionsAll: true,
        lightConditions: [
            { key: 'day', name: 'Day', icon: faSun, visible: true, count: null, ids: [1] },
            { key: 'night-lit', name: 'Night lit', icon: faLampDesk, visible: true, count: null, ids: [4] },
            { key: 'night-unlit', name: 'Night unlit', icon: faMoon, visible: true, count: null, ids: [5,6,7] }
        ],
        weatherConditionsAll: true,
        weatherConditions: [
            { key: 'fine', name: 'Fine', icon: faSun, visible: true, count: null, ids: [1,4] },
            { key: 'rain', name: 'Rain', icon: faCloudRain, visible: true, count: null, ids: [2,5] },
            { key: 'snow', name: 'Snow', icon: faSnowflake, visible: true, count: null, ids: [3,6] },
            { key: 'wind', name: 'Wind', icon: faWind, visible: true, count: null, ids: [4,5,6] },
            { key: 'fog', name: 'Fog', icon: faFog, visible: true, count: null, ids: [7] }
        ],
        roadConditionsAll: true,
        roadConditions: [
            { key: 'dry', name: 'Dry', icon: faSun, visible: true, count: null, ids: [1] },
            { key: 'wet', name: 'Wet', icon: faCloudRain, visible: true, count: null, ids: [2] },
            { key: 'snow', name: 'Snow', icon: faSnowflake, visible: true, count: null, ids: [3] },
            { key: 'ice', name: 'Ice', icon: faIcicles, visible: true, count: null, ids: [4] },
            { key: 'flood', name: 'Flood', icon: faWater, visible: true, count: null, ids: [5] },
            { key: 'oil', name: 'Oil', icon: faOilCan, visible: true, count: null, ids: [6] }
        ]
    }

    handleToggleAccidents = () => {
        this.handleToggle('accidents', () => {
            if (this.drivePolyline) {
                this.drivePolyline.setOptions(Drive.PolylineOptions);
            }

            this.setState({ showHeatMap: false, showAccidents: !this.state.showAccidents }, () => {
                this.drawAccidentMarkers({ animate: true });
            });
        });
    }

    handleToggleHeatMap = () => {
        this.handleToggle('heatMap', () => {
            if (this.drivePolyline) {
                this.drivePolyline.setOptions(Drive.PolylineOptionsFaded);
            }

            this.setState({ showHeatMap: !this.state.showHeatMap, showAccidents: false }, () => {
                this.drawAccidentMarkers();
            });
        });
    }

    handleToggle = (type, callback) => {
        if (this.state.isLoading) return;

        const isClosing = (type === 'accidents' && this.state.showAccidents) ||
            (type === 'heatMap' && this.state.showHeatMap);
        
        if (isClosing) {
            if (this.props.onClose) this.props.onClose();
    
            this.accidents = [];
            this.setState({ showAccidents: false, showHeatMap: false });
            return;
        }

        if (!this.props.onOpen) return;

        this.setState({ isLoading: true });

        this.props.onOpen().then(accidents => {
            this.accidents = accidents.map(record => {
                record.vehicleFlag = 0;
                if (record.bicycles > 0) record.vehicleFlag += 1;
                if (record.motorcycles > 0) record.vehicleFlag += 2;
                if (record.cars > 0) record.vehicleFlag += 4;
                if (record.trucks > 0) record.vehicleFlag += 8;

                record.year = new Date(Date.parse(record.date)).getFullYear();
                return record;
            });
        
            let severities = [...this.state.severities];

            severities.forEach(severity => {
                severity.count = this.accidents.filter(a => a.severity === severity.id).length;
            });

            let years = this.accidents.map(a => a.year);

            let minYear = Math.min(...years);
            let startYear = minYear;

            let maxYear = Math.max(...years);
            let endYear = maxYear;

            let vehicleTypes = [...this.state.vehicleTypes];

            vehicleTypes.forEach(type => {
                type.count = this.accidents.reduce((c, a) => c + (a[type.key] > 0 ? 1 : 0), 0);
            });

            let newState = {
                visible: true,
                isLoading: false,
                severities: severities,
                vehicleTypes: vehicleTypes,
                minYear: minYear,
                startYear: startYear,
                maxYear: maxYear,
                endYear: endYear
            };

            this.setState(newState, () => {
                if (callback) callback();
            });
        });
    }

    toggleAccidentSeverity = (id) => {
        let severities = [...this.state.severities];
        let severity = severities.find(s => s.id === id);
        severity.visible = !severity.visible;

        this.setState({ severities: severities }, () => {
            this.drawAccidentMarkers({ excludeCountUpdateFor: 'severities' });
        });
    }

    handleStartRangeChange = (event) => {
        let startYear = parseInt(event.target.value);
        let endYear = this.state.endYear;

        if (startYear > endYear) {
            endYear = startYear;
        }

        this.setState({ startYear: startYear, endYear: endYear }, () => {
            this.drawAccidentMarkers();
        });
    }

    handleEndRangeChange = (event) => {
        let startYear = this.state.startYear;
        let endYear = parseInt(event.target.value);

        if (endYear < startYear) {
            startYear = endYear;
        }

        this.setState({ startYear: startYear, endYear: endYear }, () => {
            this.drawAccidentMarkers();
        });
    }

    toggleVehicleType = (type) => {
        let vehicleTypes = [...this.state.vehicleTypes];
        let vehicleType = vehicleTypes.find(t => t.key === type);
        vehicleType.visible = !vehicleType.visible;

        this.setState({ vehicleTypes: vehicleTypes }, () => {
            this.drawAccidentMarkers();
        });
    }

    toggleConditions = (type, condition) => {
        const key = type + 'Conditions';
        const allKey = type + 'ConditionsAll';

        let conditions = [...this.state[key]];
        let stateCondition = conditions.find(c => c.key === condition.key);

        stateCondition.visible = !condition.visible;

        let newState = {};
        newState[key] = conditions;
        newState[allKey] = conditions.every(c => c.visible);

        this.setState(newState, () => {
            this.drawAccidentMarkers();
        });
    }

    toggleAllConditions = (type) => {
        const key = type + 'Conditions';
        const allKey = type + 'ConditionsAll';

        let conditions = [...this.state[key]];
        let toggleAll = !this.state[allKey];

        if (this.state[type + 'ConditionsAll']) {
            conditions.forEach(c => c.visible = false);
        }
        else {
            conditions.forEach(c => c.visible = true);
        }

        let newState = {};
        newState[key] = conditions;
        newState[allKey] = toggleAll;

        this.setState(newState, () => {
            this.drawAccidentMarkers();
        });
    }

    drawAccidentMarkers = (options) => {
        options = options || {};

        let excludedSeverityIds = this.state.severities.filter(s => !s.visible).map(s => s.id);

        let vehicleFlag = this.state.vehicleTypes
            .filter(t => t.visible)
            .reduce((flag, t) => flag += t.flag, 0);

        let activeLightConditionIds = this.state.lightConditions.filter(c => c.visible).reduce((ids, c) => { ids.push(...c.ids); return ids; }, []);
        let allLightConditions = this.state.lightConditions.every(c => c.visible);

        let activeWeatherConditionIds = this.state.weatherConditions.filter(c => c.visible).reduce((ids, c) => { ids.push(...c.ids); return ids; }, []);
        let allWeatherConditions =this.state.weatherConditions.every(c => c.visible);

        let activeRoadConditionIds = this.state.roadConditions.filter(c => c.visible).reduce((ids, c) => { ids.push(...c.ids); return ids; }, []);
        let allRoadConditions = this.state.roadConditions.every(c => c.visible);

        const filterOptions = {
            excludedSeverityIds: excludedSeverityIds,
            vehicleFlag: vehicleFlag,
            allLightConditions: allLightConditions,
            activeLightConditionIds: activeLightConditionIds,
            allWeatherConditions: allWeatherConditions,
            activeWeatherConditionIds: activeWeatherConditionIds,
            allRoadConditions: allRoadConditions,
            activeRoadConditionIds: activeRoadConditionIds,
            startYear: this.state.startYear,
            endYear: this.state.endYear
        };

        let filteredData = this.filterAccidentData(this.accidents, filterOptions)
            .map(accident => {
                let severity = this.state.severities.find(s => s.id === accident.severity);

                return {
                    id: accident.id,
                    severity: severity,
                    location: accident.location
                };
            });

        if (this.state.showHeatMap) {
            if (this.props.renderHeatMap) {
                this.props.renderHeatMap(filteredData);
            }
        }
        else {
            if (this.props.renderPins) {
                this.props.renderPins(filteredData, options.animate || false);
            }
        }

        this.updateAccidentCount(filterOptions);
    }

    updateAccidentCount = (filterOptions) => {
        let severities = [...this.state.severities];
        let filteredData = this.filterAccidentData(this.accidents, filterOptions, 'severities');
        severities.forEach(s => {
            s.count = filteredData.filter(a => a.severity === s.id).length;
        });

        let vehicleTypes = [...this.state.vehicleTypes];
        filteredData = this.filterAccidentData(this.accidents, filterOptions, 'vehicles');
        vehicleTypes.forEach(t => {
            t.count = filteredData.filter(a => t.flag & a.vehicleFlag).length;
        });

        let lightConditions = [...this.state.lightConditions];
        filteredData = this.filterAccidentData(this.accidents, filterOptions, 'lightConditions');
        lightConditions.forEach(c => {
            c.count = filteredData.filter(a => c.ids.includes(a.lightConditionId)).length;
        });

        let weatherConditions = [...this.state.weatherConditions];
        filteredData = this.filterAccidentData(this.accidents, filterOptions, 'weatherConditions');
        weatherConditions.forEach(c => {
            c.count = filteredData.filter(a => c.ids.includes(a.weatherConditionId)).length;
        });

        let roadConditions = [...this.state.roadConditions];
        filteredData = this.filterAccidentData(this.accidents, filterOptions, 'roadConditions');
        roadConditions.forEach(c => {
            c.count = filteredData.filter(a => c.ids.includes(a.roadConditionId)).length;
        });

        this.setState({
            severities: severities,
            vehicleTypes: vehicleTypes,
            lightConditions: lightConditions,
            weatherConditions: weatherConditions,
            roadConditions: roadConditions
        });
    }

    handleRemoveSave = () => {
        if (!this.props.onRemoveSave) return;

        this.props.onRemoveSave(() => {
            this.accidents = [];
            this.setState({ visible: false }, () => {
                this.handleToggleAccidents();
            });
        });
    }

    filterAccidentData = (accidents, options, exclude) => {
        options = options || {};
        exclude = exclude || null;

        const excludeSeverities = exclude === 'severities';
        const excludeVehicles = exclude === 'vehicles';
        const excludeLightConditions = exclude === 'lightConditions';
        const excludeWeatherConditions = exclude === 'weatherConditions';
        const excludeRoadConditions = exclude === 'roadConditions';

        return accidents
            .filter(accident => {
                if (!excludeSeverities && options.excludedSeverityIds.includes(accident.severity)) {
                    return false;
                }

                if (!excludeVehicles) {
                    const hasFlag = options.vehicleFlag & accident.vehicleFlag;
                    if (!hasFlag) {
                        return false;
                    }
                }

                if (!excludeLightConditions && !options.allLightConditions && !options.activeLightConditionIds.includes(accident.lightConditionId)) {
                    return false;
                }

                if (!excludeWeatherConditions && !options.allWeatherConditions && !options.activeWeatherConditionIds.includes(accident.weatherConditionId)) {
                    return false;
                }

                if (!excludeRoadConditions && !options.allRoadConditions && !options.activeRoadConditionIds.includes(accident.roadConditionId)) {
                    return false;
                }

                return accident.year >= options.startYear &&
                    accident.year <= options.endYear;
            });
    }

    showSafetyRecordExplanation = () => {
        const dialog = document.querySelector('#accident-safety-record-help-dialog');
        dialog.showModal();
    }

    closeSafetyRecordExplanation = (e, forceClose) => {
        const dialog = document.querySelector('#accident-safety-record-help-dialog');

        if (forceClose) {
            dialog.close();
            return;
        }

        if (e.target === dialog) {
            dialog.close();
        }
    }

    render() {
        if (!this.props.safetyRecord || !this.props.safetyRecord.accidentConcentration) {
            return (
                <div className="drive-accident-controls">
                    <p className='no-accidents-message'>
                        <FontAwesomeIcon className="icon" fixedWidth icon={faFrown}/>
                        Sorry, no safety data found for this Drive.
                    </p>
                </div>
            );
        }

        return (
            <div className="drive-accident-controls">
                {this.props.safetyRecord &&
                    <div className="safety-record">
                        <dl className="data-list annual-casualties">
                            <div className="item">
                                <dt className="key">Annual Casualties per km:</dt>
                                <dd className="value">
                                    <strong className="enlarge">{this.props.safetyRecord.accidentConcentration}</strong>
                                    {this.props.safetyAverages &&
                                        <span className="average"> (UK average: {this.props.safetyAverages.accidentConcentration})</span>
                                    }
                                </dd>
                            </div>
                        </dl>

                        {this.props.safetyRecord.relativeRisk && 
                            <div className="risk-factors fake-fieldset">
                                <h3 className="title">Relative Risk Factor</h3>
                                <button className="fieldset-help link-button" onClick={this.showSafetyRecordExplanation}>(What does this mean?)</button>

                                <dl className="data-list">
                                    <div className="item">
                                        <dt className="key">Cars:</dt>
                                        <dd className="value">
                                            {this.props.safetyRecord.relativeRiskCars}
                                            {this.props.safetyAverages &&
                                                <span className="average"> (UK average: {this.props.safetyAverages.relativeRiskCars})</span>
                                            }
                                        </dd>
                                    </div>
                                    <div className="item">
                                        <dt className="key">Motorbikes:</dt>
                                        <dd className="value">
                                            {this.props.safetyRecord.relativeRiskMotorbikes}
                                            {this.props.safetyAverages &&
                                                <span className="average"> (UK average: {this.props.safetyAverages.relativeRiskMotorbikes})</span>
                                            }
                                        </dd>
                                    </div>
                                    <div className="item gap-above">
                                        <dt className="key">All Vehicles:</dt>
                                        <dd className="value">
                                        <strong className="enlarge">{this.props.safetyRecord.relativeRisk}</strong>
                                            {this.props.safetyAverages &&
                                                <span className="average"> (UK average: {this.props.safetyAverages.relativeRisk})</span>
                                            }
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        }

                        <dialog className="dialog-modal" id="accident-safety-record-help-dialog" onClick={(e) => this.closeSafetyRecordExplanation(e)}>
                            <div className="inner">
                                <header className="header">
                                    <h2 className="title">
                                        <FontAwesomeIcon className="icon" icon={faQuestionCircle}/>
                                        <span className="text">What Does Risk Factor Mean?</span>
                                    </h2>
                                    <button type="button" className="link-button close" aria-label="Close" onClick={(e) => this.closeSafetyRecordExplanation(e, true)}>
                                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                                    </button>
                                </header>
                                <div className="main">
                                    <p>
                                        A busier road with more traffic will naturally have a higher concentration of accidents.
                                        So to show how dangerous a road is, a relative Risk Factor is calculated from the annual
                                        casualties per km along with the average daily traffic.
                                    </p>
                                </div>
                            </div>
                        </dialog>
                    </div>
                }

                <div className="accident-controls-header">
                    <button className={`button cta toggle-accidents-button toggle-type-button` + (this.state.showAccidents ? ` selected` : ``)}
                        onClick={this.handleToggleAccidents}>
                        <FontAwesomeIcon className="icon" fixedWidth spin={this.state.isLoading}
                            icon={this.state.isLoading ? faTireRugged : faCarCrash}/>
                        <span className="text">Toggle Accidents</span>
                    </button>

                    <button className={`button toggle-heat-map-button toggle-type-button` + (this.state.showHeatMap ? ` selected`: ``)}
                        onClick={this.handleToggleHeatMap}>
                        <FontAwesomeIcon className="icon" fixedWidth icon={faChartScatter}/>
                        <span className="text">Toggle Heat Map</span>
                    </button>

                    {(this.state.showAccidents || this.state.showHeatMap) && this.accidents.length === 0 &&
                        <p className='no-accidents-message'>
                            <FontAwesomeIcon className="icon" fixedWidth icon={faFrown}/>
                            Sorry, no accident data found for this Drive.
                        </p>
                    }

                    {this.props.adminMode && (this.state.showAccidents || this.state.showHeatMap) &&
                        <aside className="admin-controls">
                            {!this.props.removeMode &&
                                <button className="button" onClick={this.props.onToggleRemoveMode}>Remove Accidents</button>
                            }

                            {this.props.removeMode && <>
                                <button className="button" onClick={this.handleRemoveSave}>Save</button>
                                <button className="link-button" onClick={this.props.onToggleRemoveMode}>Cancel</button>
                            </>}
                        </aside>
                    }
                </div>

                {(this.state.showAccidents || this.state.showHeatMap) && this.accidents.length > 0 &&
                    <div className="accident-controls">
                        <div className="control severity-control">
                            <h3 className="title">Toggle Severity:</h3>

                            <ul className="map-key">
                                {this.state.severities.map(severity =>
                                    <li key={severity.id} className={`item ` + (severity.visible ? `visible` : `hidden`)}>
                                        <button className="link-button toggle" onClick={() => this.toggleAccidentSeverity(severity.id)}>
                                            <img className="key" alt={`Pin icon for ${severity.name} accidents`} src={severity.pin}/>
                                            <label className="name">
                                                {severity.name} {severity.count !== null && <span className="count">({severity.count})</span>}
                                            </label>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="control years-control">
                            <h3 className="title">Year Range:</h3>

                            <div className="inline-range-controls">
                                <div className="inline-range-control">
                                    <label className="label" htmlFor="acidentStartYearInput">Start</label>
                                    <input id="acidentStartYearInput" className="range-input" type="range"
                                        min={this.state.minYear} max={this.state.maxYear}
                                        value={this.state.startYear} onChange={this.handleStartRangeChange}/>
                                    <span className="value">{this.state.startYear}</span>
                                </div>
                                <div className="inline-range-control">
                                    <label className="label" htmlFor="acidentEndYearInput">End</label>
                                    <input id="acidentEndYearInput" className="range-input" type="range"
                                        min={this.state.minYear} max={this.state.maxYear}
                                        value={this.state.endYear} onChange={this.handleEndRangeChange}/>
                                    <span className="value">{this.state.endYear}</span>
                                </div>
                            </div>
                        </div>
                        <span className="breaker years-control-breaker"></span>

                        <div className="control vehicle-type-control">
                            <h3 className="title">Toggle Vehicle<span className="superfluous"> Types</span>:</h3>

                            <ul className="map-key">
                                {this.state.vehicleTypes.map(type =>
                                    <li key={type.key} className={`item ${type.visible ? `visible` : `hidden`}`}>
                                        <button className="link-button toggle" onClick={() => this.toggleVehicleType(type.key)}>
                                            <FontAwesomeIcon className="icon" fixedWidth icon={type.icon}/>
                                            <label className="name">
                                                {type.name} {type.count !== null && <span className="count">({type.count})</span>}
                                            </label>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <span className="breaker vehicle-type-control-breaker"></span>

                        <div className="control accident-conditions accident-condition-light">
                            <h3 className="title">
                                <span className="text">Light: </span>
                                <button className="link-button toggle-all" onClick={() => this.toggleAllConditions('light')}>
                                    ({this.state.lightConditionsAll ? `off` : `on`})
                                </button>
                            </h3>

                            <ul className="accident-conditions-list">
                                {this.state.lightConditions.map(condition =>
                                    <li key={condition.key} className={`item ${condition.visible ? `visible` : `hidden`}`}>
                                        <button className="link-button toggle" onClick={() => this.toggleConditions('light', condition)}>
                                            <FontAwesomeIcon className="icon" fixedWidth icon={condition.icon}/>
                                            <span className="label">
                                                {condition.name}
                                                {condition.count !== null && <span className="count"> ({condition.count})</span>}
                                            </span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>

                        <div className="control accident-conditions accident-condition-weather">
                            <h3 className="title">
                                <span className="text">Weather: </span>
                                <button className="link-button toggle-all" onClick={() => this.toggleAllConditions('weather')}>
                                    ({this.state.weatherConditionsAll ? `off` : `on`})
                                </button>
                            </h3>

                            <ul className="accident-conditions-list">
                                {this.state.weatherConditions.map(condition => 
                                    <li key={condition.key} className={`item ${condition.visible ? `visible` : `hidden`}`}>
                                        <button className="link-button toggle" onClick={() => this.toggleConditions('weather', condition)}>
                                            <FontAwesomeIcon className="icon" fixedWidth icon={condition.icon}/>
                                            <span className="label">
                                                {condition.name}
                                                {condition.count !== null && <span className="count"> ({condition.count})</span>}
                                            </span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <span className="breaker weather-conditions-control-breaker"></span>

                        <div className="control accident-conditions accident-condition-road">
                            <h3 className="title">
                                <span className="text">Road<span className="superfluous"> Conditions</span>: </span>
                                <button className="link-button toggle-all" onClick={() => this.toggleAllConditions('road')}>
                                    ({this.state.roadConditionsAll ? `off` : `on`})
                                </button>
                            </h3>

                            <ul className="accident-conditions-list">
                                {this.state.roadConditions.map(condition =>
                                    <li key={condition.key} className={`item ${condition.visible ? `visible` : `hidden`}`}>
                                        <button className="link-button toggle" onClick={() => this.toggleConditions('road', condition)}>
                                            <FontAwesomeIcon className="icon" fixedWidth icon={condition.icon}/>
                                            <span className="label">
                                                {condition.name}
                                                {condition.count !== null && <span className="count"> ({condition.count})</span>}
                                            </span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AccidentControls