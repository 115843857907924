import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/pro-solid-svg-icons'

class ErrorBoundary extends React.Component {

    state = {
        hasError: false,
        errorReportingFailed: false,
        errorId: null
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        let name = error.name;
        let message = error.message;
        let stack = error.stack || null;

        console.error(name + ': ' + message);
        console.error('Stacktrace:\n' + stack);

        const apiRootUrl = process.env.REACT_APP_API_URL;
        const logUrl = apiRootUrl + '/v1/logs';

        let data = {
            source: 'web',
            message: name + ': ' + message,
            stack: stack,
            url: window.location.toString(),
            version: process.env.REACT_APP_VERSION
        }

        fetch(logUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.authUser ? 'Bearer ' + this.authUser.token : null
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            if (response.code === 0) {
                this.setState({ errorId: response.id });
            }
            else {
                this.setState({ errorReportingFailed: true });
            }
            
        })
        .catch(() => {
            this.setState({ errorReportingFailed: true });
        })
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }

        return (
            <div className="error-page">
                <img className="logo" src="/logo.png" alt="Spirited Drive logo"/>

                <h1 className="title"><FontAwesomeIcon icon={faFrown}/> Oh dear, looks like we broke down!</h1>

                <p className="message">
                    We've been notified of the issue and will fix the problem ASAP. In the meantime try reloading this page,
                    or <a href="/">return to the home page</a>.
                </p>

                <p className="message">Sorry for any inconvience Thank you!</p>

                {this.state.errorId &&
                    <p className="error-id">Error ID: {this.state.errorId}</p>
                }

                <nav className="footer-links">
                    <ul className="row-list">
                        <li className="item"><a className="contact-link" href="mailto:hello@spiriteddrive.club">Contact Us</a></li>
                        <li className="item"><a className="contact-link" target="_blank" rel="noreferrer" href="https://twitter.com/Spirited_Drive">Twitter</a></li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default ErrorBoundary