import React from 'react';
import * as d3 from 'd3';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import geo from '../utils/geo'

class ElevationGradientInfo extends React.Component {
    state = {
        graphCarousel: {
            showLeft: false,
            showRight: true
        }
    }

    carouselObserver = null;
    carouselScroller = null;
    scrollInterval = null;

    renderElevationGraph = () => {
        // calculate width of the graph
        const minWidth = 800;
        const maxWidth = 3000;

        const baselineElevation = 700; // meters
        const baselineHeight = 160; // pixels
        const pixelsPerMeter = baselineHeight / baselineElevation;

        const maxDriveHeight = this.props.elevation.max;
        const maxHeight = (maxDriveHeight * pixelsPerMeter);

        let km = this.props.distanceInMeters / 1000;
        let initialWidth = km * 50;

        if (initialWidth < minWidth) {
            initialWidth = minWidth;
        }
        if (initialWidth > maxWidth) {
            initialWidth = maxWidth;
        }

        // calculate how many x axis labels we should show
        const maxTicks = 30;
        const minTicks = 10;

        let widthRange = maxWidth - minWidth;
        let widthPercentage = (100 / widthRange) * (initialWidth - minWidth);

        let tickRange = maxTicks - minTicks;
        let tickCount = Math.ceil((tickRange * (widthPercentage / 100)) + minTicks);

        // set the dimensions and margins of the graph
        let margin = { top: 10, right: 20, bottom: 20, left: 20 };
        let width = initialWidth - margin.left - margin.right;
        let height = maxHeight;

        // append the svg object to the body of the page
        let svg = d3.select('#elevation-graph-d3')
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        // add X axis
        let x = d3.scaleLinear()
            .domain([0, km])
            .range([ 0, width ]);

        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x).ticks(tickCount).tickFormat((d, i) => {
                return d === 0 ? '0' : d + 'km'
            }));

        // add Y axis
        let y = d3.scaleLinear()
            .domain([0, maxDriveHeight])
            .range([ height, 0 ]);

        let accumulativeDistance = 0;

        let points = [...this.props.elevation.points];

        points.forEach((p, i) => {
            let nextPoint = points[i + 1];
            if (nextPoint) {
                p.distance = accumulativeDistance;

                let distance = geo.getDistanceFromLatLngInKm(p.point, nextPoint.point);
                accumulativeDistance += distance;
            }
            else {
                p.distance = km;
            }
        });

        // Add the area
        svg.append('path')
            .datum(points)
            .attr('fill', '#9ed6ae')
            .attr('stroke', '#69b3a2')
            .attr('stroke-width', 1.5)
            .attr('d', d3.area()
                .x(function(d) { return x(d.distance) })
                .y0(y(0))
                .y1(function(d) { return y(d.height) })
            );

        svg
            .append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', () => {
                focus.style('opacity', 1);
                focusText.style('opacity', 1);
            })
            .on('mousemove', (event) => {
                // recover coordinate we need
                let distanceX = x.invert(d3.pointer(event)[0]);
                            
                if (distanceX < 0) {
                    distanceX = 0;
                }
                if (distanceX > km) {
                    distanceX = km;
                }

                let i = bisect(points, distanceX, 0);
                let selectedPoint = points[i];

                if (this.props.onElevationGraphPointSelected) {
                    this.props.onElevationGraphPointSelected(selectedPoint);
                }

                focus
                    .attr('cx', x(selectedPoint.distance))
                    .attr('cy', y(selectedPoint.height))
                focusText
                    .html(selectedPoint.height.toFixed(0) + ' m')
                    .attr('x', x(selectedPoint.distance) + 15)
                    .attr('y', y(selectedPoint.height))
            })
            .on('mouseout', () => {
                focus.style('opacity', 0);
                focusText.style('opacity', 0);

                if (this.props.onElevationGraphPointSelected) {
                    this.props.onElevationGraphPointSelected(null);
                }
            });

        let bisect = d3.bisector(function(d) { return d.distance; }).left;

        let focus = svg
            .append('g')
            .append('circle')
            .style('fill', 'none')
            .attr('stroke', 'black')
            .attr('r', 8.5)
            .style('opacity', 0);
        
        // create the text that travels along the curve of chart
        let focusText = svg
            .append('g')
            .append('text')
            .style('opacity', 0)
            .attr('text-anchor', 'left')
            .attr('alignment-baseline', 'middle');
    }

    componentDidMount() {
        this.carouselObserver = new MutationObserver(() => {
            if (this.carouselScroller) return;

            this.carouselScroller = document.querySelector('.elevation-graph.carousel .scroller');
            this.setCarouselState(this.carouselScroller);
            this.carouselScroller.addEventListener('scroll', () => {
                this.setCarouselState(this.carouselScroller);
            });

            if (this.carouselObserver) {
                this.carouselObserver.disconnect();
            }
        });

        window.addEventListener('resize', this.handleResizeWindow);

        this.carouselObserver.observe(document.body, {
            subtree: true,
            childList: true
        });

        if (this.props.elevation) {
            this.renderElevationGraph();
        }
    }

    setCarouselState = (scroller) => {
        let scrollLeft = Math.abs(scroller.scrollLeft);
        let scrollWidth = scroller.scrollWidth;
        let boxWidth = scroller.getBoundingClientRect().width;

        let showLeft = false;
        let showRight = false;

        if (scrollLeft > 0) {
            showLeft = true;
        }

        if (scrollLeft + boxWidth < (scrollWidth - 1)) {
            showRight = true;
        }

        this.setState({
            graphCarousel: {
                showLeft: showLeft,
                showRight: showRight
            }
        });
    }

    handleResizeWindow = () => {
        this.setCarouselState(this.carouselScroller);
    }

    handleExportElevation = () => {
        let csv = '';

        this.props.elevation.points.forEach(point => {
            csv += point.distance + ',';
        });

        csv += '\n';

        this.props.elevation.points.forEach(point => {
            csv += point.height + ',';
        });

        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
        element.setAttribute('download', 'elevation.csv');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    handleStartScrollLeft = () => {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }

        this.scrollInterval = setInterval(() => {
            const initialLeft = this.carouselScroller.scrollLeft;
            this.carouselScroller.scrollLeft -= 10;

            if (initialLeft === this.carouselScroller.scrollLeft) {
                clearInterval(this.scrollInterval);
            }
        }, 10);
    }

    handleStartScrollRight = () => {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }

        this.scrollInterval = setInterval(() => {
            const initialLeft = this.carouselScroller.scrollLeft;
            this.carouselScroller.scrollLeft += 10;

            if (initialLeft === this.carouselScroller.scrollLeft) {
                clearInterval(this.scrollInterval);
            }
        }, 10);
    }

    handleStopScroll = () => {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    }

    componentWillUnmount() {
        if (this.carouselObserver) {
            this.carouselObserver.disconnect();
        }

        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
        }

        window.removeEventListener('resize', this.handleResizeWindow);
    }

    render() {
        // get imperial elevation units
        let elevationImperial = null;

        if (this.props.elevation) {
            elevationImperial = {
                min: this.props.elevation.min * 3.28084,
                max: this.props.elevation.max * 3.28084,
                difference: this.props.elevation.difference * 3.28084,
                average: this.props.elevation.average * 3.28084,
            }
        }

        return(<>
            <dl className="data-list drive-props">
                <div className="item">
                    <dt className="key">Elevation:</dt>
                    <dd className="value">
                        <table className="min-max-table" summary="Elevation data">
                            <thead className="screen-readers-only">
                                <tr>
                                    <th></th>
                                    <th scope="col">Metric</th>
                                    <th scope="col">Imperial</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="row">
                                    <th className="key" scope="row" aria-label="Minimum elevation">Min:</th>
                                    <td className="value metric">{this.props.elevation.min.toFixed(0)}m</td>
                                    <td className="value imperial">({elevationImperial.min.toFixed(0)} ft)</td>
                                </tr>
                                <tr className="row">
                                    <th className="key" scope="row" aria-label="Max elevation">Max:</th>
                                    <td className="value metric">{this.props.elevation.max.toFixed(0)}m</td>
                                    <td className="value imperial">({elevationImperial.max.toFixed(0)} ft)</td>
                                </tr>
                                <tr className="row">
                                    <th className="key" scope="row" aria-label="Elevation difference">Diff:</th>
                                    <td className="value metric">{this.props.elevation.difference.toFixed(0)}m</td>
                                    <td className="value imperial">({elevationImperial.difference.toFixed(0)} ft)</td>
                                </tr>
                                <tr className="row">
                                    <th className="key" scope="row" aria-label="Average elevation">Avg:</th>
                                    <td className="value metric">{this.props.elevation.average.toFixed(0)}m</td>
                                    <td className="value imperial">({elevationImperial.average.toFixed(0)} ft)</td>
                                </tr>
                            </tbody>
                        </table>
                    </dd>
                </div>
            
                {this.props.gradient &&
                    <div className="item gradient-item">
                        <dt className="key">Gradient:</dt>
                        <dd className="value">
                            <table className="min-max-table" summary="Gradient data">
                                <thead className="screen-readers-only">
                                    <tr>
                                        <th></th>
                                        <th scope="col">Gradient</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.gradient.max &&
                                        <tr className="row">
                                            <th className="key" scope="row">Max Ascent:</th>
                                            <td className="value">{this.props.gradient.max.toFixed(1)}%</td>
                                        </tr>
                                    }
                                    {this.props.gradient.min &&
                                        <tr className="row">
                                            <th className="key" scope="row">Max Descent:</th>
                                            <td className="value">{this.props.gradient.min.toFixed(1)}%</td>
                                        </tr>
                                    }
                                    <tr className="row">
                                        <th className="key" scope="row" aria-label="Average Descent">Avg:</th>
                                        <td className="value">{this.props.gradient.average.toFixed(1)}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </dd>
                    </div>
                }
            </dl>

            <div className="elevation-graph carousel">
                {/* {this.props.adminMode &&
                    <button type="button" className="button" onClick={this.handleExportElevation}>Export Elevation</button>
                } */}

                {this.state.graphCarousel.showLeft &&
                    <button type="button" className="scroll-left" aria-hidden="true"
                        onMouseDown={this.handleStartScrollLeft} onMouseUp={this.handleStopScroll} onMouseLeave={this.handleStopScroll}>
                        <FontAwesomeIcon className="icon rtl-flip" icon={faChevronLeft}/>
                    </button>
                }

                <div className="scroller">
                    <div id="elevation-graph-d3" aria-hidden="true"></div>
                </div>

                {this.state.graphCarousel.showRight &&
                    <button type="button" className="scroll-right" aria-hidden="true"
                        onMouseDown={this.handleStartScrollRight} onMouseUp={this.handleStopScroll} onMouseLeave={this.handleStopScroll}>
                        <FontAwesomeIcon className="icon rtl-flip" icon={faChevronRight}/>
                    </button>
                }
            </div>
        </>);
    }
}

export default ElevationGradientInfo;