import heic2any from "heic2any";

function cropAndResizeImage(imgSrc, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        let image = new Image();

        image.onerror = () => {
            reject();
        };

        image.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.style['image-rendering'] = 'high-quality';

            canvas.width = maxWidth;
            canvas.height = maxHeight;

            let width = image.width;
            let height = image.height;
            let isPortrait = height > width;

            let shiftX = 0;
            let shiftY = 0;

            let newWidth = canvas.width;
            let newHeight = canvas.height;

            if (isPortrait) {
                let ratio = height / width;
                newHeight = canvas.width * ratio;
                shiftY = (newHeight - canvas.height) / 2;
            }
            else {
                let ratio = width / height;
                newWidth = canvas.height * ratio;
                shiftX = (newWidth - canvas.width) / 2;
            }

            let ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(image, -shiftX, -shiftY, newWidth, newHeight);

            resolve(canvas.toDataURL('image/jpeg'))
        };

        image.src = imgSrc;
    });
}

function resizeImage(imgSrc) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.crossOrigin = 'Anonymous';

        image.onerror = () => {
            reject();
        };

        image.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.style['image-rendering'] = 'high-quality';

            const maxWidth = 1600;
            const maxHeight = 1600;

            let width = image.width;
            let height = image.height; 
            let isPortrait = height > width;

            let newWidth = maxWidth;
            let newHeight = maxHeight;

            if (isPortrait) {
                newHeight = maxHeight
                let ratio = width / height;
                newWidth = newHeight * ratio;
            }
            else {
                newWidth = maxWidth
                let ratio = height / width;
                newHeight = newWidth * ratio;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            let ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            resolve(canvas.toDataURL('image/jpeg'));
        };

        image.src = imgSrc;
    });
}

const functions = {
    resize: function(file) {
        return new Promise((resolve, reject) => {
            if (file.type === 'image/heic') {
                // https://alexcorvi.github.io/heic2any/
                heic2any({ blob: file, toType: 'image/png' }).then(blob => {
                    resizeImage(URL.createObjectURL(blob))
                        .then(dataUrl => {
                            resolve(dataUrl);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }
            else {
                let reader = new FileReader();
                reader.onload = () => {
                    resizeImage(reader.result)
                        .then(dataUrl => {
                            resolve(dataUrl);
                        })
                        .catch(error => {
                            reject(error);
                        });
                };

                reader.readAsDataURL(file); 
            }
        });
    },

    cropAndResize: function(file, maxWidth, maxHeight) {
        return new Promise((resolve, reject) => {
            if (file.type === 'image/heic') {
                // https://alexcorvi.github.io/heic2any/
                heic2any({ blob: file, toType: 'image/png' }).then(blob => {
                    cropAndResizeImage(URL.createObjectURL(blob), maxWidth, maxHeight)
                        .then(dataUrl => {
                            resolve(dataUrl);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }
            else {
                let reader = new FileReader();
                reader.onload = () => {
                    cropAndResizeImage(reader.result, maxWidth, maxHeight)
                        .then(dataUrl => {
                            resolve(dataUrl);
                        })
                        .catch(error => {
                            reject(error);
                        });
                };

                reader.readAsDataURL(file); 
            }
        });
    }
}

export default functions;