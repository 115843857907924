import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons'

class ScrollIndicator extends React.Component {
    state = {
        visible: false,
        opacity: 0
    }

    scrollableContainer = null;
    showIndicatorTimeout = null;

    componentDidMount() {
        if (window.innerWidth > 700) return;

        this.scrollableContainer = document.querySelector('.main-page-half');
        if (!this.scrollableContainer) return;

        this.showIndicatorTimeout = setTimeout(() => {
            this.setState({
                visible: true,
                opacity: 0
            });

            setTimeout(() => {
                this.setState({ opacity: 1 });
            });

            setTimeout(() => {
                if (!this.showIndicatorTimeout) return;

                this.setState({ opacity: 0 });

                setTimeout(() => {
                    this.setState({ visible: false });
                }, 500);
            }, 5000);
        }, 2000);

        this.scrollableContainer.addEventListener('scroll', () => {
            clearTimeout(this.showIndicatorTimeout);
            this.showIndicatorTimeout = null;

            this.setState({ opacity: 0 });

            setTimeout(() => {
                this.setState({ visible: false });
            }, 500);
        });
    }

    componentWillUnmount() {
        if (this.showIndicatorTimeout) {
            clearTimeout(this.showIndicatorTimeout);
            this.showIndicatorTimeout = null;
        }
    }

    render() {
        if (!this.state.visible) return null;

        return (
            <div aria-hidden="true" className="scrolling-hint" style={{ 'opacity': this.state.opacity }}>
                <div className="icon-hint-container">
                    <FontAwesomeIcon className="icon" fixedWidth icon={faArrowDown}/>
                </div>
            </div>
        )
    }
}

export default ScrollIndicator