import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faInfinity } from '@fortawesome/pro-solid-svg-icons'

class DriveInfo extends React.Component {
    formatDriveName = (name) => {
        name = (name || '').trim();

        if (name.toLowerCase().startsWith('the ')) {
            return name;
        }

        return 'The ' + name;
    }

    render() {
        // format distance
        let distanceInMeters = this.props.distanceInMeters || 0;

        let distanceInKM = distanceInMeters / 1000;
        let distanceInMiles = distanceInKM * 0.621371;

        distanceInKM = distanceInKM.toFixed(1);
        distanceInMiles = distanceInMiles.toFixed(1);

        // format travel time
        let travelTimeInMinutes = this.props.travelTimeInMinutes || 0;

        let hours = Math.floor(travelTimeInMinutes / 60)
        let remainderMinutes = travelTimeInMinutes - (hours * 60);

        let hoursLabel = '';
        if (hours > 0) {
            hoursLabel = hours + ' hour' + (hours > 1 ? 's' : '');
        }

        let minutesLabel = '';
        if (remainderMinutes > 0) {
            minutesLabel = remainderMinutes + ' minute' + (remainderMinutes > 1 ? 's' : '');
        }

        let travelTime = hoursLabel + ' ' + minutesLabel;

        // calculate average speed
        let averageSpeedKmh = this.props.averageSpeed;

        if (!averageSpeedKmh) {
            let remainderMinutesFraction = remainderMinutes / 60;
            let allHours = hours + remainderMinutesFraction;
            averageSpeedKmh = distanceInKM / allHours;
        }

        let averageSpeedMph = averageSpeedKmh * 0.621371;

        // road info
        let roadTypes = this.props.roadTypes || [];
        let roadNames = this.props.roadNames || [];

        return (
            <div className={this.props.className}>
                <address className="address-information">
                    <h2 className="screen-reader-only">Where is {this.formatDriveName(this.props.name)}?</h2>

                    <p>
                        <span className="from-to">
                            {this.props.isLoop && <>
                                <span className="screen-reader-only">This drive starts and ends at </span>
                                <span className="from">{this.props.startAddress}</span>
                                <FontAwesomeIcon className="icon" icon={faInfinity}/>
                            </>}

                            {!this.props.isLoop && <>
                                <span className="screen-reader-only">This drive starts at </span>
                                <span className="from">{this.props.startAddress}</span>

                                <FontAwesomeIcon className="icon rtl-flip" icon={faLongArrowAltRight}/>

                                <span className="screen-reader-only"> and ends at </span>
                                <span className="to">{this.props.endAddress}</span>
                            </>}
                        </span>

                        <span className="location">
                            <span className="screen-reader-only"> in </span>
                            {this.props.generalLocation}
                        </span>
                    </p>
                </address>

                <section className="drive-properties-container">
                    <h2 className="screen-reader-only">Drive Properties</h2>

                    <dl className="data-list drive-props">
                        <div className="item">
                            <dt className="key">Distance:</dt>
                            <dd className="value">{distanceInKM}km ({distanceInMiles} miles)</dd>
                        </div>
                        <div className="item">
                            <dt className="key">Travel Time:</dt>
                            <dd className="value">{travelTime}</dd>
                        </div>
                        <div className="item">
                            <dt className="key">Average Speed:</dt>
                            <dd className="value">{averageSpeedKmh.toFixed(0)} km/h ({averageSpeedMph.toFixed(0)} mph)</dd>
                        </div>
                        {this.props.averageDailyTraffic &&
                            <div className="item">
                                <dt className="key">Average Traffic:</dt>
                                <dd className="value">{this.props.averageDailyTraffic} per day</dd>
                            </div>
                        }

                        {roadNames.length > 0 &&
                            <div className="item gap-above road-names-item">
                                <dt className="key">
                                    {roadNames.length > 1 ? <>Roads:</> : <>Road:</>}
                                </dt>
                                <dd className="value">
                                    {roadNames.length > 1 &&
                                        <ul className="csv-list">
                                            {roadNames.map((name, i) =>
                                                <li key={i} className="item">{name}</li>
                                            )}
                                        </ul>
                                    }

                                    {roadNames.length === 1 && <>{roadNames[0]}</>}
                                </dd>
                            </div>
                        }
                        {roadTypes.length > 0 &&
                            <div className="item">
                                <dt className="key">
                                    {roadTypes.length > 1 ? <>Road Types:</> : <>Road Type:</>}
                                </dt>
                                <dd className="value">
                                    {roadTypes.length > 1 &&
                                        <ul className="road-types-list">
                                            {roadTypes.map(type =>
                                                <li key={type.id} className="road-type-item">
                                                    <span className="text">{type.name}</span>
                                                    {roadTypes.length > 1 &&
                                                        <span className="proportion"> ({type.proportion}%)</span>
                                                    }
                                                </li>
                                            )}
                                        </ul>
                                    }

                                    {roadTypes.length === 1 && <>{roadTypes[0].name}</>}
                                </dd>
                            </div>
                        }
                    </dl>
                </section>
            </div>
        )
    }
}

export default DriveInfo;