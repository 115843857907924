import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faEdit, faSync } from '@fortawesome/pro-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import validation from '../../utils/validation'
import youTube from '../../utils/youTube'

import ModalPortal from './ModalPortal'

import './Videos.scss'

class Videos extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            videos: props.videos || [],
            form: {
                url: {
                    val: '',
                    err: null
                }
            },
            isSubmitted: false,
            isSaving: false
        }
    }

    handleInputChange = (event) => {
        this.setState({ form: validation.handleInputChange(event.target, this.state.form) });
    }

    addNewVideo = (event) => {
        event.preventDefault();
        this.setState({ isSubmitted: true });

        let isValid = event.target.checkValidity();
        if (!isValid) return;

        let videoId = youTube.extractVideoId(this.state.form.url.val);

        if (this.state.videos.some(v => v.id === videoId)) {
            let errorMessage = 'You have already added this video.';
            this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'url', errorMessage) });
            return;
        }

        youTube.getVideoInfo(videoId, this.props.googleApiKey)
            .then(videoItem => {
                if (!videoItem) {
                    let errorMessage = 'Video not found. Check URL and try again.';
                    this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'url', errorMessage) });
                    return;
                }

                if (videoItem.privacyStatus !== 'public') {
                    let errorMessage = 'Video must be made Public';
                    this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'url', errorMessage) });
                    return;
                }

                if (!videoItem.embeddable) {
                    let errorMessage = 'Video must be set to be embeddable';
                    this.setState({ form: validation.markFieldInvalid(event.target, this.state.form, 'url', errorMessage) });
                    return;
                }

                let videos = this.state.videos;

                videos.push({
                    id: null,
                    youTubeId: videoItem.youTubeId,
                    title: videoItem.title,
                    channelId: videoItem.channelId,
                    channelTitle: videoItem.channelTitle,
                    views: videoItem.views
                });

                let form = { ...this.state.form };
                form.url.val = '';

                this.setState({
                    videos: videos,
                    form: form,
                    isSubmitted: false
                });
            });
    }

    removeVideo = (video) => {
        let videos = this.state.videos;
        let index = videos.indexOf(video);
        if (index !== -1) {
            videos.splice(index, 1);
            this.setState({ videos: videos });
        }
    }

    handleSave = () => {
        if (!this.props.handleSave) return;

        if (this.state.isSaving) return;
        this.setState({ isSaving: true });

        this.props.handleSave(this.state.videos);
    }

    close = () => {
        if (this.state.isSaving) return;

        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (
            <ModalPortal className="standard-modal youTube-video-modal" onClose={this.close}>
                <header className="header">
                    <h2 className="title">
                        <FontAwesomeIcon className="icon" icon={faYoutube}/>
                        <span className="text">{this.state.videos.length > 0 ? 'Update' : 'Add'} Your YouTube Videos</span>
                    </h2>
                    <button type="button" className="link-button close" aria-label="Close" onClick={this.close}>
                        <FontAwesomeIcon className="icon" icon={faTimesCircle}/>
                    </button>
                </header>
                <div className="main">
                    <div className="media-form-items">
                        {this.state.videos.map(video =>
                            <article className="media-item" key={video.id}>
                                <div className="media video-media">
                                    <iframe width="224" height="126" src={`https://www.youtube.com/embed/` + video.youTubeId + `?controls=0&modestbranding=1`} title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="simple-image video-item" allowFullScreen=""></iframe>
                                </div>
                                <div className="details">
                                    <h3 className="title">{video.title}</h3>

                                    <dl className="data-list">
                                        <div className="item">
                                            <dt className="key">Channel:</dt>
                                            <dd className="value">
                                                <a target="_blank" rel="noreferrer" href={`https://www.youtube.com/channel/` + video.channelId}>{video.channelTitle}</a>
                                            </dd>
                                        </div>
                                        <div className="item">
                                            <dt className="key">Views:</dt>
                                            <dd className="value">{video.views}</dd>
                                        </div>
                                    </dl>

                                    <div className="actions">
                                        <button className="button delete-button cta" onClick={() => this.removeVideo(video)}>Remove Video</button>
                                    </div>
                                </div>
                            </article>
                        )}
                    </div>

                    {this.state.videos.length < 3 &&
                        <form onSubmit={this.addNewVideo} className={this.state.isSubmitted ? 'submitted' : ''} noValidate>
                            <div className="form-row">
                                <label htmlFor="modal-youTube-url" className="label">YouTube Video URL</label>
                                <div className="control with-button">
                                    <input id="modal-youTube-url" name="url" type="url" className="textbox" required
                                        maxLength="100" pattern="^https:\/\/(youtu\.be\/|www\.youtube\.com\/watch\?v=)(.+)$"
                                        data-error-pattern="Must be a valid YouTube URL"
                                        placeholder="e.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
                                        value={this.state.form.url.val} onChange={this.handleInputChange} />
                                    <button type="submit" className="button">Add</button>
                                </div>

                                {this.state.form.url.err &&
                                    <p className="validation-error">{this.state.form.url.err}</p>
                                }
                            </div>
                        </form>
                    }

                    {this.state.videos.length >= 3 &&
                        <p>A maximum of 3 videos are allowed per drive per user.</p>
                    }

                    <div className="form-row buttons">
                        <button type="submit" className="button primary-button" onClick={this.handleSave}>
                            <FontAwesomeIcon className="icon" icon={this.state.isSaving ? faSync : faEdit} spin={this.state.isSaving} fixedWidth={true}/>
                            <span className="text">Save &amp; Close</span>
                        </button>
                        <button type="button" className="link-button cancel-button" onClick={this.close}>Nevermind</button>
                    </div>
                </div>
            </ModalPortal>
        )
    }
}

export default Videos