function storageAvailable(window, type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

const functions = {
    get: function(window) {
        if (storageAvailable(window, 'localStorage')) {
            return window.localStorage;
        }
        else if (storageAvailable(window, 'sessionStorage')) {
            return window.sessionStorage;
        }
        else {
            if (!window.SpiritedStorage) {
                window.SpiritedStorage = {
                    items: {},
                    getitem: function(key) {
                        return this.items[key] || null;
                    },
                    setItem: function(key, value) {
                        this.items[key] = value;
                    },
                    removeItem: function(key) {
                        delete this.items[key];
                    },
                    clear: function() {
                        this.items = {};
                    }
                };
            }
    
            return window.SpiritedStorage;
        }
    }
}

export default functions;